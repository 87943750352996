.container {
  /* border: 2px solid red; */
  margin-bottom: 4rem;

}
.containn {
  /* border: 2px solid black; */
  height:fit-content;
  background: #f6fcfe;
  padding-left: 0.5rem;
  padding-right: 1rem;
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 27px;
  color: #1d3557;
  padding-left: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.imgs{
    width: 20rem;
   height: fit-content;
}
