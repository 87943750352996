.contain {
    height: 22.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .btnn1 {
      text-align: center;
      border-radius: 9px;
      /* padding: 0.6rem 1rem 0.6rem 1rem; */
      padding: 0.75rem 2.75rem;
      /* box-shadow: 0px 6px 8px rgba(154, 170, 207, 0.2);
      box-shadow: 1px 1px 2px 2px rgba(154, 170, 207, 0.2); */
      color: #0084EF;
      font-family: Inter;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 0.5rem;
      /* border: 1px solid #0084EF; */
      background: #FFF;
      width:50%;
      cursor:pointer;
    }
    .btnn1:hover{
      background: #EBF6FF;
     }
   .background a{
        width:50%;
    }
    
    .btn2 {
      text-align: center;
      color: #ffffff;
      padding: 0.75rem 1.25rem;
     width:50%;
      border: none;
      border-radius: 0.5rem;
      background: #0084EF;
      color: #FFF;
      font-family: Inter;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }
    .btn2:hover{
      box-shadow: 0px 6px 16px 0px rgba(172, 173, 174, 0.20);
    }
    .heading {
      color: #FFF;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    }
  
    .para {
      color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top:1.06rem;
    }
    
    .requestHead {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 127.52%;
      color: #212121;
      margin-top: 2rem;
    }
    
    .eachLine {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 127.52%;
      color: #141414;
      margin-top: 1.5rem;
    }
  
    .background {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.00) 100%), url("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/image%2038.png");
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btnContainer{
      margin-top: 2.5rem; 
      display:flex;
       gap:1.25rem;
       width:70%;
    }
  
  @media only screen and (max-width: 767px){
    .background {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0.00) 100%), url("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/abvFooter.png");
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12.0625rem;
    }
    .heading{
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left:2.37rem;
      margin-right:2.37rem;
    }
    .para{
      color: #F5F5F5;
text-align: center;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 0.27rem;
    }
    .btn2 {
      margin-left: 0rem;
      padding: 0.75rem 1.125rem;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
    }
    .btnn1{
      font-size: 0.75rem;
      padding: 0.75rem 1.125rem;
      font-weight: 600;
    }
    .btnContainer{
      display: flex;
      gap: 1.25rem;
      margin-top: 1.5rem;
      width: 80%;
    }
  }