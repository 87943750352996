.container {
    /* border: 2px solid black; */
   
    display: flex;
    justify-content: space-between;
    padding-right: 8rem;
    margin-top: 1.5rem;
    position: relative;
    margin-bottom: 5rem;
     
  }
  .btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1A936F;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border: none;
    background: #DCF9F0;
    border-radius: 50px;
    margin-top: 4rem;
  
   
  }
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #1d3557;
    margin-top: 1rem;
    /* width: 50%; */
  }
  .content_div {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
  }
  .projects_div {
    /* border: 2px solid red; */
    /* width: 65%; */
    height: 40vh;
    margin-top: 2rem;
 
  }
  .file_timeline {
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
  }
  .task_daily {
    /* border: 2px solid red; */
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
  }
  
  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  .icon_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    padding-top: 0.6rem;
    line-height: 22px;
  
    color: #212121;
  }
  .para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4d4d4d;
    margin-top: 0.2rem;
  }

  .img_div{
    width: 50%;
  }
  .mainImg{
    position: absolute;
    top: 11%;
   left: 5%;
    height: 28rem;
    width: 40%;
    border-radius: 16px;
  }
  .back{
    /* border: 2px solid red; */
    width:70%;
    height: 35rem;
  }
  /* .mainImg{
  height: 50rem;
  width: 50rem;
  } */
  
 
  