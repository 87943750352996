.logo{
    width: 8.79188rem;
height: 1.41613rem;
}
.ham{
    width: 1rem;
height: 1rem;
transform: scale(1.2);
}

.innerContainer{
    padding:1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 1000000000;
    background: #FFF;
}
.mainContainer{
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);
background: #FFF;
}