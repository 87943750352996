.container {
  /* border: 2px solid red; */
  /* height: 90vh; */
  display: flex;
}

.firstSection {
  /* border: 2px solid black; */
  width: 65%;
  padding-left: 5rem;
}
.Check_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */
  margin-bottom: 1rem;
  margin-top: 2rem;
  /* border: 2px solid black; */
  color: #212121;
}
.first_div {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding-left: 2rem;
  padding-top: 1.2rem;
  padding-right: 2rem;
  padding-bottom:2.5rem;
  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 12px;
  width: 100%;
  height: auto;
  /* border: 2px solid red; */
}
.sec_div {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding-left: 2rem;
  padding-top: 1.2rem;
  padding-bottom: 2.7rem;
  padding-right: 2rem;
  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 12px;
  width: 100%;
  height: auto;
  margin-top: 2rem;
  /* border: 2px solid red; */
}
.div_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #252525;
  margin-bottom:1rem;
  /* border: 2px solid red; */
}
.inputs {
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.inputs1 {
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 7px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 50%;
}

.secondSection {
  /* border: 2px solid black; */
  width: 35%;
  display: flex;
  /* justify-content: center; */
  padding-top: 7.69rem;
  padding-left: 2rem;
}

.summary {
  background: #ffffff;
  border: 1px solid #e6e6e6;

  box-shadow: 0px 4px 8px rgba(154, 170, 207, 0.2);
  border-radius: 12px;
  height: 28rem;
  width: 25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
}
.sum_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #252525;
  padding-bottom: 2rem;
}

.sub_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  /* margin-bottom: 2rem; */
  color: #212121;
}

.pro_btn {
  background: #f1fbfd;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #2053c5;
  margin-bottom: 1rem;
}
.proImg {
  height: 1.1rem;
  /* width: 4rem; */
}

.btn {
  padding: 0.5rem 2rem 0.5rem 2rem;
  background: #2053c5;
  border-radius: 9px;
  border: none;
  font-weight: 600;
  color: #ffffff;
  margin-top: 2rem;
}

.foot_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  text-align: center;
  color: #a8a8a8;
  margin-top: 1rem;
}
.cityContainer{
   display: flex;
  justify-content: space-between;
  gap: 1rem;

margin-bottom: 1.2rem;



  /* margin-top:1.2rem; */
}
 
.topBox{
  display: flex; 
  flex-direction: column;
  gap: 2.25rem;
}
.billingDetailsContainer{
  display: flex; 
flex-direction: column;
 gap:2.25rem;
}
@media only screen and (max-width: 767px) {
  .cityContainer{
    display: flex;
   flex-direction:column; 
   gap: 2rem;
 }
  .Check_heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    margin-left:1rem;
  }
  .div_head {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: normal;
    margin-bottom:1rem;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .firstSection {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .summary {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .secondSection{
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top:1.5rem;
  }
  .inputs1 {
    width: 100%;
  }
  .sec_div{
    height: auto;
    padding-top: 1rem;
    padding-bottom: 2.4rem;
  }
  .inputs1 {
    margin-top: 0rem;
  }
  .first_div {
    padding-bottom: 2.4rem;
    height: auto;
  }
}
