.files_contain {
  /* border: 2px solid red; */
  width: 100%;
  padding-top: 2rem;
}
.img_div {
  padding-top: 1rem;
}
.img {
  width: 100%;
}
.file_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 127.52%;
  text-align: center;

  color: #1d3557;
}

.file_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #4d4d4d;
  padding-top: 0.5rem;
}
.icon_div {
  /* border: 2px solid red; */
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}
.eachBox {
  /* border: 2px solid black; */
  width: 100%;
  text-align: center;
}
.mobIcon{
    height: 2rem;
    width: 2.5rem;
   
}

.iconPara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: rgba(33, 33, 33, 0.8);
  padding-top: 0.5rem;
}
