.btn {
  background: #e9e6ff;
  border-radius: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9381ff;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border: none;
}
