.heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #0a2540;
  }
  
  .para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    color: #425466;
    margin-top: 1.5rem;
    /* border: 2px solid red; */
  }
  
  .btn_div {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 1rem;
    position: fixed;
    top: 9.5%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 999;
    width: 100%;
    /* border-top: 1px solid rgb(180, 174, 174); */
    box-shadow: 0px 4px 16px rgba(154, 170, 207, 0.2);
    transition: all 400ms;
  }
  .btn_div1 {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .imgs {
    width: 8rem;
    cursor: pointer;
  }
  .imgs2 {
    width: 7rem;
    cursor: pointer;
  }
  .imgs3 {
    width: 8.5rem;
    cursor: pointer;
  }
  .imgs4 {
    width: 10rem;
    cursor: pointer;
  }
  .imgs5 {
    width: 7rem;
    cursor: pointer;
    /* border: 2px solid red; */
  }
  
  .imgs6 {
    width: 4rem;
    cursor: pointer;
  }
  
  .container {
    padding-left: 7rem;
    padding-right: 7rem;
    display:flex;
    justify-content: space-between;
    padding-top: 3rem;
  
  }
  