.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.header {
  height: 4.5rem;
  width: 100vw;
  border-bottom: 1px solid #a7a7a7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 12px;
  padding-right: 20px;
  padding-left: 1rem;
  background-color: #ffffff;
}

.sidebar {
  height: 90vh;
  width: 20vw;
  border-right: 1px solid #dfdfdf;
}

.sidebarCollapsed {
  height: 90vh;
  width: 55px;
  border-right: 1px solid #dfdfdf;
}

.logobox {
  width: 13%;
  margin-left: 1rem;
}

.logobox img {
  margin-left: 4px;
  margin-bottom: 3px;
}

.profilebox {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menubox {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.eachHeaderLink {
  font-weight: 500;
  font-size: 16px;
  margin-right: 2rem;
}

.eachHeaderLink a {
  text-decoration: none;
  color: #444444;
}

.notification {
  color: #3b5998;
  margin-right: 0.8rem;
  font-size: 20px;
}

.cart {
  color: #3b5998;
  margin-right: 1.5rem;
  font-size: 20px;
}

.profileNameBox {
  cursor: pointer;
  position: relative;
  user-select: none;
  margin-bottom: 3px;
  margin-right: 3px;
}

.profileName {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.dropdown {
  position: absolute;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 4px 0 10px #00000021;
  border-radius: 6px;
  width: 8rem;
  right: 5px;
  top: 40px;
}

.dropdownItem {
  padding: 0.5rem;
  border-radius: 6px;
}

.dropdownItem:hover {
  background-color: #00000021;
}

.planPill {
  font-size: 10px;
  background-color: #17609126;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: fit-content;
  padding: 0 1rem;
}

.sidebar {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.createProjectButtonWrapper {
  padding: 0 0 0.8rem 0;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: center;
}

.createProjectButtonWrapper button {
  border: none;
  border-radius: 4px;
  background-color: #003566;
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 500;
  color: #fafafa;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
  width: 100%;
  justify-content: center;
}

.createProjectButtonWrapper button:hover {
  box-shadow: 4px 4px 10px #0000003f;
}

.collapsedCreateProjectButtonWrapper {
  padding: 0 0 0.8rem 0;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.collapsedCreateProjectButtonWrapper button {
  border: none;
  border-radius: 4px;
  background-color: #003566;
  font-size: 12px;
  font-weight: 500;
  color: #fafafa;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all;
  transition-duration: 300ms;
  width: 100%;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

.collapsedCreateProjectButtonWrapper button:hover {
  box-shadow: 4px 4px 10px #0000003f;
}

.linksContainerWrapper {
  height: 95%;
  overflow-y: scroll;
}

.linksContainer {
  height: fit-content;
  padding: 0.5rem 0;
}

.sidebarLink {
  display: flex;
  padding: 0.4rem 0 0.4rem 0.5rem;
  align-items: center;
  text-decoration: none;
  color: #000000 !important;
  margin-bottom: 0.125rem;
  border-radius: 8px;
  transition: all;
  transition-duration: 300ms;
}

.sidebarLink:hover {
  background-color: #00000021;
}

.collapsedSidebarLink {
  display: flex;
  padding: 0.4rem 0.5rem 0.4rem 0.5rem;
  align-items: center;
  text-decoration: none;
  color: #000000 !important;
  margin-bottom: 0.125rem;
  border-radius: 8px;
  transition: all;
  transition-duration: 300ms;
  justify-content: center;
}

.collapsedSidebarLink:hover {
  background-color: #00000021;
}

.sidebarLabel {
  font-size: 14px;
  margin-left: 0.75rem;
}

.upgradeButton {
  font-size: 14px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #333333;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all;
  transition-duration: 300ms;
}

.upgradeButton:hover {
  background-color: #003566;
  color: #ffffff;
  border: 1px solid #003566;
}

.upgradeText {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  margin-top: 1rem;
  align-items: center;
}

.rmName {
  font-size: 14px;
  font-weight: 500;
}

.rmPost {
  font-size: 12px;
  color: #9c9c9c;
}

.activeSidebarItem {
  background-color: #00000021 !important;
  border-radius: 6px;
}

.projectName {
  /* padding: 1rem 0rem; */
  /* margin: 0rem 1rem; */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.projectNameText {
  color: #1D3557;
  font-weight: 600;
  margin-left: 0.5rem;
  font-size: 18px;
}