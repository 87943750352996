.container {
  /* border: 2px solid red; */
  padding-top: 4rem;
  padding-bottom: 6rem;
  display: flex;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
}
.img {
  width: 90%;
  padding-left: 5rem;
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #000000;
  margin-top: 1.5rem;
  width: 90%;
  /* text-align: justify; */
}
