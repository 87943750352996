/*********************** Header ***************************/
.header {
  background: #ffffff;
  // border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgb(0 4 0 / 10%);
  position: sticky;
  top: 0;
  padding: 5px 0;
  z-index: 9999;
}

.blue-color {
  color: #3b5998;
}

.nav-item .nav-link {
  font-weight: normal;
  font-size: 18px;
  line-height: 33px;
  padding: 0 18px !important;
  font-family: "DM Serif Display";
}

.color-change .nav-item .nav-link {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #3b5998;
  font-family: "Inter", sans-serif;
}

.interFont {
  font-family: "Inter", sans-serif !important;
}

.grayText {
  color: #767676;
}

.redText {
  color: #be1616;
}

.pointer {
  cursor: pointer;
}

.hoverRow {
  &:hover {
    td {
      --bs-table-accent-bg: #eff9fa !important;
    }
  }
}

.disableErrorIcon {
  background-image: none !important;
}

.tagProjectModal {
  background-color: white;
  height: 200px;
  width: 500px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-weight: bold;
  }
}

.tagProjectModalBtn {
  padding: 10px;
  background: #3b5998 !important;
  color: white !important;
  font-size: 12px;
  border-color: #3b5998;
}
.tagProjectModalBtn2 {
  padding: 10px;
  background: white !important;
  color: #3b5998 !important;
  font-size: 12px;
  border-color: #3b5998;
}

.premiumListingPlanCard {
  background: #e9e9e9;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px;
  border: 3px solid #e9e9e9;

  h4 {
    letter-spacing: 0px;
    color: #2f4776;
    font-family: "Inter", sans-serif;

    font-size: 18px;
    font-weight: 500;
  }
  h5 {
    letter-spacing: 0px;
    color: #000000;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
  }
  h5 span {
    font-size: 14px;
  }
}

.activePremiumPlanCard {
  background: #ffffff;
  border: 3px solid #3b5998;
}

.offcanvas-body {
  padding: 0;
}

.offcanvas-body .nav-item .nav-link,
.sidemenu-logout .nav-item {
  color: #000;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .tagProjectModal {
    width: 94%;
    margin: 0 auto;
  }
  .tagProjectModal .mt-4.row {
    margin-top: 10px !important;
  }
  .tagProjectModal .mt-4.row .form-group {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .tagProjectModalBtn,
  .tagProjectModalBtn2 {
    font-size: 10px;
  }
}
