.containerr {
  /* width: 200%; */
  /* background-image: url("../imagesAnj/section1/backImg1.png");
  background-size: cover; */
  height: 100vh; 
  background-color: #F5F8FF;
  /* border: 2px solid red; */
}
.content {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid black; */
}
.topHeading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  word-spacing: 0.2rem;
  color: #1d3557;
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 127.52%;
  margin-top: 1.2rem;
  text-align: center;
  color: #212121;
}

.btn1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 127.52%;
  background-color: #ffffff;
  text-align: center;
  border-radius: 9px;
  color: #1d3557;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.btn2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 127.52%; 
  text-align: center;
  color: #ffffff;
  background-color:#1d3557;
  padding: 0.6rem 1rem 0.6rem 1rem;
  border-radius: 9px;
  margin-left: 1rem;
  border: none;
}

.img1{
    /* border: 2px solid red; */
    width:50%;
    height: 32rem;
    border-radius: 10.989px;
    border-left: 5px solid #E6E6E6;
    border-right: 3px solid #E6E6E6;
    border-top: 3px solid #E6E6E6;
    border-bottom: 3px solid #E6E6E6;
}
.scheduleHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 127.52%;
  color: #222222;
  padding-top: 4rem;
}

.requestHead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 127.52%;
  color: #212121;
  margin-top: 2rem;
}

.eachLine {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127.52%;
  color: #141414;
  margin-top: 1.5rem;
}

.scheduleDemo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 127.52%;
  color: #212121;
  margin-bottom: 1.2rem;
  
}

.inputsDrop{
  border-radius: 10px;
  padding: 0.5rem;
  font-size: 16px;

}


.outlinedTextField {
  display: inline-flex;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 4px;
}

.input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
}

.label {
  position: absolute;
  top: -8px;
  left: 12px;
  background-color: #fff;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  color: #bdbdbd;
}
