.eachCol {
  /* border: none; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #808080;
  width: 17%;
}

.percentBtn {
  color: #108500;
  border: none;
  background: #caffd9;
  border-radius: 5.71429px;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
  font-weight: 600;
}
.btn {
  background: #e9e5ff;
  border-radius: 50px;
  color: #9381FF;
  font-weight: 600;
  border:none;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
  margin-left: 0.5rem;
}


