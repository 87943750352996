.container {
    background-color: #ebf7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
  }
  
  .para {
    margin-top: 1rem;
    width: 80%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #000000;
    text-align:justify ;
  }
  