.testimonial {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../Assets/Landing/testimonial.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 600px;
  width: 80%;
  margin: 0 auto;

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    color: #4f5e71;
    padding: 0 35px;
  }
  h3 {
    font-weight: normal;
    font-size: 22px;
    line-height: 33px;
    color: #252a31;
    padding-bottom: 0px;
    padding-top: 5px;
    text-align: center;

    span {
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      color: #4f5e71;
      margin-left: 8px;
    }
  }
  h2 {
    font-size: 40px;
    color: #252a31;
    padding-bottom: 35px;
    text-align: center;
  }

  ul {
    bottom: -40px;
    li button:before {
      font-size: 10px;
    }
  }

  img {
    margin: 0 auto;
  }
  .slick-dots .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .testimonial {
    padding: 15px 0 80px !important;
    height: inherit;
    background-size: 140%;
    width: 100%;
    background-position: top;
  }
  .testimonial h2 {
    font-size: 28px;
    padding-bottom: 15px;
  }
  .testimonial p {
    font-size: 14px;
    line-height: 24px;
    padding: 0 0;
  }
  .testimonial .slick-slide img {
    width: 100%;
    max-width: 50px;
  }
  .testimonial .slick-dots li {
    width: 10px;
  }
  .testimonial .slick-dots li button {
    padding: 0;
  }
  .testimonial ul li button:before {
    font-size: 6px;
  }
  .banner-home.profile-build .banner-left {
    margin-top: 30px;
  }
  .testimonial h3 span{
    font-size: 14px;
  }
  .testimonial {
    background-image: url("../../../Assets/Landing/testimonial-mobile.svg");
    background-size: contain;
    width: 90%;
  }
}
