.container {
    height: 100vh;
    display: flex;
  }
  .first_contain {
    background-color: #e9effb;
    height: 100vh;
    width: 50%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
  }
  
  .ImgDiv {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E9EFFB;
  
  }
  .imgg{
    width: 90%;
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 127.52%;
    color: #1d3557;
    margin-top: 1rem;
    
  }
  .para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17.7px;
    line-height: 25px;
    color: #212121;
    margin-top: 0.5rem;
    /* border: 2px solid red; */
    padding-right: 2rem;
  }
  .icon {
    margin-top: 2rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  
  /* second div */
  .second_contain {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
  }
  .PersonalContent_div {
    /* border: 2px solid red; */
    width: 90%;
    height: 65%;
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
  }
  .personal_head {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #212121;
  }
  .inputs {
    background: #f2f4fc;
    border-radius: 10px;
    border: none;
    padding: 0.8rem 1rem 0.8rem 1rem;
    width: 80%;
    margin-top: 2rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
  
    color: #2053c5;
  }
  .inputs1 {
    background: #eafaee;
    border-radius: 10px;
    border: none;
    padding: 0.8rem 1rem 0.8rem 1rem;
    width: 80%;
    margin-top: 2rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: #2dc653;
  }
  .inputs ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
  }
  
  .skip {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #b7b8bb;
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
    width: 80%;
    margin-top: 1rem;
    cursor: pointer;
  }
  .check{
      width: 15px;
      height: 15px;
      margin-right: 0.5rem;
  }
  