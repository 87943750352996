.container {
  height: 100vh;
  display: flex;
}
.first_contain {
  background-color: #e9effb;
  /* height: 100vh; */
  width: 50%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
  /* border: 2px solid red; */
  /* gap:5%; */
  flex-direction: column;
}

.ImgDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #E9EFFB;
}

.imgg{
  /* width: 95%; */
  /* height:300px; */
  /* height:350px; */
}

.heading {
  color: #1d3557;
  font-family: "Inter";
  font-size: 1.55rem;
  font-style: normal;
  font-weight: 700;
  /* margin-top: 1rem; */
}

.para {
  color: #212121;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-top: -18px; */
  margin-bottom: 1.25rem;
  /* margin-top: 0.5rem; */
  /* border: 2px solid red; */
  /* padding-right: 2rem; */
}
.icon {
  margin-top: 2rem;
  height: 1.5rem;
  width: 1.5rem;
}

/* second div */
.second_contain {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.PersonalContent_div {
  /* border: 2px solid red; */
  /* height: 60%; */
  width: 90%;
  display: flex;
  flex-direction: column;
  /* padding-left: 5rem; */
  /* padding-top: 2rem;
  gap: 2rem; */
  gap:1rem;
}
.personal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 44px;
  color: #212121;
}

.inputs {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 1.5rem;
  color: #565353;
  font-size: 16px;
}
.inputsDrop{
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  width: 80%;
  margin-top: 1.5rem;
  font-size: 16px;

}
.inputsErr {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 80%;
  margin-top: 1.5rem;
  border: 2px solid rgb(190, 48, 48);
  font-size: 16px;
}
.inputsErrDrop {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 0.4rem;
  width: 80%;
  margin-top: 1.5rem;
  border: 2px solid rgb(190, 48, 48);
  font-size: 16px;
}
.inputs::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
  font-size: 16px;
}
.btn{
  background: #2053c5;
  border-radius: 10px;
  border: none;
  padding: 0.9rem 1rem 0.9rem 1rem;
  width: 80%;
  margin-top: 2rem;
  color: #ffffff;
}
.backBtn {
  background: #f6f8fe;
  /* border-radius: 50px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2053c5;
  border: none;
  border-radius: 14px;
   padding: 0.2rem 0.8rem 0.2rem 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backArrow{
    margin-right: 0.5rem;
}

.skip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #b7b8bb;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
  /* width: 80%; */
  /* margin-top: 1rem; */
  cursor: pointer;
}


.personalDetailsDiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.skipShowHide{
  display: none;
}
.nameWrapper{
  display: flex;
   gap: 1rem;
}
.cityContainer{
  display: flex;
   gap: 1rem;
    position: relative;
}
.nextBtn {
  width: 100%;
  padding: 15px 10px;
  color: #ffffff;
  background-color: #2053c5;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}
.skipBtnInLast{
  display:block;
}

/* .logoImg{
  height: 1.8125rem;
  position: absolute;
  top: 2rem;
  left: 4rem;
} */
.CarouselItem{
  box-shadow: none;
  border-bottom: none;
}
.insideCaraouselItem{
  display: flex;
  flex-direction: column;
  gap: 12px;
  }
  .miniImg{
    height: 2rem;
     width: 2rem;
    /* margin-top: 1.5rem;
     margin-bottom: 1rem; */
  }
  .logoImg{
    height: 1.8125rem;
    /* position: absolute; */
    /* top: 2rem;
    left: 4rem; */
margin-left: 3.6rem;
margin-top: 2rem;
  }
.birthContainer{
  display: flex;
   gap: 1rem;
    width:100%;
}
.dotsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9effb;
    padding-top:20px;
}
.activeDots{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #2053C5;
}
.notActiveDots{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #FFF;
}

   @media (max-width:767px){
    .imgg{
      margin-left: 10px;
    }
    .insideCaraouselItem{
      gap:8px;
    }
    .birthContainer{
        gap:2.2rem; 
        flex-direction:column;
    }
    .personalDetailsDiv{
      margin-top: 16px;
    }
    .container{
      flex-direction: column;
      overflow-y: scroll;
    }
    .first_contain{
      width:100%;
      flex-direction: column;
    }
    .logoImg{
      position:static;
      top: 0rem;
    left: 0rem; 
    width: 125.385px;
  height: 20px;
  margin-left: 0rem;
  margin-top: 0rem;
    }
    .logoImgContainer{
      background-color: white;
      width:100%;
      padding:13px 0px 15px 16px;
    }
    .flexForCarousel{
      display: flex;
    padding-top: 16px;
    background: #e9effb;
    }
    .miniImg{
      display: none;
    }
    
    .heading{
      font-size:14px;
    }
    .para{
      font-size: 10px;
     max-width:137px;
     margin-bottom: 0rem;
    }
    .second_contain{
      width:100%;
      justify-content: unset;
      padding-left: 16px; 
    padding-right:16px;
   }
   .PersonalContent_div {
    width:100%;
    padding-top: 0rem;
  }
  .personal_head{
    font-size: 20px;
    line-height: normal;
  }
  .backBtn{
    display:none;
  }
  .skipShowHide{
    display: block;
  }
  .skip{
    font-size: 14px;
    line-height: 18px;
    font-weight:500;
    margin:0;
  }
  .nameWrapper{
    flex-direction: column;
    gap:2.2rem;
  }
  .cityContainer{
    flex-direction: column;
  }
  .skipBtnInLast{
    display: none;
  }
  .nextBtn{
    padding:12px;
      border-radius:0;
      font-size: 16px;
  }
  .dotsContainer{
    /* padding-top:25px; */
    padding-bottom: 25px;
}
.activeDots{
    width: 10px;
    height: 10px;
}
.notActiveDots{
    width: 10px;
    height: 10px;
}
  }
  
  @media(min-width:320px) and (max-width:349px){
    .imgg{
      width: 153px;
    height: 89px;
    }
  }
  
  @media (min-width:350px) and (max-width:767px){
    .imgg{
      width: 163px;
    height: 99px;
    }
   
  } 
