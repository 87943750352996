.container {
  /* border: 2px solid red; */
margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
}

.contain {
  background: linear-gradient(97.29deg, #8bc6ec -27.12%, #9599e2 132.28%);
  border-radius: 16px;
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1.5rem;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.btn1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 127.52%;
  background-color: #ffffff;
  text-align: center;
  border-radius: 12px;
  color: #1d3557;
  border: none;
  padding: 0.8rem;
}

.btn2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 127.52%; 
  text-align: center;
  color: #ffffff;
  background-color:#1d3557;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  border-radius: 13px;
  margin-left: 1rem;
}
