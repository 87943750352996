/* CommonHero*/
.mainContainer {
    /* height: auto; */
    /* height:106rem; */
    display: flex;
    padding-top: 7.81rem;
    padding-bottom: 7.81rem;
    /* gap: 5.81rem; */
    /* width:100%; */
    justify-content: space-between;
    /* background-color: #F0F5FF; */
    background: url("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/featuresBackground.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.leftContainer {
    margin-left: 6.25rem;
    width:40%;
}

.btnHeading {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: rgba(154, 209, 254, 0.50);
    text-transform: uppercase;
    width: fit-content;
}

.btnHeadingTxt {
    color: #333;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.mainheading {
    color: #1D3557;
    font-family: Inter;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 1rem;
}

.description {
    color: #333;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5625rem;
    /* 138.889% */
    margin-top: 1.75rem;
}

.scheduleDemoContainer {
    cursor: pointer;
    display: flex;
    height: 2.75rem;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #0084EF;
    margin-top: 3rem;
    width: fit-content;
}
.scheduleDemoContainer:hover{
    box-shadow: 0px 6px 8px 0px rgba(122, 143, 192, 0.20);
}
.subHeadingStyles{
    color: #1A3355;
text-align: center;
font-family: Inter;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: 1.75rem; /* 155.556% */
padding-bottom: 6.88rem;
padding-left: 10.5rem;
padding-right: 10.5rem;
}
.scheduleDemoText {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 127.523%;
    /* 1.59406rem */
}

.rightContainer {
    width:42%;
    /* width: 38.4375rem; */
    /* height: 21.875rem; */
    height:auto;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.25);
    margin-right: 6.25rem;
}

/* second container*/
.icon {
    padding: 0 2.5rem 1.5rem 0;
    text-align: right;
    cursor: pointer;
    position: relative;
}
  .stickyImage {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    z-index: 999; 
  }
.mainContainerSecond {}

.mainContSecondHeading {
    padding-top: 6rem;
    padding-bottom: 1rem;
    /* padding-bottom: 6.88rem; */
    color: #2B548F;
    font-family: Inter;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemHeading {
    color: #333;
    font-family: Inter;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 127.523%;
    /* 2.23163rem */
    margin-bottom: 1.5rem;
}

.itemSubHeading {
    margin-bottom: 1.25rem;
    color: #4D4D4D;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 136%;
    /* 1.53rem */
}

ul {
    padding-left: 1rem;
}

ul li {
    margin-bottom: 1rem;
    color: rgba(33, 33, 33, 0.80);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.imageContainer{
    width:100vw;
}
.imageContainer img{
    width:100%;
}
.flexContainerSecond {
    display: flex;
    width: 100%;
    gap:9.5rem;
    flex-direction: column;
}

.flexContainerSecondInner {
    display: flex;
    /* gap: 15rem; */
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    height:35.75rem;
}

.flexContainerSecondInner .leftContainerSecond{
    width:66%;
}

.flexContainerSecondInner:nth-child(even) .leftContainerSecond{
    display: flex;
    justify-content: flex-end;
}
.flexContainerSecondInner:nth-child(odd) .leftContainerSecond{
    display: flex;
    justify-content: flex-start;
}
.flexContainerSecondInner .rightContainerSecond{
    width:34%;
}
/* .flexContainerSecondInner .leftContainerSecond img{
    height:35.75rem;
} */

.mainImages{
    height:35.75rem;
}
.flexContainerSecondInner:nth-child(even) {
    flex-direction: row-reverse;
}
/* .flexContainerSecondInner:nth-child(odd) .rightContainerSecond{
    margin-right:9rem;
} 
.flexContainerSecondInner:nth-child(even)  .rightContainerSecond{
    margin-left:7rem;
}  */
.flexContainerSecondInner:nth-child(odd) .coloredBox{
    width: 39.1875rem;
height: 35.75rem;
flex-shrink: 0;
border-radius: 0rem 1rem 1rem 0rem;
}
.flexContainerSecondInner:nth-child(even)  .coloredBox{
    width: 39.1875rem;
height: 35.75rem;
flex-shrink: 0;
border-radius: 1rem 0rem 0rem 1rem;
}

/* main page*/

.leftContainerFlexItem {
    display: flex;
    gap: 0.75rem;
    align-items: center;
}

.rightContainerFlexItem {
}

.text {
    color: #274C82;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    display: inline-flex;
    padding:1.31rem 7.5rem;
    align-items: center;
    gap: 1.875rem;
    background: #E5F4FF;
    width: 100%;
    /* margin-top:2.5rem;
    margin-bottom:3.5rem; */
}
.questionMarkImg{
    height: 2.125rem;
     width: 2.125rem;
}
.btnOuterContainer {
    display: none;
  }
  .scheduleDemoContainerSticky{
    cursor: pointer;
    display: flex;
    height: 2.75rem;
    padding: 1rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #0084EF;
    box-shadow: 0px 6px 8px 0px rgba(154, 170, 207, 0.20);
    width: 100%;
    margin:0 auto;
  }
  .QueriesContainer{
    /* margin-top: 3rem; */
    margin-top: 2.5rem;
  }
  .overlayImageWrapper {
    /* position: absolute; */
    /* right:0; */
    width: 1.125rem;
    height: 1.125rem;
    /* z-index: 1;  */
}

/* .overlayImage {
    width: 3rem;
    height: 3rem;
} */
.heroImg{
    width:100%;
    height:100%;
}
.topIconImg{
    height:5rem;
  }
@media only screen and (max-width: 767px){
    /* .flexContainerSecondInner .leftContainerSecond img{
        height: 19.375rem;
    } */
    .leftContainerSecond{
        position: relative;
    }
    .mobImg{
        margin-top: 0.87rem;
        height: 19.375rem;
        width:100%;
        position: relative;
    }
    .btnOuterContainer {
        display: inline-flex;
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.625rem;
        background: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100;
      }
.flexContainer{
    flex-direction: column;
    background: transparent;
    border-radius: 0.75rem;
border: 1px solid #EDEDED;
width: 19.5rem;
height: 10.25rem;
padding: 1.5rem 3.56rem 1.75rem 3.62rem;
margin-bottom: 0rem;
gap:0.75rem;
}
.text {
    color: #132339;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem; 
    width:10.3125rem;
}
.questionMarkImg{
    width: 1.5rem;
    height: 1.5rem;
}
.scheduleDemoText{
    color: #FFF;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 127.523%;
}
.QueriesContainer{
    margin-top: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leftContainerFlexItem {
    gap: 0.4rem;
}
.mainContainer{
    padding-top: 2.25rem;
    flex-direction: column;
    gap:2.25rem;
    padding-bottom: 2.5rem;
    background: url("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/featuresbackMob.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.leftContainer {
    margin:0 auto;
    width: unset;
}
.btnHeadingTxt{
    color: #1F3D68;
font-family: Inter;
font-size: 0.625rem;
font-style: normal;
font-weight: 700;
line-height: 0.875rem; /* 140% */
text-transform: uppercase;
}
.mainheading{
    color: #1D3557;
text-align: center;
font-family: Inter;
font-size: 1.375rem;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 0.63rem;
}
.description{
    color: #333;
text-align: center;
font-family: Inter;
font-size: 0.8125rem;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 0.975rem */
margin-left: 1.5rem;
margin-right: 1.5rem;
margin-top:1rem;
}
.scheduleDemoContainer{
    margin:1.75rem auto 0rem auto;
    width:85%;
}
.rightContainer {
    width: 19.43838rem;
height: 11.0625rem;
    border-radius: 0.5rem;
    background: rgba(0, 0, 0, 0.25);
    margin:0 auto;
}
.btnHeading{
    margin:0 auto;
    padding:0.25rem;
    border-radius: 0.25rem;
}
.heroImg{
    height: 100%;
    width:100%;
}
.mainContSecondHeading {
    padding-top: 3rem;
    padding-bottom: 0.63rem;
    color: #1D3557;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.60644rem; 
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}
.itemHeading {
    color: #1D3557;
font-family: Inter;
font-size: 1.125rem;
font-style: normal;
font-weight: 700;
line-height: 1.60644rem; /* 142.792% */
/* margin-bottom: 0.63rem; */
margin:0rem 1.7rem 0.63rem 1.5rem;
}
.itemSubHeading {
    /* margin-bottom: 0.75rem; */
    margin:0rem 1.5rem 0.63rem 1.5rem;
    color: #666;
font-family: Inter;
font-size: 0.8125rem;
font-style: normal;
font-weight: 500;
line-height: 1.07094rem; /* 131.808% */
}
.flexContainerSecondInner:nth-child(odd) .rightContainerSecond{
    margin-right:0rem;
} 
.flexContainerSecondInner:nth-child(even)  .rightContainerSecond{
    margin-left:0rem;
} 
.flexContainerSecondInner .leftContainerSecond{
    width:100%;
}
.flexContainerSecondInner .rightContainerSecond{
    width:100%;
}
.flexContainerSecondInner:nth-child(even) {
    flex-direction: column-reverse;
}
.flexContainerSecondInner {
    height:unset;
    flex-direction: column-reverse;
}
ul{
    padding-left: 2rem;
    padding-right: 1rem;
}
ul li {
    margin-bottom: 0.38rem;
    color: #666;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: 1.07094rem; /* 142.792% */
}
.flexContainerSecond{
    margin-top: 3rem;
    gap:4.12rem;
}
.flexContainerSecondInner:nth-child(odd) .coloredBox{
    width: 100%;
height: 17.375rem;
border-radius: 0rem ;
margin-top: 1.25rem;
}
.flexContainerSecondInner:nth-child(even)  .coloredBox{
    width: 100%;
height: 17.375rem;
border-radius: 0rem;
margin-top: 1.25rem;
}
.stickyImage {
    bottom: 70px; 
    right: -20px; 
    z-index: 999; 
  }
  .topIconImg{
    height:4rem;
  }
}