@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.show-join-pro-button{
  height: 3rem !important;
}
.hide-join-pro-button{
  height: 0 !important;
}
.modal{
  z-index: 99999;
}

.forgotPassword {
  font-family: "Inter";
  float: right;
  font-size: 14px;
  color: #3b5998;
  cursor: pointer;
}
.banner-img img {
  width: 100%;
  margin-left: 30px;
}
.banner-home {
  background-image: url("../Assets/Landing/banner-bg.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 78%;
  padding: 70px 0 120px;
}
.dashboard-header a.navbar-brand {
  font-size: 0;
}
.header.dashboard-header {
  padding-top: 0;
}
.dashboard-header nav.navbar.navbar-expand-lg.navbar-light {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
}
.banner-left h2 {
  font-size: 40px;
  line-height: 138.1%;
  color: #252a31;
}
.banner-left p {
  font-size: 18px;
  line-height: 29px;
  font-family: "Inter", sans-serif;
}
.headeing h2 {
  font-size: 30px;
}
.listing-left h2 {
  font-size: 30px;
}
#myTab {
  border: none;
  margin-bottom: 15px;
  justify-content: center;
  margin-top: 15px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
}
.tab-home .nav-tabs .nav-link {
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #00000078;
  font-family: "Inter", sans-serif;
  height: 38px;
  padding: 0 30px !important;
  background: rgb(196 196 196 / 40%);
}
.tab-home .nav-tabs .nav-link.active {
  background: linear-gradient(91.61deg, #3b5998 0.52%, #132f6b 97.92%);
  color: #fff;
}
.form-group input {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  height: 40px;
  padding: 10px;
  font-family: "Inter", sans-serif;
}
.form-group {
  margin-bottom: 20px;
}
.form-group::placeholder {
  font-size: 14px;
}
.form-group input:focus-visible {
  outline: none;
}
button.btn.btn-create {
  background: #3b5998;
  border-radius: 6px;
  width: 100%;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}
button.btn.btn-create.sign-google {
  background: #e8edf1;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
button.btn.btn-create.sign-google i.fab.fa-google-plus-g {
  margin-right: 8px;
}
button.btn.btn-create.create-account {
  margin-top: 8px;
}
.or span {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #bababa;
  font-family: "Inter", sans-serif;
  display: inline-block;
  background: #fff;
  width: 40px;
}
.or {
  text-align: center;
  padding: 7px 0;
  position: relative;
}
.or:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 18px;
  background: #dde3e9;
  left: 0;
  z-index: -1;
}
// p {
//   font-weight: normal;
//   font-size: 18px;
//   color: #4f5e71;
//   font-family: "Inter", sans-serif;
// }
.design-box h2 {
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: #252a31;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
}
.design-box p {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #252a31;
}
.listing-right img {
  width: 100%;
  position: absolute;
  right: -120px;
  height: 500px;
}
.last-img-div .listing-right img{
  height: 620px;
}
.listing-right {
  position: relative;
}
.design-listing {
  overflow: hidden;
}
.listing-left {
  height: 462px;
}
.listing-left h3 {
  background: #cef0ff;
  border-radius: 8px;
  font-weight: normal;
  font-size: 22px;
  color: #0c93cc;
  padding: 10px 12px;
  display: inline-block;
}
button.know-more {
  background: #3b5998;
  border-radius: 6px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  border: none;
  padding: 0 20px;
}
.flex-row-reverse .listing-right img {
  width: 100%;
  position: absolute;
  right: inherit;
  left: -120px;
}
.flex-row-reverse .listing-right.brand-img img {
  left: -10px;
  height: 570px;
  max-width: 500px;
}
.brand-img img {
  max-width: 450px;
}
.crm .listing-left h3 {
  background: #e4e5ff;
  color: #6a6dcdad;
}
.marketplace .listing-left h3 {
  background: #e2fbf0;
  color: #14d08191;
}
.mobileapp .listing-left h3 {
  background: #fee5f3;
  color: #ff51b1a1;
}
.listing-left img {
  width: 100%;
  max-width: 120px;
  height: 35px;
  margin-right: 10px;
}
.brand-offer h3 {
  background: #cef0ff;
  border-radius: 12px;
  display: inline-block;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #2cace2;
  padding: 10px 12px;
}
.brand-offer {
  background-image: url("../Assets/Landing/brand-offer.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0;
  height: 600px;
}
.brand-offer h2 {
  font-size: 32px;
  text-align: center;
  color: #252a31;
}
.plan img {
  width: 100%;
}

.plan-text span {
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  margin-right: 15px;
  font-family: "Inter", sans-serif;
}
.any-question {
  background: radial-gradient(
                  61.25% 61.25% at 50% 50.18%,
                  #2f4776 0%,
                  #1a3266 100%
  );
  padding: 60px 0;
  color: #fff;
}
.any-question h2 {
  font-size: 40px;
  margin-bottom: 0;
  color:white;
}
.busines-product.design-advantage .boxes img {
  width: 100%;
  max-width: 140px;
}
.any-question p {
  line-height: 29px;
  color: #ffffff;
  font-weight: 300;
}
.any-question button.know-more {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #252a31;
  height: 44px;
}

.product {
  background-image: url("../Assets/Product/product-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-banner h2 {
  font-weight: normal;
  font-size: 36px;
  line-height: 146.1%;
  color: #ffffff;
  margin-bottom: 0;
}
.product-banner p {
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.69);
}
.product-banner button.know-more {
  background: #ffffff;
  border-radius: 6px;
  color: #2f4776;
  font-weight: 500;
  margin-top: 10px;
}
.product-img img {
  width: 100%;
  max-width: 68%;
}
.headeing h6 {
  background: #fff0f0;
  border-radius: 8px;
  font-weight: normal;
  font-size: 20px;
  color: #f28f8fa8;
  padding: 10px 12px;
  display: inline-block;
}
.boxes h2 {
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  font-family: "Inter", sans-serif;
}
.boxes h2 span {
  font-weight: 700;
}
.busines-product .boxes h2 {
  font-size: 16px;
  padding: 0 20px;
}
.boxes {
  padding: 0 15px;
}
.mobile-app .boxes img {
  width: 100%;
  max-width: 130px;
}
.leadmanagement .headeing h6 {
  color: #6a6dcdad;
  background: #e4e5ff;
}
.lead-mange img {
  width: 100%;
}
// .mobile-app .boxes h2 {
//   text-align: left;
// }
.mobile-app .boxes {
  padding: 20px 0px;
  width: 50%;
}
.both-img img {
  margin-right: 10px;
  margin-left: 10px;
  width: 100%;
  max-width: 160px;
}
.phone-photo img {
  width: 100%;
  max-width: 250px;
  margin-top: 0px;
}

.mobile-app {
  background-image: url("../Assets/Product/mobile-bg.png");
  background-position: -20px 50px;
  background-repeat: no-repeat;
  background-size: 90% 110%;
}

.login-banner {
  background-image: url("../Assets/Login/login-bg.svg");
  background-size: cover;
  background-position: center;
  padding: 20px 0 220px;
}
.login-banner .login-form {
  background: #fafafa;
  border: 1px solid #dde3e9;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 6px;
  padding: 25px 25px 40px;
  margin-top: 12px;
}
.login-banner .login-form .or span {
  background-color: #fafafa;
  z-index: 9;
  position: relative;
}
.login-banner .or:after {
  background: #e3e3e3;
  z-index: 1;
}

.register-now h2 {
  color: #ffffff;
  padding-bottom: 20px;
}
.register-now .boxes h2 {
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.register-now .boxes img {
  width: 100%;
  max-width: 120px;
  height: 120px;
}

.register-now .boxes {
  width: 49%;
}

/********** Pricing Page css start here ******************/

.pricing-text ul {
  display: flex;
  justify-content: center;
  padding: 0;
}

.pricing-text ul li {
  font-weight: normal;
  font-size: 20px;
  line-height: 39px;
  color: #4f5e71;
  margin: 0 20px;
  font-family: "Inter", sans-serif;
}
::marker {
  font-size: 22px;
}
.pricing-text span {
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  color: #89c5cc;
  margin-left: 16px;
  font-family: "Inter", sans-serif;
  position: relative;
  top: 2px;
}
.pricing-text button.know-more {
  font-size: 16px;
  font-weight: 600;
  padding: 0px 25px;
  border-radius: 4px;
  height: 42px;
}
.pricing-text {
  margin-bottom: 30px;
  position: relative;
}
img.arrow-price {
  position: absolute;
  top: -40px;
  right: 160px;
  width: 100%;
  max-width: 160px;
}
.pricing-banner .or {
  width: auto;
  max-width: 30%;
  margin: 60px auto 30px;
}
.Collapsible {
  padding: 20px 0 10px;
  border-bottom: 1px solid #cccccc;
  text-align: left;
}
.Collapsible__trigger {
  font-weight: 500;
  font-size: 22px;
  line-height: 44px;
  color: #3d3c3c;
  padding-bottom: 10px;
  font-family: "Inter", sans-serif;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Collapsible__trigger p {
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #7a7a7a;
  margin: 0;
  padding: 15px 0;
}
.Collapsible__trigger svg {
  transition: transform 300ms;
}
.Collapsible__trigger.is-open svg {
  transform: rotateZ(180deg);
}
.faq-content-text {
  width: 100%;
  max-width: 75%;
  margin: 40px auto 80px;
}

.banner-home.profile-build {
  background-image: none;
  padding-top: 10px;
}
.profile-build .form-group.chechbox input[type="checkbox"] {
  width: auto;
  line-height: 0;
  height: 13px;
  margin-right: 8px;
  position: relative;
  top: 1px;
}
.profile-build #myTab li.nav-item {
  width: 50%;
}
.profile-build .tab-home .nav-tabs .nav-link {
  width: 100%;
}
.profile-build .form-group.chechbox {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #797979;
}
.profile-build .login-form h4 {
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.profile-build .login-form p {
  font-size: 14px;
  text-align: center;
  margin: 10px;
}
.profile-build .login-form p a {
  cursor: pointer;
  text-decoration: underline;
}
.profile-build .login-form h6 {
  font-size: 16px;
  font-family: "Inter", sans-serif;
}
.profile-build .login-form h6 span {
  color: red;
}
.banner-home.profile-build .banner-left h2 {
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
  font-size: 30px;
}
.upload {
  background: #dde3e9;
  border: 1px dashed #3d3c3c;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  padding: 15px;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
}
.upload h3 {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #979797;
  margin: 0;
}
.upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.brandExploreBtn {
  background: #3b5998;
  border-radius: 6px;
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  border: none;
  padding: 14px 30px;
}

/////// Form styles /////

.invalidFeedback {
  font-family: "Inter", sans-serif;
}

/// dashboard layout ///
.main-wraper {
  display: flex;
  height: 100%;
}

.privacy-police h3,
.privacy-police h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 146.1%;
  color: #000000;
  font-family: "Inter", sans-serif;
}

.privacy-police p {
  font-size: 15px;
  line-height: 30px;
  color: #000000;
  font-family: "Inter", sans-serif;
  padding-bottom: 10px;
}

.privacy-police h6 {
  font-size: 18px;
}

.request_form {
  font-family: Inter;
  h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 26px;
    color: #000000;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
  }
 
  input {
    background-color: #f3f3f3;
    border: 0px;
    width: 80%;
    &:focus {
      background-color: #f3f3f3;
    }
  }
  textarea {
    background-color: #f3f3f3;
    border: 0px;
    width: 80%;
    &:focus {
      background-color: #f3f3f3;
    }
  }
  button {
    width: 110px;
  }
  .request_header {
    img {
      width: 100%;
      text-align: center;
    }
  }
}
.main-contact {
  font-family: "Inter", sans-serif;
  border: 2px solid red;
}
.bg_contact {
  background-image: url("../Assets/Dashboard/contactHeader.png");
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  .contact_content {
    padding-top: 17%;
    h1 {
      align-items: center;
      font-weight: 400;
      font-size: 55px;
      line-height: 64px;
      text-transform: uppercase;
      color: #ffffff;
    }
    p {
      font-weight: 300;
      font-size: 24px;
      line-height: 39px;
      letter-spacing: 0.165em;

      color: #bdbdbd;
    }
  }
}

.addressContact{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.0015em;
color: #212121;
}
.paraContact{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
margin-top: 0.5rem;

color: #3C3C3C;

}
.nextSection_contact {

  border-radius: 10px;
  font-family: "Inter", sans-serif;
padding-top: 3rem;
  h3 {
    font-weight: 500;
    font-size: 33px;
    text-align: center;
    color: #000000;
  
  }
  h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    margin-top: 10px;
    color: #000000;
  }
  .label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

text-align: center;
letter-spacing: 0.0015em;

color: #212121;

  }

  textarea {
    width: 100%;
  }
  p {
    font-size: 16px;
  }
}
.ContactBtn{
  
background: #0038FF;
border-radius: 4px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.0015em;
padding: 0.5rem 1rem 0.5rem 1rem;
color: #FFFFFF;
border: none;
width: 26%;
}
.ContactBtnMob{ 
  background: #0038FF;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: #FFFFFF;
  border: none;
  // width: 26%;
  }

.contact_sections {
  input {
    border: 0px;
    border-bottom: 1px solid #5b5b5b99;
    background-color: #f8f8f8;

  }
}

.plan {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  border-radius: 18px;
}

.plan h2 {
  background: #89c5cc;
  border-radius: 18px 18px 0px 0px;
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 38px;
  font-weight: 400;
  color: #fff;
}

.plan-body h3 {
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #3d3c3c;
  text-align: center;
  font-family: "Inter";
}

.plan-body h3 span {
  font-size: 16px;
}

.plan-body p {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #3d3c3c;
  opacity: 0.7;
  padding: 0 15px;
}

.plan-body ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3d3c3c;
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  margin-left: 12px;
  margin-right: 12px;
}

.get-started {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}

.get-started button.know-more {
  width: 85%;
  margin: 0 auto;
}

.blue .plan-body {
  background: #2f4776;
  border-radius: 0 0 18px 18px;
  color: #fff;
}

.blue .plan-body h3 {
  color: #fff;
  padding-top: 10px;
}

.blue .plan-body p {
  color: #fff;
  opacity: 1;
}

.blue .plan-body ul li {
  color: #fff;
}

.blue .get-started button.know-more {
  background: #ffffff;
  border-radius: 5px;
  color: #3b5998;
  font-weight: 600;
}

.blue .get-started {
  padding-bottom: 30px;
  padding-top: 50px;
}

.plan.blue h2 {
  margin: 0;
}

img.plan-user {
  position: absolute;
  top: -57px;
  z-index: -1;
  left: -92px;
  width: 80%;
}

img.horn {
  position: absolute;
  right: -60px;
  width: 32%;
  bottom: 17px;
}

img.planone {
  position: absolute;
  width: 30%;
  left: -48px;
  bottom: -20px;
}

.blue ul {
  margin-top: 30px;
}

.login-terms {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #8b8a8a;
  margin: 10px 0 0;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
}
.desihn-profasional .design-box img {
  width: 100%;
  max-width: 38%;
}
.mobile-app .boxes:last-child {
  margin-bottom: 20px;
}
.profile-builds .boxes {
  width: 50%;
}
.profile-builds .boxes p {
  font-size: 15px;
}
.feature-missing h2 {
  background: rgba(255, 255, 255, 0.89);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: inline-block;
  padding: 15px 50px;
  color: #3b5998;
  opacity: 0.8;
  font-size: 24px;
  margin-bottom: 30px;
  width: 100%;
}

.formInput ::placeholder{
  color: #DEDEDE;
}

.profile-build know-moregroup {
  margin-bottom: 18px;
}
.profile-builds .boxes img {
  width: 100%;
  max-width: 150px;
}
.footer-one p {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.footer-one p:last-child {
  text-decoration-line: underline;
  color: #6d6d6d;
  font-weight: 500;
}
section.main-section.brand-page.brands-offers {
  width: 100%;
}

.product-banner button.know-more:hover {
  color: #2f4776;
}
.any-question button.know-more:hover {
  color: #252a31;
}
.mobile_viwe {
  display: none;
}
.mobile_viwe a.nav-link {
  padding: 0 !important;
  color: #3b5998;
  font-family: "Inter";
  font-weight: 600;
}
.join-as-pro {
  display: none;
}
img.mobile-img {
  display: none;
}
.months-plans{
  padding: 0 15px;
}
.any-question .col-md-8 {
  padding-left: 80px;
}
.pricing-banner .slick-slide:nth-child(2) .months-plans .col-md-9 {
  width: 90% !important;
}
.desihn-profasional.eassy-start .slick-slide:nth-child(2) .months-plans .col-md-9 {
  width: 90% !important;
}
.mobile-app .boxes h2 {
  font-size: 16px;
  padding: 0 20px;
}
.mobile-desktop{
  display: none;
}
.offcanvas-start{
  z-index: 999999 !important;
}

@media only screen and (min-width: 1400px) {
  img.plan-user{
    width: 70%;
    top: -67px;
  }
  img.planone {
    bottom: -24px;
    left: -60px;
  }
  img.horn {
    bottom: 6px;
    right: -66px;
    width: 30%;
  }
}

@media screen and (max-width: 1200px) {
  img.horn,
  img.planone,
  img.plan-user {
    display: none;
  }
  .any-question .col-md-8 {
    padding-left: 15px;
  }
  .months-plans {
    padding: 0;
  }
  .design-box p br {
    display: none;
  }
  .desihn-profasional.eassy-start .row.justify-content-around .col-md-3 {
    width: 33%;
  }
  .banner-home .col-md-4,
  .banner-home .col-md-7 {
    width: 50%;
  }
  .banner-home {
    background-position: top;
    background-size: 100%;
  }
  section.banner-home.profile-build {
    padding-bottom: 40px;
  }
  .login-banner {
    background-position: top;
    padding: 20px 0 40px;
  }
  .busines-product.design-advantage .d-flex {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .busines-product.design-advantage .d-flex .boxes {
    text-align: center;
    width: 40%;
  }
  .register-now .boxes h2 {
    color: #222;
  }
  .pricing-banner .col-md-3 {
    width: 33%;
  }
  .login-banner .login-form {
    margin-top: 10px;
  }
  .both-img img {
    width: 100%;
    max-width: 30%;
  }
  .both-img.mt-5 {
    text-align: center;
  }
  .busines-product .boxes h2 {
    padding: 0 5px;
  }
  .banner-img img {
    margin-left: 0px;
  }
  .register-now .boxes h2 br {
    display: none;
  }
  .feature-missing h2{
    font-size: 20px;
    padding: 15px 20px;
  }
  .desihn-profasional.eassy-start .slick-slide:nth-child(2) .months-plans .col-md-9 {
    width: 75% !important;
  }
  .pricing-banner .slick-slide:nth-child(2) .months-plans .col-md-9 {
    width: 75% !important;
  }
}

@media screen and (max-width: 1024px) {
  .pricing-banner .col-md-3 {
    width: 100%;
  }
  .pricing-banner .plan.blue {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 992px) {
  .bg_contact .contact_content h1 {
    font-size: 40px;
  }

  .mobile_viwe {
    display: block;
  }
  .navbar-light .navbar-brand {
    margin-left: auto;
    margin-right: auto;
  }
  .dashboard-header a.navbar-brand {
    font-size: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .bg_contact .contact_content p {
    font-size: 18px;
  }
  .bg_contact {
    height: 100%;
  }
  .nextSection_contact {
    margin-top: 50px;
    margin-bottom: 40px;
    padding-bottom: 30px;
  }
  .nextSection_contact h3 {
    margin-bottom: 0;
  }
  .bg_contact .contact_content {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .nextSection_contact .col-md-7.ps-5 {
    padding-left: 0px !important;
    margin-bottom: 20px;
    width: 100%;
  }
  .nextSection_contact .col-md-5 {
    width: 100%;
  }
  .nextSection_contact input {
    width: 100%;
  }
  .nextSection_contact .d-flex {
    flex-direction: column;
  }
  .all-deal {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start !important;
    overflow: auto;
  }

  .brand-box {
    width: 25% !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("../Images/sidebarOpen.svg") !important;
  }
  .navbar-light .navbar-toggler {
    border: none;
    padding: 0;
  }
  .nextSection_contact input[type="checkbox"] {
    width: auto;
  }

}

@media screen and (max-width: 767px) {
  .bg_contact .contact_content h1 {
    font-size: 30px;
    line-height: 36px;
  }
  .busines-product.leadmanagement .d-flex.mt-3 {
    margin-top: 0 !important;
  }
  .desihn-profasional.eassy-start .slick-slide:nth-child(2) .months-plans .col-md-9 {
    width: 100% !important;
  }
  .pricing-banner .slick-slide:nth-child(2) .months-plans .col-md-9 {
    width: 100% !important;
  }
  .leadmanagement .col-md-12.text-start.mb-5 {
    margin-bottom: 0 !important;
  }
  .busines-product.leadmanagement .headeing {
    text-align: center;
  }
  .mobile-app .headeing {
    text-align: center;
  }
  .profile-builds {
    flex-direction: column;
  }
  .busines-product.leadmanagement .d-flex, .mobile-app .d-flex {
    flex-direction: column;
  }
  .mobile-app .boxes h2 br{
    display: none;
  }
  .mobile-app .boxes {
    padding: 10px 0px 0;
    width: 100%;
  }
  .phone-photo img {
    max-width: 200px;
  }
  .busines-product.design-advantage .d-flex .boxes {
    width: 100%;
  }
  .profile-builds .boxes {
    width: 100%;
  }
  img.arrow-price {
    display: none;
  }
  // .design-listing.marketplace .col-md-5,
  // .design-listing.marketplace .col-md-7 {
  //   width: 49%;
  // }
  .design-listing.marketplace .listing-left.text-start {
    text-align: center !important;
  }
  .design-listing.marketplace .listing-left.text-start h2 {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }
  .design-listing.marketplace p.mt-4 {
    margin-top: 10px !important;
    margin-bottom: 0;
    line-height: 20px;
  }

  p.login-terms {
    padding-top: 10px;
  }
  .desihn-profasional {
    padding-top: 40px;
  }
  .months-plans {
    padding: 0 30px !important;
    margin-top: 0 !important;
  }
  .plan-text {
    padding-top: 25px;
  }
  .blue .get-started {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .blue ul {
    margin-top: 0;
  }
  .listing-left h2 {
    font-size: 22px;
  }
  .headeing h2 {
    font-size: 24px;
  }
  .navbar-brand img {
    max-width: 155px;
  }
  .brand-box {
    width: 40% !important;
  }
  .banner-home.login-banner .row {
    flex-direction: column;
  }
  .pricing-banner .col-md-3 {
    width: 100%;
  }
  .banner-home .col-md-4,
  .banner-home .col-md-7 {
    width: 100%;
  }
  .desihn-profasional.eassy-start .row.justify-content-around .col-md-3 {
    width: 100%;
  }
  .bg_contact .contact_content p {
    font-size: 16px;
    line-height: 26px;
  }
  .banner-home {
    // padding: 40px 0 60px;
    // background-position: top;
    // background-repeat: no-repeat;
    // background-size: 100%;
    background: none;
  }
  .banner-home .row {
    flex-direction: column-reverse;
  }
  .banner-home .banner-img {
    margin-bottom: 30px;
  }
  .navbar .container-fluid {
    padding: 0;
  }
  .or {
    padding: 15px 0;
  }
  .or:after {
    top: 26px;
  }
  .banner-home p {
    margin: 0;
    font-size: 16px;
    margin-top: 0px;
    line-height: 22px;
  }
  .desihn-profasional .headeing {
    text-align: center;
  }
  .design-box img {
    width: 100%;
    max-width: 100px;
  }
  .desihn-profasional .col-md-4 {
    width: 100%;
    max-width: 50%;
    margin-top: 20px !important;
  }
  .design-box p {
    display: none;
  }
  .design-box h2 {
    font-size: 16px;
    line-height: 24px;
    width: 150px;
    text-overflow: ellipsis;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
  }
  .design-listing.mt-5.mb-5.pt-5 {
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    border-top: 1px solid #eeeded;
  }
  .listing-left.text-start {
    text-align: center !important;
    height: inherit;
  }
  .listing-right img {
    display: none;
  }
  .design-listing.crm.mt-5.pt-5 {
    border-top: 1px solid #eeeded;
    border-bottom: 1px solid #eeeded;
    margin-top: 80px !important;
    padding-top: 0 !important;
    padding-bottom: 80px;
  }
  section.design-listing.mobileapp {
    border-top: 1px solid #eeeded;
    margin-top: 80px !important;
  }
  .listing-left h3 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
  .last-img-div .listing-right img {
    display: block;
    position: absolute;
    left: -35px;
    top: -10px;
    width: 220px;
    right: 0;
  }
  .flex-row-reverse .listing-right.brand-img img {
    position: inherit;
    display: block;
    left: 0;
    top: 50px;
    height: 380px;
  }

  .flex-row-reverse .listing-right.brand-img img {
    height: 500px;
    max-width: 100%;
    object-fit: contain;
    top: 30px;
    left: 0px;
  }

  .brand-offer h3 {
    margin-top: 10px;
  }
  .brandExploreBtn {
    margin-top: 10px;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 500;
  }
  .desihn-profasional.eassy-start {
    border-top: 1px solid #eeeded;
    border-bottom: 1px solid #eeeded;
    margin-bottom: 50px !important;
    padding-bottom: 30px;
  }
  .plan-body h3 {
    text-align: left;
    padding-left: 15px;
    font-size: 28px;
  }
  .plan-body p {
    text-align: left;
  }
  .brand-offer {
    height: 394px;
    background-image: url("../Assets/Landing/mobile-design.png");
    padding-top: 0;
  }
  .brand-offer h2 {
    font-size: 24px;
    margin-bottom: 0 !important;
  }
  .brand-offer button.know-more {
    margin-top: 20px !important;
  }
  .mobile-none {
    display: none;
  }
  .desihn-profasional.eassy-start .row.justify-content-around {
    margin-top: 0 !important;
  }
  .plan-text span {
    font-size: 18px;
    line-height: inherit;
    margin-top: 15px;
    margin-right: 0;
    display: inherit;
  }
  .plan-text button.know-more.mt-5 {
    margin-top: 15px !important;
  }
  .eassy-start .plan-text button.know-more.mt-5 {
    margin-top: 15px !important;
    background: transparent;
    color: #3b5998;
    border: 1px solid #3b5998;
    font-weight: 600;
  }
  .any-question .col-md-8,
  .any-question .col-md-4 {
    width: 50%;
  }
  .main_deals .d-flex {
    margin-top: 10px;
  }
  .any-question h2 {
    font-size: 18px;
    margin-bottom: 0px;
    color:white;
  }
  .any-question p {
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 0;
  }
  .any-question {
    padding: 30px 0;
  }
  .any-question button.know-more {
    height: 40px;
    font-size: 13px;
    padding: 0 10px;
  }

  .pt-4.pb-4.text-center {
    text-align: left !important;
    padding: 0 15px 20px !important;
  }
  .d-flex.mt-5.mb-5 {
    margin: 20px 0 !important;
  }
  #navbarSupportedContent {
    margin-top: 15px;
  }
  .banner-left h2 {
    font-size: 24px;
    margin-bottom: 4px;
    line-height: 30px;
  }
  // .slick-prev,
  // .slick-next {
  //   display: none !important;
  // }
  .pricing-banner .slick-prev,
  .desihn-profasional .slick-prev {
    left: 0;
    z-index: 999;
  }
  .pricing-banner .slick-next,
  .desihn-profasional .slick-next {
    right: 10px;
    z-index: 999;
  }
  .pricing-banner .slick-prev:before,
  .pricing-banner .slick-next:before,
  .desihn-profasional .slick-prev:before,
  .desihn-profasional .slick-next:before {
    color: black;
    font-size: 28px;
  }
  .headeing h2 br {
    display: none;
  }
  .busines-product.design-advantage .d-flex {
    flex-wrap: wrap;
  }
  .busines-product.design-advantage .d-flex .boxes {
    text-align: center;
    width: 100%;
  }
  .busines-product.design-advantage .d-flex .boxes h2 {
    font-size: 16px;
    padding: 0 10px 10px;
  }
  .product-img img {
    max-width: 80%;
    margin-top: 25px;
  }
  .product-banner h2 {
    font-size: 28px;
  }
  .lead-mange img {
    margin-top: 20px;
  }
  .mobile-app {
    background: transparent;
  }
  .both-img img {
    width: 100%;
    max-width: 35%;
  }
  .register-now .boxes h2 {
    color: #222;
    font-size: 14px;
  }
  .register-now .boxes {
    padding: 0 5px;
  }
  .plan.blue {
    margin: 0px 0;
  }
  .both-img.mt-5 {
    margin-bottom: 40px;
    text-align: center;
  }
  .faq-content h2 {
    font-size: 22px;
    margin: 0;
  }
  .faq-content-text {
    margin-top: 10px;
    max-width: 100%;
    margin-bottom: 40px;
  }
  .Collapsible__trigger {
    font-size: 16px;
    line-height: 30px;
  }
  .pricing-text ul {
    list-style: none;
  }
  .pricing-text ul li {
    font-size: 14px;
    line-height: 14px;
    margin: 0 5px;
  }
  .pricing-banner .or {
    max-width: 80%;
    margin: 0px auto 10px;
  }
  .faq .col-md-12.mt-5.text-center {
    margin-top: 10px !important;
  }
  .pricing-banner .row {
    justify-content: center;
  }
  .pricing-banner .row.justify-content-around.mt-5.pt-2 {
    margin-top: 20px !important;
  }
  .feature-missing h2 {
    font-size: 22px;
    margin-bottom: 20px;
    background: transparent;
    color: #000;
  }
  .headeing.feature-missing h2 {
    padding: 15px 20px;
    color: #3b5998;
  }
  .profile-builds .boxes p {
    font-size: 14px;
    margin-top: 10px;
  }
  .banner-home.profile-build .row {
    flex-direction: column;
    margin-top: 25px;
  }
  .about-pagess .pe-5.about-text {
    padding-right: 0 !important;
    margin-top: 25px;
  }
  .about-pagess.mt-5 {
    margin-top: 0 !important;
  }
  .about-pagess .row {
    flex-direction: column-reverse;
  }
  .about-pagess-tow {
    margin-top: 0 !important;
  }
  .about-pagess-tow .ms-5 {
    margin-left: 0 !important;
    margin-top: 30px;
  }
  .about-pagess .pe-6 {
    margin-top: 25px;
  }
  .about-pagess-last {
    padding-bottom: 0 !important;
    margin-bottom: 20px !important;
  }
  .privacy-police .col-md-12.mt-5.pt-4 {
    margin-top: 20px !important;
  }
  .privacy-police h3,
  .privacy-police h6 {
    font-size: 16px;
  }
  .request_form .d-flex {
    flex-direction: column;
  }
  .request_form input,
  .request_form textarea {
    width: 100%;
  }
  .request_form h3 {
    font-size: 22px;
  }
  .lead-mange {
    margin-bottom: 25px;
  }
  .tab-home .nav-tabs .nav-link {
    font-weight: 400;
  }
  .any-question .col-md-4 {
    align-items: flex-start !important;
  }
  .pricing-text h2 {
    font-size: 20px;
  }
  .pricing-text a {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  .pricing-text span {
    margin: 10px 0;
    display: inherit;
  }
  .main_deals p {
    display: none;
  }
  .main_deals button {
    margin-top: 0;
    width: 100%;
  }
  .mobile-app .boxes h2 {
    text-align: center;
    padding-bottom: 15px;
  }
  .emailBtn form .form-group {
    margin-bottom: 12px;
  }
  .join-as-pro {
    width: 100%;
    border: none;
    border-radius: 0 !important;
    font-weight: 600 !important;
    position: fixed;
    bottom: 0;
    display: block;
    font-size: 16px !important;
  }
  .last-img-div .listing-right img {
    display: block;
    position: inherit;
    left: 0;
    top: 0;
    width: 270px;
    right: 0;
    margin: 70px auto 0;
    height: auto;
  }
  .design-listing.marketplace .row {
    flex-direction: column-reverse;
  }
  .design-listing.marketplace .col-md-5.mt-5.pt-4 {
    margin-top: 0 !important;
  }
  .design-listing.marketplace.mt-5.pt-5 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .listing-right img.mobile-img {
    display: block;
    position: inherit;
    right: 0;
    height: 100%;
    margin: 70px auto 0;
    left: 0;
  }
  .listing-right img.desktop-img {
    display: none;
  }
  .design-listing .col-md-5.mt-5.pt-4 {
    padding-top: 0 !important;
  }
  .design-listing .row {
    flex-direction: column-reverse !important;
  }
  // section.design-listing.mobileapp .flex-row-reverse {
  //   flex-direction: row-reverse !important;
  // }
  section.design-listing.mobileapp .flex-row-reverse .col-md-5.mt-5.pt-4 {
    margin-top: 10px !important;
    margin-bottom: 30px;
  }
  .mobile-desktop{
    display: block;
    margin-bottom: 30px;
  }
  .headeing.desktop-desktop{
    display: none;
  }
  .mobile-app .boxes:last-child {
    margin-bottom: 0;
  }
  .busines-product.design-advantage {
    border-bottom: 1px solid #F0F0F0;
  }
  .busines-product.leadmanagement {
    border-bottom: 1px solid #F0F0F0;
  }
}

@media screen and (max-width: 480px) {
  .product-banner h2 {
    font-size: 22px;
  }
  .product-banner p {
    font-size: 14px;
    margin: 0;
  }
}

@media screen and (max-width: 350px) {
  .busines-product.design-advantage .d-flex .boxes {
    width: 100%;
  }
  .pricing-text ul li {
    font-size: 13px;
  }
  br {
    display: none !important;
  }
  .mobile-app .boxes h2 {
    text-align: left;
    font-size: 16px;
  }
  .boxes h2 {
    font-size: 14px;
    line-height: 24px;
  }
  .boxes {
    padding: 0 5px;
  }
  .any-question button.know-more {
    font-size: 12px;
    padding: 0 5px;
  }
  .any-question p {
    font-size: 10px;
  }
  .any-question h2 {
    font-size: 16px;
    color:white;
  }
  .listing-left h3 {
    font-size: 16px;
  }
  .design-listing.marketplace .listing-left.text-start h2 {
    font-size: 15px;
    line-height: 22px;
  }
  .design-listing.marketplace p.mt-4 {
    font-size: 14px;
  }
  .brand-offer h2 {
    font-size: 18px;
  }
}
