

.buttons{
  display: flex;
padding: 0.25rem 0.5rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.25rem;
background: rgba(0, 0, 0, 0.08);
text-transform: capitalize;
}
.buttonsText{
  color: rgba(0, 0, 0, 0.70);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
}
.inBoxHeading{
  color: #4A4A4F;
font-family: Inter;
font-size: 1.875rem;
font-style: normal;
font-weight: 600;
line-height: 2rem; 
letter-spacing: -0.03125rem;
}

.inBoxSummary{
  color: #212123;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; 
width:19rem;
}
.arrayHeadings{
  color: #212123;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.5rem; 
}
.arrayData{
  color: #212123;
  display: flex;
  align-items: center;
  gap: 10px;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
word-wrap: break-word;

font-weight: 400;
/* line-height: 1.5rem; 150% */
}
.arrayData>div{
  line-height: 1.5;
  word-spacing: normal;
  white-space: normal;
  overflow: hidden; /* or 'visible', 'scroll', 'auto' depending on your needs */
  width: auto;
}
.blueButtonContainer{
  /* margin-right:2rem; */
}
.blueButton{
  display: flex;
padding: 0.75rem 3.75rem;
justify-content: center;
align-items: center;
gap: 0.4rem;
border-radius: 0.5rem;
background: #0084EF;
/* background-color: red; */
width: 90%;
margin: 0px auto;
}
.blueButton:hover{
  border-radius: 0.5rem;
background: #0068BD;
}
.blueButtonText{
  color: #FFF;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.8125rem; /* 145% */
}
.blueBorderButton{
  display: flex;
padding: 0.75rem 3.875rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.5rem;
border: 1px solid var(--blue, #0084EF);
background: #FFF;
}
.blueBorderButton:hover{
  border-radius: 0.5rem;
border: 1px solid var(--blue, #0084EF);
background: #E5F4FF;
}
.blueBorderButtonText{
  font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.8125rem; /* 145% */
background: var(--blue, linear-gradient(180deg, #0084EF 0%, #0766AD 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.radioBtn{
  accent-color:#00447A;
}
.checkCircle{
width: 1.25rem;
height: 1.25rem;
}
.showFeaturesBtn{
  color: #888;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
display:flex;
justify-content: center;
align-items: center;
text-underline-offset: 0.1rem;
}
.showFeaturesBtn:hover{
  color:#0084EF;
}
.amount{
  color: #212123;
font-family: Inter;
font-size: 2.25rem;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.0625rem;
}
.afterAmount{
  color: #898990;
font-family: Inter;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: 2.1875rem; /* 194.444% */
}
.pro{
  color: #272727;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
vertical-align: middle;
}
.mostPopularBtn{
  display: inline-flex;
padding: 0.25rem 0.5rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 3.125rem;
border: 1px solid #2053C5;
margin-left:0.5rem;
vertical-align: middle;
}
.mostPopularText{
  color: #2053C5;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}
.Mainbox_div {
  max-width: 100%;
  display: flex;
 justify-content: center;
  
  
  flex-wrap: wrap;
  gap:4rem;
  padding-left:60px;
  padding-right: 56px;
}
.box1 {
  /* width: 25%;   */
  height: 100%;

}


.box2 {
  width:30.5%;
   height: 100%;
   border: 1px solid #e6e6e6;
   border-radius: 16px;
   border-radius: 1.25rem;
   /* background: #FFF; */

   box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
   /* height: 53.25rem; */
   padding:2rem 2rem 2rem 2rem;
   box-sizing:border-box;
 }
 .innerHeadingBox1{
   color: #00447A;
text-align: center;
font-family: Inter;
font-size: 2rem;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top:1.37rem;
 }
 .contentBox1{
   display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 2.625rem;
margin-left: 3rem;

 }
 .billingBox{
   display: flex;
flex-direction: column;
align-items: flex-start;
gap: 1.5rem;
 }
 .billingTypeText{
   color: #454545;
font-family: Inter;
font-size: 1.15rem;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
 }
 .billingOption {
   display: flex;
   align-items: center;
   gap:0.75rem;
 }
 
.save25btn{
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 3.125rem;
background: #D3DEF8;
}
.save25btnText{
  color: #2053C5;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.additionalFeatures{
  color: #212123;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.5rem; /* 133.333% */
margin-bottom:0.75rem;
}
.yoursForeverText{
  color: #212123;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 700;
line-height: 1.5rem; /* 150% */
}
.allBasicProText{
color: #212123;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.5rem; /* 150% */
}
.proImage{
margin-right:0.5rem;
 margin-left:0.5rem;
  height:1.25rem;
   width:1.25rem;
}
@media only screen and (max-width: 767px) {
  .mostPopularText{
    color: #2053C5;
    font-family: Inter;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase; 
  }
  .box2{
    padding:0.75rem;
    width:84vw;
  }
  .buttonsText{
    font-size: 0.5rem;
  }
  .inBoxHeading{
    font-size: 1.125rem;
  }
  .pro{
    font-size: 0.75rem;
  }
  .proImage{
    height:0.625rem;
    width:0.625rem;
    margin-left:1.5rem;
    margin-right: 0.25rem;
  }
  .inBoxSummary{
    font-size:0.625rem;
  }
  .arrayData{
    font-size:0.625rem;
    font-weight: 400;
    line-height: 0rem;
    margin-bottom: 0.25rem;
  }
  .arrayHeadings{
    font-size:0.75rem;
  }
  .amount{
    font-size: 26px;
  }
  .afterAmount{
    font-size: 0.75rem;
  }
  .blueButtonText{
    font-size:0.875rem;
  }
  .blueBorderButtonText{
    font-size:0.875rem;
  }
  .blueButton{
    padding:0.75rem 0px;
    /* width: 8.625rem; */
height: 2rem;
  }
  .checkCircle{
    width: 1rem;
    height: 1rem;
      }
      .blueBorderButton{
        padding:0.75rem 0px;
        /* width: 8.625rem; */
  height: 2rem;
      }
      .blueButtonContainer{
        margin-right:0rem;
        width:100%;
      }
    .showFeaturesBtn{
      font-size: 0.625rem;
    }  
    .additionalFeatures{
      font-size:0.75rem;
      margin-bottom: 0rem;
    }
    .yoursForeverText{
      font-size:0.625rem;
    }
}

@media only screen and (min-width:1023px) and (max-width: 1439px){
  .box2{
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
  .Mainbox_div {
    gap:2rem;
    padding-left: 40px;
    padding-right: 45px;
  }
  .contentBox1{
gap: 1rem;
margin-left: 1rem;
  }
  .innerHeadingBox1{
font-size: 1.39rem;
  }
  .billingTypeText{
font-size: 1.09rem;
  }
  .billingBox{
    gap:1rem;
  }
}
@media only screen and (min-width:767px) and (max-width: 1023px){
  .Mainbox_div {
    gap:2rem;
    padding-right: 1rem;
    padding-left:1rem;
  }
  .contentBox1{
    gap: 1rem;
    margin-left: 1rem;
  }
  .innerHeadingBox1{
    font-size: 1.45rem;
  }
  .billingBox{
    gap:1rem;
  }
  .buttonsText{
    font-size: 0.5rem;
  }
  .box2{
    width: 16rem;
    padding: 1rem 0rem 1rem 1rem;
  }
  .afterAmount{
    font-size: 1rem;
  }
  .amount{
    font-size: 1.75rem;
  }
  .blueBorderButton{
    padding: 0.75rem 2rem;
  }
  .blueButton{
    padding: 0.75rem 1rem;
    gap:0rem;
  }
}


/* new media querry enabled by me  */

@media (max-width:400px)  {
  .box1{
    width: 85%;
  }

  .contentBox1{
    margin-left: 0px;
  }
  
}