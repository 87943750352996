.container {
    /* border: 2px solid red; */
    padding-top: 3rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 1rem;
  }
  .img {
    width: 100%;
   
  }
  
  .para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #000000;
    margin-top: 1.5rem;
    /* border: 2px solid red; */
  }
  