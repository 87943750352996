.saas-signup-header {
  font-size: 24px;
  color: #212121;
  margin-bottom: 0.5rem;
  line-height: 33px;
  font-weight: 500;
}

.saas-signup-span {
  font-weight: 400;
  font-size: 14px;
  color: #575757;
  width: 100%;
}

.main-saas {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  height: 90vh;
  width: 100%;
}

.saas-form {
  border: 1px solid #dfdfdf;
  /* border: 1px solid #3B5998; */
  border-radius: 4px;
  height: 36px;
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 1rem;
  font-size: 14px;
}

.eye {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0.6rem;
  margin-left: auto;
  right: 1rem;
  cursor: pointer;
}

.create222 {
  height: 40px;
  width: 100%;
  background: #3b5998;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
}

.empty-line {
  border-top: 1px solid #a7a7a7;
  height: 2px;
  width: 50%;
}

.saas-or {
  font-weight: 400;
  font-size: 16px;
  color: #a7a7a7;
  padding: 0.5rem;
}

.create2-main {
  height: 40px;
  width: 100%;
  background: #fafafa;
  border: 1px solid #dfdfdf !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #2e2e2e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.saas-by {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #888888;
}

.saas-resend {
  font-weight: 500;
  font-size: 14px;
  color: #3b5998;
  cursor: pointer;
}

.a1 {
  width: 30%;
  height: 574.4px;
  background: #ffffff;
  box-shadow: -2px 2px 12px rgb(0 0 0 / 5%);
  border-radius: 12px;
  padding-left: 2.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2.5rem;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 1;
}

/* .a2 {
      width: 30%;
      height: 574.4px;
      background: #FFFFFF;
      box-shadow: -2px 2px 12px rgb(0 0 0 / 5%);
      border-radius: 12px;
      padding-left: 2.5rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-self: center;
  } */

.googgle {
  position: relative;
  right: 6.5vw;
  height: 20px;
  width: 20px;
}

/* .a2 {
      width: 27%;
      height: fit-content;
  } */

.or-start {
  display: flex;
  align-items: center;
  width: 100%;
  /* margin-top: 0.5rem;
      margin-bottom: 0.5rem; */
}

.new-heading-des {
  font-size: 28px;
  color: #121212;
  line-height: 33px;
  font-weight: 500;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.new-heading-des22 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 80px;
  /* identical to box height, or 167% */

  color: #1d3557;
}

.tabActiveName {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #3b5998;
}

.tabInActiveName {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #888888;
}

.des-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  margin-top: 5rem;
}

.tick {
  height: 13px;
  width: 13px;
  margin-right: 0.5rem;
}

.des-main2 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 78vh;
  /* margin-top: 4rem; */
}

.des-img {
  height: 10rem;
  width: 17rem;
  object-fit: cover;
}

.new-span-des {
  font-weight: 400;
  font-size: 16px;
  color: #575757;
  margin-bottom: 1.5rem;
  text-align: center;
  width: 85%;
  line-height: 25px;
}

.tabActive-des {
  background: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 48px;
  cursor: pointer;
  display: flex;
  padding: 0.05rem 1.25rem;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.tabInActive-des {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 48px;
  cursor: pointer;
  display: flex;
  padding: 0.05rem 1rem;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.choose-start {
  display: flex;
  flex-wrap: wrap;
  /* width: 22.5%; */
  margin-top: 2rem;
  width: 90%;
}

.start-des {
  height: 40px;
  width: 8rem;
  background: #3b5998;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  border: none;
  margin-top: 1rem;
}

.new-span-des2 {
  font-weight: 400;
  font-size: 14px;
  color: #888888;
  line-height: 25px;
  /* width: 22.5%; */
  margin-bottom: 0.35rem;
}

.start-des22 {
  height: 40px;
  width: 7rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #888888;
  border: none;
  margin-top: 1rem;
  background: #f0f0f0;
  margin-right: 1rem;
}

/* contact */

.des-contact {
  display: flex;
  background: #ffffff;
  /* width: 22.5%; */
  height: 40px;
  align-items: center;
  margin-top: 2rem;
}

.contact-label {
  border: 1px solid #dfdfdf;
  background: #fafafa;
  padding: 0.5rem;
  border-radius: 4px 0 0 4px;
  font-size: 14px;
  height: 100%;
}

.contact-label-contractor {
  border: 1px solid #dfdfdf;
  background: #fafafa;
  padding: 0.5rem;
  border-radius: 4px 0 0 4px;
  font-size: 12px;
  height: 100%;
  width: 31vw;
  font-weight: 500;
  color: black;
}

.contact-form-in {
  border: 1px solid #dfdfdf;
  outline: none;
  padding: 0.25rem;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  width: 60%;
  height: 100%;
}

.contact-form-in-contractor {
  border: 1px solid #dfdfdf;
  outline: none;
  padding: 0.45rem;
  border-radius: 0 4px 4px 0;
  font-size: 12px;
  width: 60%;
  height: 100%;
}

.contact-form-in-err {
  border: 1px solid #f7693d;
  outline: none;
  padding: 0.25rem;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  width: 60%;
  height: 100%;
}

.saas-form2 {
  border: 1px solid #dfdfdf;
  /* border: 1px solid #3B5998; */
  border-radius: 4px;
  height: 36px;
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 1rem;
  font-size: 14px;
}

.saas-form23 {
  border: 1px solid #dfdfdf;
  /* border: 1px solid #3B5998; */
  border-radius: 4px;
  height: 8rem;
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 1rem;
  font-size: 14px;
  resize: none;
  padding-top: 0.5rem;
}

/* .saas-form23::placeholder{
  
  } */
.des-webName {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #888888;
}

/* feesCharge */
.des-contact2 {
  display: flex;
  background: #ffffff;
  /* width: 22.5%; */
  height: 40px;
  align-items: center;
  margin-top: 1rem;
}

/* checkoutcart */

.checkouCart-header {
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
  color: #121212;
}

.checkoutspan {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #121212;
}

.checkoutCard {
  background: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 8px;
  padding: 1rem;
  /* width: 42%; */
  height: 8.5rem;
  margin-top: 2.5rem;
  justify-content: space-between;
}

.gst {
  display: flex;
  /* width: 42%; */
  justify-content: space-between;
  /* margin-left: 3rem !important; */
  border: 1px solid #dfdfdf;
  padding: 15px;
  border-radius: 9px;
  margin-top: 1rem;
}

.gstMain {
  display: flex;
  flex-direction: column;
  /* margin-left: 3rem !important; */
  border: 1px solid #dfdfdf;
  padding: 15px;
  border-radius: 9px;
  margin-top: 1rem;
}

.gst_form {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.saas-form2 {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  height: 40px;
  width: 48%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-left: 1rem;
  font-size: 14px;
  /* margin-right: 0.5rem; */
}

.gst2 {
  display: flex;
  /* width: 42%; */
  justify-content: space-between;
}

.gst-span {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #888888;
}

.check_main {
  /* justify-content: space-between; */
  display: flex;
  /* height: 245px; */
  width: 50%;
  margin-left: 5rem;
}

.main_checkOut {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gst_btn {
  border: none;
  background: none;
  color: #3b5998;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.gst_text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}

.pay_now {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 78%;
}

.pay_sub {
  display: flex;
  flex-direction: column;
  /* width: 81%; */
  padding: 1rem;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  height: 8.5rem;
  justify-content: space-between;
}

.pay_text {
  color: #888888;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  width: 89%;
}

.pay_btn {
  padding: 0.5rem;
  width: 90%;
  background: #3b5998;
  border: none;
  color: white;
  border-radius: 8px;
}

.checkoutspan2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #212121;
}

.checkoutspan23 {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #212121;
}

.checkoutspan25 {
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  color: #888888;
}

.billed {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #888888;
}

.summ_header {
  font-size: 18px;
  color: black;
  width: 89%;
}

.summary > div {
  display: flex;
  width: 89%;
  justify-content: space-between;
  align-items: center;
}

.summary > div > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.summary {
  /* width: 81%; */
  border: 1px solid #dfdfdf;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summ_gst {
  border-bottom: 1px solid #dfdfdf;
}

.summ_total {
  padding-top: 22px;
}

/* pricing */

.newpricingmain {
  justify-content: center;
  align-items: center;
}

.newpricingheader22 {
  color: #2053C5;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* width: 14.4375rem; */
}

.newpricingspan {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #444444;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 127.52%;
  /* identical to box height, or 26px */

  text-align: center;

  color: #252525;
}

.new-monthlyUnactive {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
}

.forgot-main {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3b5998;
}

.new-monthlyActive {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3b5998;
}

.new-checkbox {
  background: #ffffff;
  border: 0.726027px solid #444444;
  width: 2.5rem;
  border-radius: 29px;
  height: 1.25rem;
  margin: 0rem 0.75rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.new-checkboxActive {
  height: 1.2rem;
  width: 1rem;
  /* margin: 0.84px; */
  cursor: pointer;
  position: absolute;
  opacity: 0;
}

.new-checkboxActive2 {
  height: 1.2rem;
  width: 1rem;
  /* margin: 0.84px; */
  cursor: pointer;
  position: absolute;
  opacity: 0;
  right: 0.05rem;
}

.new-checkboxActive-circle {
  height: 1rem;
  width: 1rem;
  margin: 0.84px;
  background: #3b5998;
  border-radius: 50%;
  cursor: pointer;
  /* display: none; */
}

.new-checkboxActive-circle2 {
  height: 1rem;
  width: 1rem;
  margin: 0.84px;
  background: #3b5998;
  border-radius: 50%;
  cursor: pointer;
  margin-left: auto;
  /* display: none; */
}

.new-dots {
  position: absolute;
  left: 20.5rem;
  top: 6rem;
  width: 13rem;
  z-index: -1;
}

.new-dots2 {
  position: absolute;
  right: 6rem;
  top: 51rem;
  width: 13rem;
  z-index: -1;
}

.plan-start-new {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  width: 100%;
}

.plan-start2-new {
  background: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 12px;
  width: 100%;
}

.plan-header {
  font-weight: 500;
  font-size: 20px;
  color: #222222;
}

.plan-span {
  font-weight: 400;
  font-size: 12px;
  color: #444444;
}

.new-started {
  background: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #3b5998;
  height: 40px;
  width: 100%;
  margin-top: 1rem;
}

.new-started:hover {
  background: #3b5998;
  color: white;
  border: 1px solid #3b5998;
}

.feature1 {
  background: #f5fcff;
  width: 100%;
  height: 4rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 0.75rem;
}

.img-new {
  /* margin-top: 0.5rem; */
  height: 12px;
  width: 14px;
}

.feature2 {
  background: #f2f4ff;
  width: 100%;
  height: 10rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  padding: 0.75rem;
}

.new-valid {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #3b5998;
}

.new-valid2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #666666;
}

.feature3 {
  background: #f5fcff;
  width: 100%;
  height: 6rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 0.75rem;
}

.feature4 {
  background: #f2f4ff;
  width: 100%;
  height: 6rem;
  /* margin-bottom: 0.75rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 0 0 12px 12px;
}

.recommended {
  background: rgba(59, 89, 152, 0.2);
  border-radius: 0px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #3b5998;
  padding: 0.5rem;
  margin-left: auto;
}

.features-new-head {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
}

.accordion-flush .accordion-item {
  /* border-radius: 72px !important; */
  border-bottom: none !important;
  background: #ebebeb !important;
  border: 1px solid #dfdfdf !important;
}

.accordion-flush .accordion-item .accordion-button {
  /* border-radius: 72px !important; */
  border-bottom: none !important;
  background: #ebebeb !important;
  border: 1px solid #dfdfdf !important;
}

/* .accordion-button:not(.collapsed) {
      border-radius: 72px !important;
  } */

.accordion-body {
  border-top: none !important;
}

.footer-headerWeb {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a142f;
}

.footer-header-web {
  /* font-family: 'Montserrat'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0a142f;
}

.footer-span-web {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #0a142f;
  margin-bottom: 0.5rem;
}

.footer-news {
  background: #0a142f;
  border: 1px solid #0a142f;
  border-radius: 4px;
  height: 40px;
  padding-left: 8px;
  width: 16rem;
}

.footer-button {
  background: #0a142f;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3b5998;
  height: 40px;
  border: none;
  width: 5rem;
}

.footer_bottomWeb {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #0a142f;
}

.foot {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 2rem 1rem 1rem 1rem;
}

.initials-again {
  background: #3b59981a;
  border-radius: 50%;
  height: 2.3rem;
  width: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.tooltip-inner {
  padding: 6px 10px !important;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%) !important;
  background: white !important;
  color: #575757 !important;
  border-radius: 4px 4px 4px 0px !important;
  text-align: left !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 14px !important;
}

.tooltip .tooltip-arrow {
  display: block !important;
  top: 18px !important;
  /* box-shadow: 0px 2px 8px rgb(0 0 0 / 10%) !important; */
  background: white !important;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: white !important;
}

.head-pricing {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-signn {
  font-weight: 400;

  background-color: #ffffff;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  color: #212121;
}

.header-sign2 {
  font-weight: 400;
  background-color: #ffffff;
  /* width: 10rem; */
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  color: #212121;
  /* border: 2px solid red; */
}
.header-sign {
  background: #1d3557;
  border-radius: 10px;
  color: #ffffff;
  /* padding: 0.3rem; */
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/* landing-page */

.new-lan-head {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #333333;
}

.new-lan-span {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #888888;
  width: 86%;
}

.header-sign22222 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: white;
  background: #3b5998;
  border-radius: 4px;
  height: 36px;
  width: 10rem;
  border: none;
  margin-top: 4rem;
}

.designers-no {
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #49b7cf;
}

.designers-no-span {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #666666;
}

.corousel-main-div2 {
  scroll-behavior: smooth;
}

.preview-img {
  height: 69%;
  /* width: 100%; */
  object-fit: cover;
  align-items: center;
  display: flex;
  /* z-index: 1; */
  margin-top: 2rem;
}

.sec2-head {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #626365;
}

.sec2-head-byline {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #888888;
}

.sec2-head2 {
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  width: 67%;
  line-height: 38px;
}

.manage222 {
  width: 80%;
  /* height: 100%; */
  object-fit: cover;
  height: fit-content;
}

.manage-active {
  background: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  box-shadow: 4px 10px 21px rgba(59, 89, 152, 0.25);
  width: 40%;
  margin-right: 2rem;
  margin-bottom: 1rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.manage-inactive {
  background: #f0f0f0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  width: 40%;
  margin-right: 2rem;
  margin-bottom: 1rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.manage-inactive:hover {
  background: #ffffff;
  border: 1px solid #3b5998;
  box-shadow: 4px 10px 21px rgba(59, 89, 152, 0.25);
}

.sec-explore {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #3b5998;
  cursor: pointer;
}

.contactt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #3b5998;
}

.contacttt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 15px;
  color: #000000;
}

.namel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
}

.thanks {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.submission {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #444444;
}

.need {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132.2%;
  color: #000000;
}

.sec-explore-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3b5998;
  background: white;
  border-radius: 4px;
  height: 40px;
  width: 8rem;
  border: 1px solid #3b5998;
  /* margin-right: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-sec3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}

.span-sec3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #888888;
}

.news-line {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #333333;
}

.news-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #888888;
}

.sec7-header {
  font-weight: 600;
  font-size: 26px;
  color: #575757;
}

.sec7-span {
  font-weight: 400;
  font-size: 14px;
  color: #717171;
}

/* product page */

.product-header {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
}

.buss-line {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: white;
}

.buss-byline {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 2.5rem;
  color: #ffffff;
}

.linear-bg {
  background: linear-gradient(
    180deg,
    #d8daff 23.56%,
    rgba(248, 248, 255, 0) 95.03%
  );
}

.pro-num {
  font-weight: 500;
  font-size: 80px;
  line-height: 80px;
  color: white;
  margin-bottom: 1.25rem;
}

.pro-ver-line {
  height: 4rem;
  background: #f9be26;
  width: 1.5px;
  transform: rotate(180deg);
}

.pro-sec2-header {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #000000;
}

.pro-sec2-line {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  width: 69%;
  margin-top: 1rem;
}

.pro-sec2-start {
  background: #3b5998;
  border-radius: 4px;
  height: 40px;
  width: 9rem;
  font-size: 14px;
  border: none;
  color: white;
}

.corousel-main-div {
  display: flex;
  flex-direction: column;
  width: 28rem;
  height: 100%;
}

.corousel-main-div2 {
  display: flex;
  overflow-x: scroll;
  /* width: 100%; */
}

/* contarctor-new-css */

.contractor-info {
  font-weight: 400;
  font-size: 14px;
  color: #888888;
  margin-top: auto;
  cursor: pointer;
}

.by-addpro {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  color: #575757;
  text-align: left !important;
  margin-bottom: 0 !important;
  margin-left: 0.5rem;
}

#new-project-scroll::-webkit-scrollbar {
  display: none;
}

.popover .popover-arrow {
  display: block !important;
  /* top: -3px !important; */
  left: -13px !important;
}

.popover {
  padding: 6px 10px !important;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%) !important;
  background: white !important;
  color: #575757 !important;
  border-radius: 4px 4px 4px 0px !important;
  text-align: left !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /* line-height: 14px !important; */
}

.popover-body {
  padding: 0.15rem !important;
}

.plan-new {
  font-weight: 600;
  font-size: 14px;
  color: #454545;
}

.toggleactive {
  /* background: #1b3f7c; */
  /* border-radius: 50px; */
  /* font-weight: 500; */
  /* font-size: 13px; */
  /* color: #ffffff; */
  padding: 0.22rem 0.65rem;
  cursor: pointer;
  transition: all 300ms;

  color: #454545;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 1.25rem;
background: #F1F9FE;
}

.toggleunactive {
  transition: all 300ms;
  /* background: transparent;
  border-radius: 50px;
  font-weight: 400;
  font-size: 13px;
  color: #1d3557; */
  padding: 0.28rem 0.5rem;
  cursor: pointer;

  color: #FFF;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.Mainbox_div {
  /* border: 2px solid red; */
  /* padding-left: 1rem;
  padding-right: 1rem; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
  padding-top: 2rem;
  /* overflow-x: scroll; */
}
.box1 {
  width: 84vw;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  border-radius: 16px;
  /* border: 2px solid black; */
}
.underBox1 {
  /* border: 2px solid red; */
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  /* width: 100%; */
}
.box2 {
  /* border: 2px solid black; */
  width: 84vw;
  height: 100%;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  border-radius: 16px;
  background-color: #1b3f7c;
  color: #ffffff;
}
.underBox2 {
  /* border: 2px solid red; */
  padding-top: 1rem;
  padding-bottom: 2rem;

  height:92.5rem;
}
.localText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.pro_img {
  width: 20px;
  height: 20px;
}
.pro_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-transform: uppercase;
  border: 1px solid #2053c5;
  color: #2053c5;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  border-radius: 12.874px;
  background-color: #ffffff;
  margin-left: 0.5rem;
}

.unlimited_users {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1rem;
}
.unlimited_userss {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-right: 1rem;
}
.unlimited_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 0.3rem;
  color: #2053c5;
  background: #d8edfd;
  border-radius: 4px;
  border: none;
}
.unlimited_btnn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 0.3rem;
  color: #2053c5;
  background: #ffffff;
  border-radius: 4px;
  border: none;
}

.basic {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #272727;
  /* border: 2px solid red; */
}
.rupees {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
}
.price_btn {
  background: #3e96ed;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #ffffff;
  border: none;
  padding: 0.7rem 2rem 0.6rem 2rem;
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.price_btn1 {
  background: #3e96ed;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #ffffff;
  border: none;
  padding: 0.6rem 2rem 0.6rem 2rem;
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.client_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;

  color: #272727;
  margin-top: 1.5rem;
}
.client_subset {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;

  color: #000000;
}

.nineFeature {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #8ac0f4;
}

.third_img_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  text-align: center;

  color: #ffffff;
  border-top: 1px solid rgba(216, 237, 253, 0.5);
  padding-top: 2rem;
  text-align: center;
  /* border: 2px solid red; */
}

@media screen and (max-width: 1300px) {
  .client_subset {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #000000;
  }
  .nineFeature {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    color: #8ac0f4;
  }
  .client_head {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #272727;
    margin-top: 1.5rem;
  }
}
.btn:hover {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

@media only screen and (max-width: 767px) {
  .save25btn{
  display: flex;
  padding: 0.14419rem 0.28831rem;
  justify-content: center;
  align-items: center;
  gap: 0.36044rem;
  border-radius: 1.80213rem;
background: #D3DEF8;
margin-top:0.27rem;
margin-left:7rem;
}
.save25btnText{
  color: #2053C5;
font-family: Inter;
font-size: 0.4325rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
}
