.container {
  /* border: 2px solid red; */
  /* height: 60vh; */
  margin-bottom: 8rem;
  display: flex;
  justify-content: center;
  /* align-items: center; */

}
.contain {
  height: 80%;
  width: 85%;
  background: linear-gradient(86.11deg, #fffafa -33.53%, #cde2ff 53.63%);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.img_div {
  /* border: 2px solid red; */
  padding-top: 1rem;
  height: 100%;
  /* width: 35%; */
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
}
.mobImg {
  height: 100%;
  width: 16rem;
}
.content_div {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-left: 2rem;
  flex-direction: column;
  /* border: 2px solid red; */
}

.mob_head {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  /* line-height: 44px; */

  color: #132339;
}
.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #01122c;
}
.input_div {
  /* border: 2px solid red; */
  margin-top: 1.3rem;
}
.firstInput {
  width:3rem;
  height: 2.5rem;
  border: none;
  background: #ffffff;
  border-bottom: 2.86486px solid #1d3557;
  border-radius: 6px;
  padding-left: 0.5rem;
}

.secInput{
    margin-left: 1rem;
    width:40%;
    height: 2.5rem;
    border: none;
    background: #FFFFFF;
    border-bottom: 2.86486px solid #1D3557;
    border-radius: 6px;
    padding-left: 0.5rem;
}

.btn{
    height: 2.5rem;
    width: 7rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.appDiv{
    /* border: 2px solid red; */
    margin-top: 2.5rem;

}
.app_head{    
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
color: #212121;
}

.apple{
  margin-top: 0.8rem;
  height: 2.5rem;
  width: 8rem;
  cursor: pointer;
}
