.heading{
    color: #2E548A;
font-family: Inter;
font-size: 2.125rem;
font-style: normal;
font-weight: 600;
line-height: normal;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 3rem;
}
.imagesContainer{
    display: flex;
    gap:1.56rem;
    margin-bottom: 7.4rem;
    justify-content:center;
    align-items:center;
}
.whoWeServeImageContainer{
    width: 14.3125rem;
height: 13.5rem;
border-radius: 0.75rem;
border: 1px solid #E0E0E0;
background: #FFF;
cursor: pointer;
}

.whoWeServeImageContainer:hover{
    border-radius: 0.75rem;
    border: 1px solid #EDEDED;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(129, 130, 133, 0.25);
}
.whoWeServeImages{
    width: 14.22rem;
height: 10.6875rem;
border-radius:0.75rem 0.75rem 0rem 0rem;
/* background: url(<path-to-image>), lightgray -17.451px 0px / 124.494% 100% no-repeat, #D9D9D9; */
box-shadow: 0px 1.005px 2.01px 0px rgba(0, 0, 0, 0.10);
}
.whoWeServeImageText{
    margin-top: 0.87rem;
    margin-bottom: 0.87rem;
    color: #333;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}

.upperHeading{
/* margin-top: 7.69rem; */
color: #2D2D2D;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
}

.mainContainer{
    background: #FFF;
    margin-top: 6rem;
}
.innerFlexContainer{
    display: flex;
    gap:1.56rem;
    justify-content:center;
    align-items:center;
}

.icon {
    padding: 0 2.5rem 1.5rem 0;
    text-align: right;
    cursor: pointer;
    position: relative;
}
  .stickyImage {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    z-index: 999; 
  }
  #fixedButton {
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 1000; /* Adjust as needed */
    display: none; /* Initially hide the button */
}
.topIconImg{
    height:5rem;
  }
@media only screen and (max-width: 767px){
    .upperHeading{
        margin-top: 0rem;
        padding-top: 3rem;
    }
    .mainContainer{
        background: #F5FAFF;
        margin-top: 0px;
        padding-top: 1rem;
    }
.imagesContainer{
flex-direction: column;
margin-left: 1.5rem;
margin-right: 1.5rem;
padding-bottom: 4rem;
margin-bottom: 0rem;
}
.heading{
font-size: 20px;
font-weight: 500;
letter-spacing: 0.025rem;
text-align: center;
margin-top: 0.5rem;
margin-bottom: 1.7rem;
}
.whoWeServeImageContainer{
    /* width: 9.375rem; */
    width:100%;
height:auto;
border-radius: 0.49125rem;
border: 1px solid #E0E0E0;
background: #FFF;
}
.whoWeServeImages{
    width: 100%;
height:auto;
border-radius: 0.20406rem  0.20406rem 0rem 0rem;
box-shadow: 0px 0.658px 1.317px 0px rgba(0, 0, 0, 0.10);
}
.whoWeServeImageText{
    margin-top:0.62rem;
    margin-bottom: 0.88rem;
    font-size: 0.75rem;
    text-transform: capitalize;
}
.whoWeServeImageConFirst{
    width:100%;
    height: auto;
}
.whoWeServeImagesFirst{
    width:100%;
    height:8.75rem;
}
.stickyImage {
    bottom: 70px; 
    right: -20px; 
    z-index: 999; 
  }
  .topIconImg{
    height:4rem;
  }
}