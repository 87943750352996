.container {
  /* border: 2px solid red; */
  padding-bottom: 2rem;
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}

.eachbox {
  background: linear-gradient(180deg, #b8dafc 0%, #8bc5ff 100%);
  box-shadow: 0px 6.83333px 13.6667px rgba(154, 170, 207, 0.2);
  border-radius: 17px;
  /* padding:1.5rem 5rem 1.5rem 5rem; */
  width: 15%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.eachbox2 {
    background: linear-gradient(180deg, #FCBC98 0%, #FF955A 100%);
    box-shadow: 0px 6.83333px 13.6667px rgba(154, 170, 207, 0.2);
    border-radius: 17px;
    /* padding:1.5rem 5rem 1.5rem 5rem; */
    width: 15%;
    padding-top:1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .eachbox3 {
    background: linear-gradient(180deg, #B7FFD8 0%, #79E4AA 100%);
    box-shadow: 0px 6.83333px 13.6667px rgba(154, 170, 207, 0.2);
    border-radius: 17px;
    /* padding:1.5rem 5rem 1.5rem 5rem; */
    width: 15%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  
.task {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    /* line-height: 38px; */
    text-align: center;
    color: #ffffff;
}
.number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
}
