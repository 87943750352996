.container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 3rem;
    margin-right: 3rem;
    gap:2.25rem;
}

.row {
    display: flex;
    gap:2.25rem;
    width: 100%; 
}

.item {
    box-sizing: border-box;
    flex: 0 0 calc(33.33% - 36px); 
    padding: 20px;
    box-sizing: border-box;
    height: 200px; 
    overflow: hidden; 
    position: relative;
border-radius: 0.5rem;
border: 1px solid #C7C7C7;
background: #FFF;
}
.item:hover{
    box-shadow: 0px 4px 12px 0px rgba(149, 153, 226, 0.20);
}
.row .item:last-child {
    margin-right: 0;
}

.heading{
    color: #333;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 0.75rem;
}
.description{
    color: #4D4D4D;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.25rem; /* 125% */
}
.readMoreBtn{
color: #299FFF;
font-family: Inter;
font-size: 1rem;
font-weight: 400;
line-height: normal;
cursor: pointer;
position: absolute;
bottom: 1.75rem;
}

.readMoreBtn:hover{
   color:#0084EF;
   text-decoration: underline;
    text-underline-offset: 0.2rem;
}

.headingTxtMainHeading{
    /* color: #FFF; */
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 0.25rem;
    margin-bottom: 1.25rem;
    /* display: flex; */
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 0.625rem;
}

.headingTxtSubHeading{
    color: #333;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 1.25rem;
margin-bottom: 1.25rem;
}

.headingTxtPara{
    color: #4D4D4D;
font-family: Inter;
font-size: 0.9375rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
margin-bottom: 1.75rem;
}

.btnContainer{
    border-radius: 0.25rem;
}

.topIconImageContainer{
    padding: 0 2.5rem 1.5rem 0;
    text-align: right;
    cursor: pointer;
    position: relative;
}
.topImageIconConImage{
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    z-index: 999; 
}
.icon {
    padding: 0 2.5rem 1.5rem 0;
    text-align: right;
    cursor: pointer;
    position: relative;
}
  .stickyImage {
    position: fixed;
    bottom: 20px; 
    right: 20px; 
    z-index: 999; 
  }
  .topIconImg{
    height:5rem;
  }
@media only screen and (max-width: 767px){
    .row{
        flex-direction: column;
        gap:1.5rem;
    }
    .item{
        flex-basis: unset;
        height:unset;
    }
    .readMoreBtn{
        bottom:1rem;
        font-size: 0.78125rem;
    }
    .description{
        margin-bottom: 1.2rem;
        font-size: 0.8125rem;
    }
    .container{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .topIconImg{
        height:4rem;
      }
      .stickyImage {
        bottom: 70px; 
        right: -20px; 
        z-index: 999; 
      }
      .heading{
        font-size: 0.9375rem;
        margin-bottom: 0.62rem;
      }
      .headingTxtMainHeading{
        font-size: 0.6875rem;
      }
      .headingTxtSubHeading{
        font-size: 1.25rem;
      }
      .headingTxtPara{
        font-weight: 300;
      }
}
