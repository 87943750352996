

.fp-loading-bar {
  display: flex;
  height: 10px;
  width: 100%;
  max-width: 320px;
  box-shadow: inset 0px 0px 0px 1px #c8c8cd;
  border-radius: 50px;
  /* overflow: hidden; */
  background : #F0F4F4;
  border: none;
  z-index: 9999999999;
  position: relative;
  animation : hide 10s forwards;

}

.btn{
  color: rgb(142, 142, 142) !important; 
  padding-bottom: 2rem;
  padding: 0;
}

@keyframes hide {
  0%{
    opacity: 1;
  }
  99%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
 

@keyframes progress-animation {
  0% {
    width: 0%;
  }
  10% {
    width: 10%;
  }
  20% {
    width: 30%;
  }
  30% {
    width: 60%;
  }

  50% {
    width: 90%;
  }

  99%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.fp-progress-bar {
  display: flex;
  height: 100%;
  width: 90%;
  background: linear-gradient(90deg, #49B7CF 0%, #D9EFF4 0%, #49B7CF 100%);
  border-radius: 25px;
  animation: progress-animation 10s forwards;
}

.progress_bar_1{
  height: 0.5rem;
  width: 85%;
}
.progress_bar_2{
  height: 0.5rem;
  width: 85%;
}
.progress_bar_3{
  height: 0.5rem;
  width: 85%;
}
.progress_bar_4{
  height: 0.5rem;
  width: 85%;
}

.progress_bar_1 .progress-bar{
  background-color: #FF9D00 !important;
}
.progress_bar_2 .progress-bar{
  background-color: #BA181B !important;
}
.progress_bar_3 .progress-bar{
  background-color: #ACACAC !important;
}
.progress_bar_4 .progress-bar{
  background-color: #40916C !important;
}



/* leads */
.progress_bar_5{
  height: 0.5rem;
  width: 85%;
}
.progress_bar_6{
  height: 0.5rem;
  width: 85%;
}
.progress_bar_7{
  height: 0.5rem;
  width: 85%;
}
.progress_bar_8{
  height: 0.5rem;
  width: 85%;
}

.progress_bar_5 .progress-bar{
  background-color: #40916C !important;
}
.progress_bar_6 .progress-bar{
  background-color: #FF9D00 !important;
}
.progress_bar_7 .progress-bar{
  background-color: #BA181B !important;
}
.progress_bar_8 .progress-bar{
  background-color: #ACACAC !important;
}