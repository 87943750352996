.container {
    margin-bottom: 8rem;
    display: flex;
    justify-content: center;
    margin-top:6.25rem;
  }
  .contain {
    height: 26rem;
    width: 85%;
    background: linear-gradient(86.11deg, #fffafa -33.53%, #cde2ff 53.63%);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:6rem;
  }
  
  .img_div {
    /* padding-top: 1rem; */
    height: 100%;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-right: 2rem; */
    margin-bottom: -2.9rem;
  }
  .mobImg {
    height: 100%;
    width: 16rem;
  }
  .content_div {
    display: flex;
    justify-content: center;
    /* padding-top: 2rem;
    padding-left: 2rem; */
    flex-direction: column;
  }
  
  .mob_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #132339;
  }
  .para {
    color: #464646;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 2rem;
letter-spacing: 0.0125rem;
width: 26.875rem;
  }
  .input_div {
    margin-top: 1.3rem;
  }
  .firstInput {
    width:3rem;
    height: 2.5rem;
    border: none;
    background: #ffffff;
    border-bottom: 2.86486px solid #1d3557;
    border-radius: 6px;
    padding-left: 0.5rem;
  }
  
  .secInput{
      margin-left: 1rem;
      width:40%;
      height: 2.5rem;
      border: none;
      background: #FFFFFF;
      border-bottom: 2.86486px solid #1D3557;
      border-radius: 6px;
      padding-left: 0.5rem;
  }
  
  .btn{
      height: 2.5rem;
      width: 7rem;
      margin-left: 1.5rem;
      cursor: pointer;
  }
  
  .appDiv{
      margin-top: 3.5rem;
  
  }
  .app_head{    
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  }
  
  .apple{
    margin-top: 0.8rem;
    /* width: 10.4rem;
    height: 3.00444rem; */
    width:12.98075rem;
  height:3.75rem;
    cursor: pointer;
  }
 .appleLogo{
  margin-left: 1rem;
  width:12.98075rem;
  height:3.75rem;
 }
 .apple:hover{
box-shadow: 0px 6px 16px 0px rgba(91, 91, 91, 0.18);
 }

  @media only screen and (max-width: 767px){
    .contain{
      gap:1.04rem;
        height: 200px;
        width: 92%;
        border-radius: 1.11156rem ;
        overflow-y: hidden;
    }
    .appleLogo{
      margin-left: 0rem;
    }
    .buttonContainer{
      display:flex;
      flex-direction: column;
    }
    .container {
      margin-bottom: 3.75rem;
    padding-top: 3.25rem;
    margin-top: 0rem;
    }
    .appDiv{
      margin-top: 1.38rem;
    }
    .content_div{
      margin-right: 1.38rem;
      padding-top: 0rem;
      padding-left: 0rem;
    }
    .img_div {
      height: 100%;
      margin-bottom: -1.5rem;
    }
    .apple{
      width:5.05rem;
      height: 1.45888rem;
      cursor: pointer;
      margin-top: 0rem;
    }
    .mob_head {
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 0.56rem;
    }
    .para {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 0.875rem; 
  letter-spacing: 0.00625rem;
  width: 8.5rem;
    }
    .app_head{    
      font-weight: 600;
      font-size: 0.62525rem;
      line-height: normal;
      color: #212121;
      }
      .mobImg {
        height: 100%;
        width: 8rem;
        margin-left: 1.55rem;
      }
     
  }