.inputs {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 0.8rem 2rem 0.8rem 1rem;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
  .inputs3 {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 0.8rem 2rem 0.8rem 1rem;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .inputs ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  
    color: rgba(37, 37, 37, 0.8);
  }
  .inputs3 ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  
    color: rgba(37, 37, 37, 0.8);
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  
    color: #252525;
  }
  
  .bottomText {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
  
    color: #333333;
  }
  
  .addBtn {
    background: #1d3557;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  
    color: #ffffff;
    border: none;
    padding: 0.7rem 1rem 0.7rem 1rem;
  }
  
  .cancelBtn {
    background: #f5f5f5;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border: none;
    padding: 0.7rem 1rem 0.7rem 1rem;
  
    color: #4d4d4d;
  }
  