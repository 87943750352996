.container {
    /* border: 2px solid red; */
    /* height: 90vh; */
    background-image: url("../../imagesMobImg/section1Mob/backImg1.png");
    background-size: cover;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 10rem;
    /* border: 2px solid red; */
  }
  .content {
    padding-top: 6rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border: 2px solid black; */
  }
  .topHeading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    word-spacing: 0.2rem;
    color: #1d3557;
  }
  
  .para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 127.52%;
    margin-top: 1.2rem;
    text-align: center;
    color: #212121;

  }
  
  .btn1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 127.52%;
    background-color: #ffffff;
    text-align: center;
    border-radius: 10px;
    color: #1d3557;
    padding: 0.8rem 1.2rem 0.8rem 1.2rem;
  
  }
  
  .btn2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 127.52%; 
    text-align: center;
    color: #ffffff;
    background-color:#1d3557;
    padding: 0.9rem 1.4rem 0.9rem 1.4rem;
    border-radius: 10px;
    margin-left: 1rem;
    border: none;
 
  }

  
  .img1{
      /* border: 2px solid red; */
      margin-top: 2rem;
      width: 100%;
      /* height: 100%; */
      
  }
  