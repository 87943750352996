.email_main {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
}

.email_main2 {
    display: flex;
    flex-direction: row;
    margin-top: 8vw;
    margin-left: 6vw;

}

.email_data {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.email_data_header {
    color: #174E86;
    font-size: 40px;
    font-weight: 600;
}

.email_data_byline {
    font-size: 20px;
    margin-top: 1vw;
    width: 63%;
    font-weight: 400;
}

.otp_input {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    width: 48%;
}

.email_input {
    letter-spacing: 9px;
    text-align: center;
    font-size: 22px;
    width: 100%;
    /* height: -webkit-fill-available !important; */
    outline: none;
    border: none;
    background: #dcdcdcd6;
    border-radius: 4px;
    padding: 1vh;
    height: 64px;
}

.email_verify {
    /* width: 48%; */
    padding: 1vh;
    border: none;
    background: #174E86;
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-top: 56px;
    width: 100%;
    height: 51px;
    border-radius: 4px;
}

.email_span {
    display: flex;
    flex-direction: column;
    margin-top: 2vw !important;
}

.email_img {
    margin-left: -13vw;
}

.span1 {
    color: #174E86;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px;
}

.span1:hover {
    color: #174E86;
}

@media screen and (max-width: 1090px) {
    .email_data_header {
        font-size: 40px;
    }

    .email_data_byline {
        font-size: 18px;
        width: 70%;
    }
}

@media screen and (max-width: 978px) {
    .email_main2 {
        margin-top: 11vw;
    }

    .email_data_header {
        font-size: 35px;
    }

    .email_data_byline {
        /* font-size: 16px; */
        width: 85%;
    }

    .otp_input {
        width: 65%;
    }

    .email_verify {
        /* width: 65%; */
        width: 100%;
        margin-top: 6vw;
    }

    .span1 {
        font-size: 15px;
    }

    .email_span {
        margin-top: 3vw;
    }

    .email_img {
        width: 56%;
        height: 85%;
    }

}

@media screen and (max-width: 770px) {
    .email_main2 {
        margin-top: 13vw;
    }

    .email_data_header {
        font-size: 30px;
    }

    .email_data_byline {
        font-size: 16px;
        width: 85%;
    }

    .otp_input {
        width: 75%;
    }

    .email_input {
        width: 100%;
        height: 6vh;
    }

    .email_verify {
        /* width: 75%; */
        width: 100%;
        margin-top: 8vw;
    }

    .span1 {
        font-size: 14px;
    }

    .email_span {
        margin-top: 4vw;
    }

    .email_img {
        width: 56%;
        height: 85%;

    }
}

@media screen and (max-width: 570px) {
    .email_main2 {
        display: flex;
        flex-direction: column-reverse;
        align-self: center;
        text-align: center;
    }

    .email_data_byline {
        align-self: center;
        font-size: 14px !important;
        width: 100% !important;
        margin-top: 12px;
    }

    .email_data_header {
        margin-top: 24px;
        font-size: 24px !important;
    }

    .otp_input {
        align-self: center;
        margin-top: 56px !important;
        margin-left: 10vw;
    }

    .email_verify {
        margin-top: 104px !important;
        height: 43px !important;
        font-weight: 400;
        font-size: 14px;
    }

    .email_input {
        height: 44px !important;
    }

    .email_img {
        width: 46%;
        margin-left: -7vw !important;
        align-self: center;
    }

    .email_span {
        margin-top: 32px !important;
    }
}

@media screen and (max-width: 415px) {
    .email_data_header {
        font-size: 22px;
    }

    .email_main2 {
        padding: 24px 6px;
    }

    .email_data_byline {
        font-size: 13px;
    }

    .otp_input {
        margin-top: 8vw;
    }

    .email_verify {
        margin-top: 27vw;
        width: 100%;
        margin-left: 1vw;
        border-radius: 4px
    }

    .span1 {
        font-size: 12px;
    }

    .email_img {
        width: 50%;
        align-self: center;
        margin-left: -7vw !important;
    }
}