.onboard {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
    padding-top: 2rem;
    padding-left: 1rem;
   padding-right: 1rem;
}

.subhead {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    margin-top: 0.7rem;
    padding-left: 1rem;
    color: #575757
}

.input_div {
    padding-left: 1rem;
    padding-right: 1rem;
}

.input_tag {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 99%;
    height: 2.5rem;
    font-size: 12px;
    padding-left: 10px;
}

.input ::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #888888
}

#email {
    color: '#888888'
}

.eye img {
    position: relative;
    top: 4px;
    right: 27px;
}

#password {
    color: #888888
}

.btn-login {
    border: none;
    color: #ffffff;
    margin: 0.7rem;
    width: 91%;
    background: #3B5998;
}

.empty_div {
    width: 10rem;
    height: 0.1rem;
    background: #DFDFDF;
    margin-left: 10xp;
}

.orr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #888888;
    line-height: 2px;
}

.continue {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-left: 1rem;
    color: #888888
}

.terms {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    color: #888888
}

.account {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #888888;
    padding-left: 1rem;
}

.signin {
    color: #3B5998;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
}

.mainn_header {
    background-color: #ffffff;
    /* padding: 15px; */
    height: 57px;


}

.header-onboard {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #DFDFDF;
}

.header-menu {
    display: flex;
}

.welcome {
    padding-left: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding-top: 2.5rem;
    color: #212121
}

.forgot {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3B5998
}

.signup {
    color: #3B5998;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
}

.otp {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    padding-left: 1rem;
    color: #575757;
}

.resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3B5998;
    padding-left: 1rem;
    padding-top: 2.5rem;
}

.change {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3B5998;
    padding-left: 1rem;
    padding-top: 1rem;
}

.onboardd {
    display: flex;
    justify-content: center;
    margin-top: 8rem;
}

.onboardd img {
    width: 10rem;
    height: 6rem;
    object-fit: contain;
}

.name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #121212;
}

.complete {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #575757;
}

.started {
    border: '1px solid #fffff!importnat';
    background: #3B5998;
    border-radius: 4px;
    padding: 8px;
    color: '#ffffff';
    font-size: 13px;
}

.steps {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #888888;
    padding-left: 1rem;
    padding-top: 4.5rem;
}

.choose {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #121212;
    padding-left: 1rem;
}

.contact {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 29px;
    color: #121212;
    padding-left: 1rem;
}

.set {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #121212;
    padding-left: 1rem;
}

.url {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #888888;
    padding-left: 1rem;
}

.checkk {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    padding-left: 1rem;
}

.cancel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #121212;
    padding-left: 1rem;
}

.summary11 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding-left: 1rem;
}

.boxxx {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    width: 21.3rem;
    margin-left: 1rem;
    height: 8.3rem;
}

.essential {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #575757;
}

.paisa {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #575757;
}

.essentialbox {
    background: #FFFFFF;
    border: 1px solid #3B5998;
    border-radius: 8px;
    width: 21.3rem;
    margin-left: 1rem;
    height: 4rem;
}

.essentialplan {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
}

.permonth {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #212121;
    padding-left: 1rem;
}

.GSTbox {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    width: 21.3rem;
    margin-left: 1rem;
    height: 2.8rem;
}

.gst11 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #212121;
}

.yes {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #3B5998;
}

.provide {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    margin-top: 1rem;
    margin-left: 1rem;
    color: #888888;
}

.paynoww {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #888888;
}

.get {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3B5998;
}

.monthly {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #888888;
}

.new-monthlyActive {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3B5998;
}

.new-monthlyUnactive {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #888888;
}

.new-checkbox {
    background: #FFFFFF;
    border: 0.410959px solid #444444;
    width: 2.5rem;
    border-radius: 29px;
    height: 1.25rem;
    margin: 0rem 0.75rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.new-checkboxActive {
    height: 1.2rem;
    width: 1rem;
    /* margin: 0.84px; */
    cursor: pointer;
    position: absolute;
    opacity: 0;
}

.new-checkboxActive2 {
    height: 1.2rem;
    width: 1rem;
    /* margin: 0.84px; */
    cursor: pointer;
    position: absolute;
    opacity: 0;
    right: 0.05rem;
}

.new-checkboxActive-circle {
    height: 1rem;
    width: 1rem;
    margin: 0.84px;
    background: #3B5998;
    border-radius: 50%;
    cursor: pointer;
    /* display: none; */
}

.new-checkboxActive-circle2 {
    height: 1rem;
    width: 1rem;
    margin: 0.84px;
    background: #3B5998;
    border-radius: 50%;
    cursor: pointer;
    margin-left: auto;
    /* display: none; */
}

.feature1 {
    background: #F5FCFF;
    width: 100%;
    height: 4rem;
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    padding: 0.75rem;
}

.img-new {
    /* margin-top: 0.5rem; */
    height: 12px;
    width: 14px;
}

.feature2 {
    background: #F2F4FF;
    width: 100%;
    height: 10rem;
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 0.75rem;

}

.new-valid {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #3B5998;
}

.new-valid2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #666666;
}

.feature3mob {
    background: #F5FCFF;
    width: 100%;
    height: 6rem;
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75rem;
}

.feature4 {
    background: #F2F4FF;
    width: 100%;
    height: 6rem;
    /* margin-bottom: 0.75rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    padding: 0.75rem;
    border-radius: 0 0 12px 12px;
}

.recommended {
    background: rgba(59, 89, 152, 0.2);
    border-radius: 0px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #3B5998;
    padding: 0.5rem;
    margin-left: auto;
}

.features-new-head {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
}

.plan-start {
    background: #FFFFFF;
    border: 1px solid #3B5998;
    box-shadow: 0px 4px 12px 4px rgba(59, 89, 152, 0.2);
    border-radius: 12px;
    width: 18rem;
}

.plan-header {
    font-weight: 500;
    font-size: 20px;
    color: #222222;
}

.plan-span {
    font-weight: 400;
    font-size: 12px;
    color: #444444;
}

.new-started {
    background: #FFFFFF;
    border: 1px solid #3B5998;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: #3B5998;
    height: 40px;
    width: 100%;
    margin-top: 1rem;
}

.new-started:hover {
    background: #3B5998;
    color: white;
    border: 1px solid #3B5998;
}

.featuress {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #444444;
}

.qualified {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #666666;
}

.feature2mob {
    background: #F2F4FF;
    width: 100%;
    height: 10rem;
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
}

.new-pricing-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 80px;
    color: #3B5998;
    line-height: 3.5rem;
}



/* .accordion-flush .accordion-item .accordion-button {
    border-radius: 72px !important;
    border-bottom: none !important;
    color:#000000;
    
} */

/* .accordion-button:not(.collapsed) {
    border-radius: 72px !important;
} */

.accordion-body {
    border-top: none !important;
    border-radius: none !important;
}

.footer-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #BCBCBC;
}

.sign {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;
}

.ssubmit {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3B5998;
    background: #FFFFFF;
    border-radius: 0px 6px 6px 0px;
    width: 25%;
}

.company {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;
}

.abt {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #DFDFDF;
}

.footer_bottom {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #F5F5F5;
}

.top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid #DFDFDF;
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    height: 50px;
    padding: 1em;
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
}

.menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu>li {
    margin: 0 1rem;
    overflow: hidden;
}

.menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#menu-toggle {
    display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #000000;
    position: absolute;
    height: 2px;
    width: 18px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu-button::before {
    content: '';
    margin-top: -8px;
}

.menu-button::after {
    content: '';
    margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}

@media (max-width: 700px) {
    .menu-button-container {
        display: flex;
    }

    .menu {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        z-index: 1;
        /* padding-bottom: 1rem; */
    }

    #menu-toggle~.menu li {
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    #menu-toggle:checked~.menu li {

        height: 3rem;
        padding: 10px;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    .menu>li {

        margin: 0;
        padding: 0.5em 0;
        width: 100%;
        color: #222;
        background-color: white;
        font-size: 18px;
        font-weight: 600px;
    }

    .menu>li:not(:last-child) {
        border-bottom: 1px solid #444;
    }
}

.tabInActive-des {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 48px;
    cursor: pointer;
    display: flex;
    padding: 0.05rem 1rem;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.tabInActiveName {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #888888;
}

.tabActive-des {
    background: #FFFFFF;
    border: 1px solid #3B5998;
    border-radius: 48px;
    cursor: pointer;
    display: flex;
    padding: 0.05rem 1.25rem;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.tabActiveName {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #3B5998;
}

.contact-form-in {
    border: 1px solid #DFDFDF;
    outline: none;
    padding: 0.25rem;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    width: 60%;
    height: 100%;
}

.contact-form-in-err {
    border: 1px solid #F7693D;
    outline: none;
    padding: 0.25rem;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    width: 60%;
    height: 100%;
}

.des-webName {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #888888;
}

.textarea {
    border: 1px solid #DFDFDF;
    /* border: 1px solid #3B5998; */
    border-radius: 4px;
    height: 8rem;
    width: 95%;
    margin-bottom: 1rem;
    padding-left: 1rem;
    font-size: 14px;
    resize: none;
    padding-top: 0.5rem;
}

.des-contact {
    display: flex;
    background: #FFFFFF;
    /* width: 22.5%; */
    height: 40px;
    align-items: center;
    margin-top: 2rem;
}

.contact-label {
    border: 1px solid #DFDFDF;
    background: #FAFAFA;
    padding: 0.5rem;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    height: 100%;
}

.contact-form-inn {
    border: 1px solid #DFDFDF;
    outline: none;
    padding: 0.25rem;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    width: 80%;
    height: 100%;
}

.initials-again2 {
    background: #3b59981a;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.backkk {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 132.2%;
    color: #3B5998;
}

.contactt {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #3B5998;

}

.contacttt {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 15px;
    color: #000000;
}

.namel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #666666;
}

.thanks {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #000000;
}

.submission {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #444444;
}

.need {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 132.2%;
    color: #000000;
}

.threex {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #745BC6;
}

.faster {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #888888;
    padding-top: 2px;
}

.delight {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #745BC6;
    padding-top: 2px;
}

.subhe {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #333333;
}

.managee {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    color: #444444;
}

.numb {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #FFFFFF;
}