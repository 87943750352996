.fullContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 82vw;
}
.rightPanelTop {
  border-bottom: 1px solid #dfdfdf;
  padding: 1rem 1rem 0 1rem;
}
.topHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topHeadingText {
  font-size: 20px;
  font-weight: 500;
}
.topButtons {
  display: flex;
  align-items: center;
}
.topButtons div {
  margin-right: 1rem;
  color: #3b5998;
  cursor: pointer;
}
.topButtons button {
  border-radius: 4px;
  border: none;
  background-color: #3b5998;
  color: #ffffff;
  padding: 0.5rem 1rem;
}

.tabsAndSort {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
}
.tabsContainer {
  width: 60%;
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
}
.twoTabs {
  width: 20%;
  padding-left: 0.5rem;
  border-left: 1px solid #dfdfdf;
  margin-left: 0.5rem;
  display: flex;
  white-space: nowrap;
}
.sortByDropdown {
  border: none;
  background-color: #ffffff;
  padding-bottom: 8px;
}
.sortBy {
  width: 20%;
  padding: 0 0 0 0.5rem;
  font-size: 14px;
  color: #888888;
}
.sortBy span {
  font-weight: 500;
  color: #000000;
}
.eachTab {
  margin-right: 1rem;
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: #888888;
  cursor: pointer;
}
.eachTabActive {
  margin-right: 1rem;
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: #3b5998;
  border-bottom: 2px solid #3b5998;
  cursor: pointer;
}

.rightPanelMid {
  padding: 1rem 1rem 0 1rem;
  height: 100%;
}
.rightPanelNoLeads {
  padding: 1rem 1rem 0 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tableHeadings {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 0.5rem;
  border-radius: 4px 4px 0 0;
  font-size: 12px;
}
.checkboxSection {
  width: 2%;
  display: flex;
  align-items: center;
}
.customCheckbox {
  border: 1px solid #888888;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 2px;
}

.clientNameSection {
  width: 13%;
}
.citySection {
  width: 13%;
}
.dateSection {
  width: 10%;
}
.budgetSection {
  width: 10%;
}
.workTypeSection {
  width: 10%;
}
.sourceSection {
  width: 7%;
}
.phNumSection {
  width: 10%;
}
.statusSection {
  width: 15%;
}
.actionSection {
  width: 10%;
}

.leadCardsContainer {
  height: 56vh;
  overflow-y: scroll;
}
.leadCardsContainer::-webkit-scrollbar {
  display: none;
}
.leadCardsContainerWithoutAd {
  height: 63vh;
  overflow-y: scroll;
}
.leadCardsContainerWithoutAd::-webkit-scrollbar {
  display: none;
}

.addClientInput {
  width: 100%;
  padding: 0.5rem 0;
}
.addClientInput input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
}

.addClientInputSelect {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  color: #888888;
  padding: 0.5rem;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.addClientButtons {
  display: flex;
  padding: 1rem 0 0 0;
}
.addClient {
  padding-right: 0.25rem;
}
.addClient button {
  width: 100%;
  border: none;
  background-color: #3b5998;
  border-radius: 4px;
  color: #ffffff;
  padding: 0.5rem 1rem;
}
.addClientInactive {
  padding-right: 0.25rem;
}
.addClientInactive button {
  width: 100%;
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #888888;
  padding: 0.5rem 1rem;
}
.cancel {
  padding-left: 0.25rem;
}
.cancel button {
  width: 100%;
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #888888;
  padding: 0.5rem 1rem;
}

.addNewClientModal {
  padding: 0 1.5rem 1.5rem 1.5rem !important;
}

.rightPanelBot {
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  display: flex;
  justify-content: space-between;
}
.botMid {
  display: flex;
}
.botMid button {
  border-radius: 4px;
  color: #ffffff;
  background-color: #00000000;
  border: 1px solid #ffffff;
  font-size: 12px;
  padding: 0.25rem 1rem;
}
