.testimonialsHeading{
margin-top:4.69rem;
color: #2E548A;
text-align: center;
font-family: Inter;
font-size: 2.125rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.testimonialsText{
/* width: 64.0625rem; */
display: flex;
justify-content: center;
align-items: center;
color: #252525;
text-align: center;
font-family: Inter;
font-size: 1.375rem;
font-style: normal;
font-weight: 500;
line-height: 127.523%; /* 1.75344rem */
}
.testimonialsTextContainer{
    /* margin-left:24.63rem;
    margin-right:21.87rem; */
    margin-top:1rem;
    display:flex;
    justify-content: center;
    align-items: center;
}
.testimonialPeopleContainer{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 1.75rem;
/* margin-left:8.38rem; */
width:45%;
}
.testimonialFlexContainer{
    display:flex;
    margin-top:5.13rem;
    width:100%;
    padding: 0rem 9.5rem 0rem 9.75rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    align-items: baseline;
}
.testimonialPeople{
    height:7.75rem;
     /* width: 31.4375rem;  */
     width:100%;
    display: flex;
    gap:1.5rem;
    opacity:0.6;
}
 .testimonialPeople.clicked {
    opacity:1;
    border-radius: 1rem;
background: #FFF;
height:7.75rem;
    /* width: 31.4375rem; */
    width:100%;
/* box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02), 0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03), 0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04), 0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04), 0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05), 0px 28px 80px 0px rgba(154, 170, 207, 0.07); */
box-shadow: 0px 28px 80px 0px rgba(154, 170, 207, 0.07), 0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05), 0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04), 0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04), 0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03), 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02);
}
.testimonialPeople:hover{
    border-radius: 1rem;
background: #FFF;
height:7.75rem;
    /* width: 31.4375rem; */
    width:100%;
/* box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02), 0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03), 0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04), 0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04), 0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05), 0px 28px 80px 0px rgba(154, 170, 207, 0.07); */
border-radius: 1rem;
opacity: 0.9;
box-shadow: 0px 28px 80px 0px rgba(154, 170, 207, 0.07);
}
.testimonialPeopleName{
    color: #212121;
/* text-align: center; */
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.testimonialPeoplesText{
    display: inline-flex;
/* height: 24.1875rem; */
flex-direction: column;
align-items: flex-start;
gap: 1.3125rem;
flex-shrink: 0;
/* width: 39.125rem; */
width:32.125rem;
margin-right:4.56rem;
font-family: Inter;
font-size: 1.125rem;
color: rgba(33, 33, 33, 0.75);
font-weight: 500;
}

.testimonialPeopleRole{
   color: rgba(64, 64, 64, 0.75);
text-align: left;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.testimonialPeopleImage{
    align-self:center;
    width: 5.5625rem;
height: 5.5625rem;
flex-shrink: 0;
border-radius: 5.5625rem;
margin-left: 1.94rem;
}
.testimonialPeopleDetails{
    margin-top: 2.5rem;
    margin-left:2rem;
    display:flex;
    flex-direction: column;
}
.testimonialsRightSide{
    /* margin-top: 1.31rem; */
    display: flex;
    flex-direction: column;
    gap:1.31rem;
    width:40%;
    margin-top: -200px;
}
.insideTestRightSide{
    display: flex;
    flex-direction: column;
    gap: 1.31rem;
}
.testimonialsRightSideHeading{
    color: #4D4D4D;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: 1.8125rem; /* 120.833% */
}
.testimonialRightSideHead{
    display: flex;
    flex-direction: column;
    gap:0.94rem;
}
.star{
    margin-right: 0.54rem;
    height:1.53781rem;
    width:1.62831rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
.testimonialFlexContainer{
    gap:2rem;
    padding:1rem 2rem;
    margin-top: 1.5rem;
}
.testimonialPeopleDetails{
    margin-top: 0rem;
    margin-left: 0rem;
    justify-content: center;
}
}
