.container {
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 4rem;

 
  }
  .btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    color: #7B2CBF;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border: none;
    background: #F3EAFA;
    border-radius: 50px;
    width:100%;
    /* margin-left: 3rem */
  }
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #1d3557;
    margin-top: 1rem;
    /* width: 50%; */
    /* border: 2px solid red; */
    /* width: 150%; */
    width: 100%;
  }
  .content_div {
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* width: 60%; */
  }
  .projects_div {
    /* border: 2px solid red; */
    margin-top: 2rem;
   display: flex;
   flex-direction: column;

   
  }
  .file_timeline {
    /* border: 2px solid red; */
  display: flex;
  } 
  .task_daily {
    /* border: 2px solid red; */
    display: flex;
    /* justify-content: space-between; */
    margin-top: 2rem;
  }
  
  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  .icon_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 0.6rem;
    line-height: 22px;
  
    color: #212121;
  }
  .para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #4d4d4d;
    margin-top: 0.2rem;
    width: 100%;
  }
  
.mainImg{
    margin-top: 2rem;
    width:100%;
   
}  