.container {
  /* border: 2px solid red; */
  padding-top: 4rem;

}

.content {
  /* height: 45vh; */
  margin-top: 2rem;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 58px;

  text-align: center;

  color: #1d3557;
  /* padding-top: 3rem; */
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 127.52%;
  text-align: center;
  color: #212121;
  padding-top: 1rem;
}

.btn_div {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
  position: fixed;
  top: 3.5rem;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
  width: 100%;
  /* border-top: 1px solid rgb(180, 174, 174); */
  box-shadow: 0px 4px 16px rgba(154, 170, 207, 0.2);
  transition: all 400ms;
}
.btn_div1 {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.file {
  background: #fef7e6;
  border-radius: 50px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f2af06;
  margin: 0.5rem;
}

.imgs{
 width: 9rem;
 cursor: pointer;
}
.imgs2{
  width: 7rem;
  cursor: pointer;
 }
 .imgs3{
  width: 9rem;
  cursor: pointer;
 }
 .imgs4{
  width: 11rem;
  cursor: pointer;
 }
 .imgs5{
  width: 8rem;
  cursor: pointer;
  /* border: 2px solid red; */
 }

 .imgs6{
  width: 4rem;
  cursor: pointer;
 }

 .imgs8{
  width: 7rem;
 }
