.headingText{
    color: #2053C5;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   text-align:center;
   padding-top:48px;
   padding-bottom:80px;
}
.blueContainer{
    background: #F4F8FB;
    height:100%;
    width:100%;
 
}
.compareOurPlansTxt{
  color: #454545;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: 1.5rem; 
margin-left: 1.31rem;
}
  .secondContainer{
    display: flex;
    gap:4rem;
    /* padding-right:6.56rem; */
    width:75%;
  }
  .secondContainerBoxes{
    width: 50%;
    /* height: 166.75rem; */
    border-radius: 1rem;
background: #FFF;
padding:2.56rem;
  }
 
  .secondContainerBoxHeading{
border-top-left-radius: 1rem;
border-top-right-radius: 1rem; 
box-sizing: border-box;
text-align: center;
  }
  .secondContainerBoxHeadingText{
color: #212121;
font-family: Inter;
font-size: 1.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .secondContainerFirst{
    padding-top: 1.63rem;
    width:25%;
    margin-right: 2.5rem;
  }
.overlay{
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  width: 89vw;
  background-color: #E5F4FF;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  z-index: 100;
  position: static;
  /* top: 90px; */
  top:120px;
}
.hiddenDiv{
  display: none; 
  width:  89vw;
  height: 50px;
  background: white;
  position: sticky;
  z-index:100;
  top:85px;
}
  .arrayHeading{
    color: #0E2337;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
/* margin-bottom: 2.25rem; */
}
.arrayHeadingExtra{
  color: white;
   margin-bottom:3.7rem;
}
  .arraySubHeadings{
    color: #0E2337;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top:1.12rem;
  }

  .blueButton{
    display: flex;
padding: 0.75rem 0rem;
justify-content: center;
align-items: center;
gap: 0.4rem;
border-radius: 0.5rem;
background: #0084EF;
  }
  .blueButton:hover{
    border-radius: 0.5rem;
background: #0068BD;
  }

  .blueButtonText{
    color: #FFF;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.8125rem;
  }
  /* .blueButtonContainer{
   
  } */

  .blueBorderButton{
    display: flex;
padding: 0.75rem 0rem;
justify-content: center;
align-items: center;
gap: 0.625rem;
border-radius: 0.5rem;
border: 1px solid var(--blue, #0084EF);
background: #FFF;
  }
  .blueBorderButton:active{
    display: flex;
    padding: 0.75rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid var(--blue, #0084EF);
    /* background-color: red; */
  }
  /* .blueBorderButton:active {
    background: red;
  box-shadow: 2px 2px 5px #fc894d;
  border: 10px solid var(--blue, #0084EF);
} */

  .blueBorderButton:hover{
    border-radius: 0.5rem;
border: 1px solid var(--blue, #0084EF);
background: #E5F4FF;
  }
  .blueBorderButtonClicked{
    background-color: red;
  }
  .blueBorderButtonTextClicked{
    background-color: #FFF;
  }
  .blueBorderButtonText{
    font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.8125rem; 
background: var(--blue, linear-gradient(180deg, #0084EF 0%, #0766AD 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .checkCircle{
width: 1.5rem;
height: 1.5rem;
  }
  .arraySubHeadingsContainer{
    display: flex;
padding: 0.5rem 0rem;
align-items: center;
gap: 1.25rem;
  }
.mainContainer{
 margin-bottom: 3rem;
  margin-left:2rem;
}
.arrayHeadingContainer{
  margin-bottom: 2.56rem;
  margin-top: 1.25rem;
}
.outSecondContainer{
  display: flex;
  justify-content: space-between;
  padding:0.7rem 3.75rem 3rem 3.75rem
}
.extraDiv{
  height:2.5rem;
  background: white;
  width:100%;
  z-index: 100;
  position: sticky;
  top:80px;
}
.arrayHeadingContainerExtra{
  margin-top: 1.75rem;
}
  @media only screen and (max-width: 767px){
    .mainContainer{
 margin-bottom: 1.75rem;
  margin-left:1.38rem;
}
    .arraySubHeadingsContainer{
      gap: 0rem; 
    }
    .arrayHeading{
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.75rem;  
  }
  .arraySubHeadings{
font-size: 0.75rem;
font-weight: 500;
margin-top:0rem; 
display:flex;
justify-content: space-between;
width:100%;
  }
  .evenContainer{
    width: 19.75rem;
flex-shrink: 0;
border-radius: 0.75rem;
background: #F7F8FC;
box-shadow: 0px 4px 4px 0px rgba(161, 132, 179, 0.15);
padding:0.75rem;
  }
  .oddContainer{
    padding:0.75rem;
    width: 19.75rem;
    flex-shrink: 0;
    border-radius: 0.75rem;
    background: #FFF;
  }
  .cross{
    height:1rem;
    width:1rem;
  }
  .checkCircle{
    width:0.875rem;
    height:0.875rem;
      }
      .rotatedText {
        font-family:Inter;
        font-size:1rem;
        font-style:normal;
        font-weight:600;
        line-height:1.125rem;
        letter-spacing: -0.03125rem;
        display: inline-block;
        transform: rotate(-90deg);
        transform-origin: 100% 0;
        white-space: nowrap;
        margin-right: 20px; 
      }
      .mainHeading{
        color:black;
        width:9.375rem;
        font-family:Inter;
        font-size:1rem;
        font-style:normal;
        font-weight:600;
        line-height:1.125rem;
        letter-spacing: -0.03125rem;
      }
  }
  @media only screen and (min-width:320px) and (max-width: 374px){
    .evenContainer{
      width:auto;
    }
    .oddContainer{
      width:auto;
    }
  }
  /* .overlayWhite{
    z-index: 100;
    position: sticky;
    top: 75px;
    width: 15vw;
  background-color: #FFF;
  height:40px;
  } */
 
.toggleactive {
  /* background: #1b3f7c; */
  /* border-radius: 50px; */
  /* font-weight: 500; */
  /* font-size: 13px; */
  /* color: #ffffff; */
  padding: 0.22rem 0.65rem;
  cursor: pointer;
  transition: all 300ms;

  color: #454545;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 1.25rem;
background: #F1F9FE;
}

.toggleunactive {
  transition: all 300ms;
  /* background: transparent;
  border-radius: 50px;
  font-weight: 400;
  font-size: 13px;
  color: #1d3557; */
  padding: 0.28rem 0.5rem;
  cursor: pointer;

  color: #FFF;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media only screen and (max-width: 767px) {
  .save25btn{
  display: flex;
  padding: 0.14419rem 0.28831rem;
  justify-content: center;
  align-items: center;
  gap: 0.36044rem;
  border-radius: 1.80213rem;
background: #D3DEF8;
margin-top:0.27rem;
margin-left:10rem;
width: 49px;
}
.save25btnText{
  color: #2053C5;
font-family: Inter;
font-size: 0.4325rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
}

@media only screen and (max-width: 500px) {
  .headingText{
    padding-top: 60px;
    padding-bottom: 20px;
    font-size: 20px;
    width: 80%;
    margin: 0px auto;
  }
.blueBorderButton{
  padding: 0px;
}
}