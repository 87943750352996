.container {
  /* border: 2px solid red; */
  margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 2rem;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #252525;
}

.inputs {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 0.6rem 2rem 0.6rem 1rem;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.inputs ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  color: rgba(37, 37, 37, 0.8);
}
