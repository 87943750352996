.container {
  /* border: 2px solid red; */
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 4rem;
}

.box1 {
  border: 1px solid #e6e6e6;
  filter: drop-shadow(0px 4px 8px rgba(154, 170, 207, 0.2));
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 2rem;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #4d4d4d;
}
.email {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4d4d4d;
  margin-top: 0.7rem;
}

.approve {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #aeaeae;
  margin-top: 1.5rem;
}

.remove {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ff2020;
  cursor: pointer;
}
