.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1d3557;
  text-align: center;
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: #425466;
  margin-top: 1.2rem;
  text-align: center;
  /* border: 2px solid red; */
}

.btn_div {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding-top: 2rem; */
  padding-bottom: 1rem;
  position: fixed;
  top: 9.5%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 999;
  width: 100%;
  /* border-top: 1px solid rgb(180, 174, 174); */
  box-shadow: 0px 4px 16px rgba(154, 170, 207, 0.2);
  transition: all 400ms;
}
.btn_div1 {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.imgs {
  width: 8rem;
  cursor: pointer;
}
.imgs2 {
  width: 7rem;
  cursor: pointer;
}
.imgs3 {
  width: 8.5rem;
  cursor: pointer;
}
.imgs4 {
  width: 10rem;
  cursor: pointer;
}
.imgs5 {
  width: 6.9rem;
  cursor: pointer;
  /* border: 2px solid red; */
}

.imgs6 {
  width: 4rem;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
}

.featurePillContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 1rem 12rem;
  justify-content: center;
  padding-top: none;
}
.featurePill {
  text-decoration: none;
}
.eachPill {
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  .featurePillContainer {
    padding: 1rem 0;
    gap: 0;
  }
  .featurePill {
    width: 50%;
    padding: 0.25rem;
  }
  .eachPill{
    padding: 0.5rem 1rem;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}
