.container {
    /* border: 2px solid red; */
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20rem;
  }
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 29px;
    text-align: center;
    color: #2053c5;
    padding-right: 1rem;
    padding-left:1rem;

  }
  .btn1 {
    background: #2053c5;
    border-radius: 8px;
    border: none;
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 550;
    font-size: 17px;
    line-height: 24px;
    text-align: center; 
    color: #ffffff;
    width: 100%;
  }
  
  .btn2 {
    border: 1px solid #2053c5;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 550;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #2053c5;
    width: 100%;
    margin-top: 1rem;
  }
  
  
  