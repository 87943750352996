.container {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6rem;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #212121;
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-top: 0.3rem;
  /* border: 2px solid red; */
  
}
.large_para{
    /* padding-right: 4rem; */
    width: 90%;
    padding-top: 0.5rem;
    /* border: 2px solid red; */
}
.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #000000;
}
