.cardContainer {
  border: 1px solid #dfdfdf;
  margin-top: 0.5rem;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: scroll;
}

.leadNameDetails {
  display: flex;
  text-overflow: ellipsis;
}

.initialsCircle {
  background-color: #f0f0f0;
  border-radius: 50%;
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888888;
  margin-right: 0.5rem;
  font-size: 14px;
}

.leadName {
  font-size: 14px;
  font-weight: 500;
  width: 10vw;
  overflow: hidden;
  text-overflow: ellipsis;
}
.timelinePill {
  font-size: 8px;
  background-color: #e2e6f0;
  border-radius: 20px;
  width: fit-content;
  padding: 0 0.5rem;
  color: #3b5998;
}

.allDetails {
  display: flex;
  width: 50%;
  justify-content: space-between;
}
.eachDetailSection {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.eachSectionFirstLine {
  font-size: 12px;
  font-weight: 500;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.eachSectionSecondLine {
  font-size: 10px;
  color: #00000099;
}

.rateAndActions {
  display: flex;
  align-items: center;
}
.leadPrice {
  font-size: 16px;
  font-weight: 500;
  margin-right: 2rem;
}
.addToCartButton {
  background-color: #ffffff;
  border-radius: 14px;
  border: 1px solid #3b5998;
  padding: 0 1.5rem;
  margin-right: 0.5rem;
  color: #3b5998;
  height: 1.25rem;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.addToCartButtonActive {
  background-color: #3b5998;
  border-radius: 14px;
  border: 1px solid #3b5998;
  padding: 0 1.5rem;
  margin-right: 0.5rem;
  color: #ffffff;
  height: 1.25rem;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.buyLeadButton {
  background-color: #3b5998;
  color: #ffffff;
  border: 1px solid #3b5998;
  border-radius: 14px;
  padding: 0 1.2rem;
  font-size: 10px;
  height: 1.25rem;
  display: flex;
  align-items: center;
}
.addressLine {
  width: 8rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
