.firstLine {
  color: #fff;
  font-family: Inter;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.firstLineContainer {
  display: inline-flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: rgba(114, 191, 254, 0.50);
}
.secondLine {
  margin-top: 1.44rem;
  color: #fff;
  font-family: Inter;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* max-width: 52.625rem; */
  max-width: 37.3125rem;
  margin-bottom: 2.5rem;
}
.thirdLine {
  margin-bottom: 2.5rem;
  width: 34.1875rem;
  color: #fff;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 127.523%; /* 1.59406rem */
}
.scheduleDemoBtn {
  display: flex;
  padding: 0.75rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.75rem;
  background: #0084ef;
  cursor: pointer;
}
.scheduleDemoBtn:hover{
  box-shadow: 0px 6px 16px 0px rgba(172, 173, 174, 0.20);
}
.scheduleDemoBtnText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 127.523%;
}
.getStartedBtn {
  display: flex;
  padding: 0.75rem 1.25rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 6px 8px 0px rgba(154, 170, 207, 0.2);
  cursor: pointer;
}
.getStartedBtnText {
  color: #0084ef;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 127.523%; /* 1.43463rem */
}
.btnContainer {
  display: flex;
  gap: 1.25rem;
}
.firstLineContainer {
  display: inline-flex;
  padding: 0.25rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: rgba(114, 191, 254, 0.5);
}
.scheduleDemoContainerSticky {
  cursor: pointer;
  display: flex;
  height: 2.75rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #0084ef;
  box-shadow: 0px 6px 8px 0px rgba(154, 170, 207, 0.2);
  width: 100%;
  margin: 0 auto;
}
.btnOuterContainer {
  display: none;
}
@media only screen and (max-width: 767px) {
  .btnOuterContainer {
    display: inline-flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
  .thirdLine {
    width: 100%;
    font-size: 0.75rem;
    margin-right: 3rem;
  }
  .secondLine {
    max-width: fit-content;
    font-size: 1.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.75rem;
  }
  .firstLine {
    font-size: 10px;
    display: inline-flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 3.15px;
    border-radius: 4px;
    /* background: rgba(114, 191, 254, 0.5); */
  }
}
