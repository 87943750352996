@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap");

.myContainer {
  
  height: 70vh;
  width: 100%;
  display: flex;
  background-color: #f0f4ff;
}
.left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.left_wrap {
  width: 100%;
  height: 100%;
  
}

.left-top {
  color: #1d3557;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  width: 90%;
  height: 19%;
  /* margin: 5% 0 0 16% ; */
  padding: 5% 0 0  16%;
}
.left-bottom {
  width: 100%;
  height: 100%;
  background: url("./RAD.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.right {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-wrap{
    width: 90%;
    height:80%;
   
}
.form{
    width: 100%;
    height:90%;
    border: 1px solid #0084EF;
    border-radius: 12px;
    background-color: #E5F4FF;
}
.heading{
    color: #212121;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin: 1rem 0 0 1rem;
}
.form-field{
    /* margin:16px; */
    padding: 16px;
    width: 100%;
    height: 80%;
    
}
.form-field-col2{

    width: 100%;
    margin-top: 21px;
    display: flex; 
    justify-content: space-between;
    gap: 21px;
}              
.col2-row1{
    width: 50%;

}
.col2-row2{
    width: 50%;

}

.form-field-col3{

    width: 100%;
    margin-top: 21px;
    display: flex; 
    justify-content: space-between;
    gap: 21px;
   
    
    }              
.col3-row1{
    width: 50%;

}
.col3-row2{
    width: 50%;

}
.form-field-col4{

    width: 100%;
    margin-top: 21px;
    display: flex; 
    justify-content: space-between;
    gap: 21px;
    
}              
.col4-row1{
    width: 50%;

}
.col4-row2{
    width: 50%;

}

/* .btn{
    margin-top: 3%;
} */


.dropdownClass{
  height:200px;
}
.scheduleDemoBtn{
    /* background: #1D3557;
    outline: none;
    border: none;
    color: white;
    font-size: 14px;
    border-radius: 9px;
    padding: 0.5rem 1rem;
    margin-top: 1rem; */
    cursor: pointer;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 1px solid #0084EF;
    background: #0084EF;
    width:fit-content;
    margin-top: 1rem;
}
.scheduleDemoBtnText{
  user-select: none;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 127.523%;
}
.scheduleDemoBtn:hover {
  /* box-shadow: 0px 6px 12px 0px rgba(108, 108, 108, 0.16); */

box-shadow: 0px 6px 16px 0px rgba(91, 91, 91, 0.18);
}
@media (max-width: 580px) {
  .myContainer {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    
   
  }

  .left {
    width: 100%;
    height: 45%;
   
  }
  .left_wrap{
    height: 100%;
    width: 100%;
   
  }

  .right {
    width: 100%;
    height: 80% ;
    
  }
  .right-wrap{
    width: 100%;
    height: 100%;
    padding: 5px;
  }
  .heading{
    margin: .5rem 0 0 .5rem;
  }
    .form{
        height: 100%;
    }

    .form-field-col2{
        flex-direction: column;
    }         
    .col2-row1{
        width: 100%; 


    }
    .col2-row2{
        width: 100%;

    }
    .form-field-col3{
        flex-direction: column;
    }   
    .col3-row1{
        width: 100%;
    }
    .col3-row2{
        width: 100%;   
    }
    .form-field-col4{
        flex-direction: column;
    }   
    .col4-row1{
        width: 100%;

    }
    .col4-row2{
        width: 100%;   
    }
}
