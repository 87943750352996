/* contractor_form */

.con_main {
    display: flex;
    margin: 0;
    padding: 0;
}

.con_main2 {
    background: #EBF0F9;
    width: 100%;
    height: 100%;
}

.con_center {
    display: flex;
    flex-direction: row;
    margin-top: 5vw;
    justify-content: center;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.02);
}

.con_left {
    display: flex;
    background: #FFFFFF;
    width: 36%;
    border-radius: 10px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.con_left1 {
    display: flex;
    flex-direction: column;
    /* margin-top: 4vw; */
    position: relative;
    top: 14%;

}

.left_img {
    height: 27%;
    width: auto;
}

.left_header {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 35px;
    text-align: center;
    color: #121212;
    width: 84%;
    margin-top: 4vw;
    align-self: center;
}

.left_byline {
    color: #383F45;
    font-family: system-ui;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-top: 1vw;
    width: 57%;
    align-self: center;
}

.con_right {
    background: #F7F7F7;
    display: flex;
    width: 36%;
}

.right_form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.right_content {
    display: flex;
    flex-direction: column;
    width: 63%;
    align-self: center;
    margin-top: 3vw;
}

.input1 {
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    outline: none;
    font-size: 12px;
    height: 2vw;
    padding: 15px;
    margin-top: 1vw;
}

.form_check {
    display: flex;
    flex-direction: row;
    margin-top: 1vw;
}

.check {
    background: #F5F5F5;
    border: 1px solid rgba(127, 135, 144, 0.5);
    border-radius: 1px;
    cursor: pointer;
}

.check_l {
    color: #888888;
    margin-left: 0.8vw;
    font-size: 13px;
}

.form_select {
    display: flex;
    flex-direction: row;
    margin-top: 1vw;
    justify-content: space-between;
}

.input2 {
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    outline: none;
    font-size: 12px;
    height: 2vw;
    padding: 15px;
    width: 52%;
}

.city {
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    font-size: 12px;
    height: 31px;
    width: 46%;
    color: #7F8790;
}

.multiple {
    margin-top: 1vw;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    font-size: 12px;
    height: 31px;
    width: 100%;
    color: #7F8790;
}

.con_submit {
    width: 100%;
    align-self: center;
    margin-top: 3vw;
    background: #3B5998;
    border-radius: 3px;
    border: none;
    height: 37px;
    color: white;
}

@media screen and (max-width: 1030px) {
    .left_img {
        height: 23%;
    }

    .left_header {
        font-size: 27px;
    }

    .left_byline {
        font-size: 12px;
    }

    .con_left1 {
        margin-top: 5vw;
        top: 10%;
    }

    .con_center {
        margin-top: 6vw;
    }

}

@media screen and (max-width: 915px) {
    .con_center {
        margin-top: 9vw;
    }

    .con_left, .con_right {
        width: 46%;
    }

    .con_left1 {
        /* margin-top: 8vw; */
        margin-top: 5vw;
        top: 10%;
    }

    .left_img {
        height: 25%;
    }

    .left_header {
        font-size: 27px;
    }

    .left_byline {
        font-size: 12px;
    }

    .con_submit {
        height: 31px;
    }

}

@media screen and (max-width: 541px) {
    .left_img {
        height: 22%;
    }

    .left_header {
        font-size: 20px;
    }

    .left_byline {
        font-size: 9px;
        width: 66%;
    }

    .input1, .input2 {
        font-size: 11px;
        padding: 12px;
        margin-top: 1.5vw;
    }

    .check_l {
        font-size: 9px;
    }

    .con_center {
        margin-top: 22vw;
    }

    .right_content {
        width: 75%;
    }

    .multiple, .city {
        font-size: 11px;
        height: 26px;
        margin-top: 1.5vw;
    }

    .form_check {
        margin-top: 1.5vw;
    }

    .con_submit {
        margin-top: 2vw;
        height: 29px;
        font-size: 13px;
        padding: 0;
    }
}

/* Contractor_fees and all other contractor screens */

.fees_main {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: #EBF0F9
}

.fees_main2 {
    width: 100%;
    height: 100%;
}

.fees_center2 {
    /* height: 75%; */
    height: 77%;
    display: flex;
    flex-direction: row;
    /* margin-top: 6vw; */
    margin-top: 5.5vw;
    justify-content: center;
}

.fees_left {
    display: flex;
    background: #FFFFFF;
    width: 33%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: center;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.fees_left1 {
    display: flex;
    flex-direction: column;
    margin-top: 7vw;
}

.fees_img1 {
    height: 20%;
    width: auto;
    cursor: pointer;
}

.fees_img2 {
    height: 27%;
    width: auto;
    cursor: pointer;
}

.fees_img3 {
    height: 24%;
    width: auto;
    cursor: pointer;
}

.fees_img4 {
    height: 26%;
    width: auto;
    cursor: pointer;
}

.fees_img6 {
    height: 26%;
    width: auto;
    cursor: pointer;
}

.fees_header {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #121212;
    width: 95%;
    margin-top: 3vw;
    align-self: center;
    line-height: 43px;
}

.fees_header2 {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #121212;
    width: 95%;
    margin-top: 3vw;
    align-self: center;
    line-height: 43px;
}

.fees_bottom {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    margin-top: auto;
    /* cursor: pointer; */
}

.fees_vector {
    width: 4%;
    height: auto;
    cursor: pointer !important;
}

.fees_byline {
    color: #888888;
    font-family: 'Public Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-top: 1.1vw;
    width: 57%;
    align-self: center;
    justify-content: center;
    width: 100%;
    margin-left: 10px;

}

.fees_right {
    background: #F7F7F7;
    display: flex;
    justify-content: center !important;
    width: 33%;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.fees_form {
    display: flex;
    flex-direction: column;
    width: 98%;
    height: 95%;
    /* justify-content: space-between; */
}

.fees_amount {
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(167 167 167 / 30%);
    background: #F7F7F7;
    font-size: 28px;
    border-radius: 3px;
    padding-bottom: 10px;
    margin-top: 48%;
    width: 48%;
    align-self: center;
    text-align: center;
}

.fees_amount::placeholder {
    font-size: 15px !important;
    opacity: 0.5;
}

.fees_submit {
    font-size: 14px;
    align-self: center;
    background: #3B5998;
    border-radius: 3px;
    border: none;
    height: 4.5vh;
    color: white;
    margin-left: auto;
    margin-top: auto;
    position: relative;
    height: 40px;
    width: 90px;
    /* top: -20px; */
    top: -4px;
    left: -20px;
    font-family: 'Public Sans', sans-serif;
}

@media screen and (max-width: 1280px) {
    .fees_left, .fees_right {
        width: 40%;
    }

    .fees_center2 {
        height: 62% !important;
        margin-top: 6vw;
    }

    .fees_amount {
        width: 45%;
    }

    .fees_header2 {
        font-size: 30px;
    }

    .fees_vector {
        margin-top: -4px;
        cursor: pointer !important;
    }

    .fees_byline {
        font-size: 14px;
    }
}

@media screen and (max-width: 1030px) {
    .fees_header2 {
        font-size: 26px;
        line-height: 30px;
    }

    .fees_header {
        font-size: 26px;
        line-height: 35px;

    }

    .fees_img3 {
        height: 29%;

    }

    .fees_img1 {
        margin-top: 2vw;
    }

    .fees_byline {
        font-size: 10px;
        margin-left: 0;
    }

    .fees_vector {
        margin-top: -7px !important;
        width: 4.2%
    }

    .fees_vector {
        margin-top: -9px;
        width: 4.5%;
    }

    .fees_center2 {
        height: 36% !important;
    }

    .fees_amount {
        width: 45%;
        margin-top: 44%;
    }

    .fees_submit {
        font-size: 12px;
    }
}

@media screen and (max-width: 915px) {
    .fees_center2 {
        height: 32% !important;
    }

    .fees_left, .fees_right {
        width: 38%;
    }

    .fees_left1 {
        margin-top: 5vw;
    }

    .fees_center2 {
        margin-top: 12vw;

    }

    .fees_byline {
        margin-left: 0;
    }

    .fees_amount {
        width: 55%;
        margin-top: 55%;
    }

    .fees_header2 {
        font-size: 21px;
    }

    .fees_header {
        font-size: 22px;
        line-height: 33px;
    }

    .fees_img4 {
        height: 28%;
    }
}

@media screen and (max-width: 770px) {
    .fees_center2 {
        margin-top: 18vw !important;
        height: 34% !important;
    }

    .fees_vector {
        margin-top: -9px !important;
    }

    .fees_submit {
        width: 75px;
        height: 30px;
        padding: 0;
        font-size: 12px;
    }

    .fees_amount {
        font-size: 16px;
    }

    .fees_amount::placeholder {
        font-size: 10px !important;
    }

    .fees_amount {
        margin-top: 48%;
        width: 62%;
    }

    .fees_header {
        font-size: 20px !important;
        line-height: 27px;
    }

    .fees_header2 {
        font-size: 19px !important;
    }
}

@media screen and (max-width: 830px) {
    .fees_center2 {
        margin-top: 16vw;
    }

    .fees_header2 {
        font-size: 22px;
        line-height: 27px;
    }

    .fees_header {
        font-size: 21px;
    }
}

@media screen and (max-width: 1217px) {
    .fees_amount {
        font-size: 24px;
    }

    .fees_amount::placeholder {
        font-size: 12px !important;
    }

    .fees_vector {
        margin-top: -10px !important;
    }
}

@media screen and (min-height: 1180px) {
    .fees_submit {
        height: 30px;
        width: 72px;
        font-size: 12px;
        padding: 0
    }
}

@media screen and (max-width: 1370px) {
    .fees_center2 {
        height: 54%;
    }
}

@media screen and (max-height: 600px) {
    .fees_submit {
        height: 5.5vh;
    }
}

@media screen and (max-height: 700px) {
    .fees_center2 {
        height: 73% !important;
    }
}

@media screen and (max-width: 721px) {
    .fees_center2 {
        height: 56% !important;
    }

    .fees_header {
        font-size: 19px
    }
}

@media screen and (max-width: 550px) {
    .fees_header {
        font-size: 15px !important;
        line-height: 22px;
    }

    .fees_header2 {
        font-size: 15px !important;
        line-height: 18px;
    }

    .fees_byline {
        font-size: 8px;
    }

    .fees_vector {
        margin-top: -11px;
    }

    .fees_amount {
        margin-top: 42%;
        font-size: 16px;
        width: 63%;
    }

    .fees_amount::placeholder {
        font-size: 10px !important;
    }

    .fees_submit {
        width: 61px;
        height: 26px;
        font-size: 10px;
        padding: 0;
    }

    .fees_left, .fees_right {
        width: 43%;
    }

    .fees_center2 {
        margin-top: 33vw !important;
        height: 38% !important;
    }
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
    .fees_center2 {
        height: 62% !important;
    }
}

@media screen and (max-width: 540px) and (max-height: 720px) {
    .fees_center2 {
        height: 45% !important;
    }
}

/* popup */
.contractor_popup_main {
    width: 35%;
    position: absolute;
    background: #FFFFFF;
    border-radius: 5px;
    display: none;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    top: 13vw;
    left: 32vw;
}

.popup1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
}

.cross {
    width: 8%;
    margin-left: auto;
    background: none;
    border: none;
    cursor: pointer;
}

.popup_header {
    font-size: 18px;
    text-align: center;
    padding-top: 13px;
}

.popup_byline {
    padding-bottom: 12px;
}

.popup_list, .popup_byline {
    color: #7F8790;
    font-size: 15px;
    width: 79%;
    margin-left: 2vw;
}

.cross_pic {
    filter: brightness(0.7);
    width: 45%;
}

@media screen and (max-width: 912px) {
    .contractor_popup_main {
        width: 47% !important;
        top: 22vw;
        left: 30vw;

    }

    .popup_list, .popup_byline {
        font-size: 12px;
    }

    .popup_header {
        font-size: 16px;
    }
}

@media screen and (max-width: 550px) {
    .contractor_popup_main {
        width: 56% !important;
        top: 34vw;
        left: 24vw;
    }

    .popup_header {
        font-size: 12px;
        padding-top: 30px;
    }

    .popup_list, .popup_byline {
        font-size: 10px;
    }

    .popup_byline {
        padding-bottom: 2px;
    }

    .cross {
        top: 0 !important;
    }

    .cross_pic {
        width: 60%;
    }
}

@media screen and (max-width: 1024px) {
    .contractor_popup_main {
        width: 46%;
    }
}

.btns {
    display: flex;
    width: 42%;
    margin-left: auto;
    justify-content: space-between;
    flex-direction: row;
    margin-top: auto;
}

#skip {
    background: none;
    color: gray;
}