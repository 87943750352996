.companyLogoContainer {
  display: flex;
  gap: 3.75rem;
  align-items: center;
  justify-content: center;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 5.13rem;
}
.arquite {
  display: flex;
  height: 2.13413rem;
  padding: 0.23706rem 0.5625rem 0.24106rem 0.36163rem;
  justify-content: center;
  align-items: center;
}
.essentia {
  width: 14.63894rem;
  height: 2.12781rem;
}
.design {
  width: 11.5rem;
  height: 5.0625rem;
}
.swb {
  width: 14.625rem;
  height: 5.82156rem;
}
.akdesigno {
  width: 25.625rem;
  height: 2.875rem;
}
.alsorg {
  width: 9.59331rem;
  height: 3.0625rem;
}
.companyHeading {
  color: #1d3557;
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 5rem; /* 166.667% */
  margin-bottom: 3.69rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
    .mainContainer{
        gap:0.75rem;
    }
  .companyHeading {
    font-size: 0.80956rem;
    font-weight: 600;
    line-height: 1.34931rem;
    margin-bottom: 1rem;
  }
  .companyLogoContainer {
    gap: 1.1rem;
  }
  .arquite {
    height:0.91994rem;
    width:5.95619rem;
  }
  .essentia {
    width: 4.5905rem;
    height: 0.91813rem;
  }
  .design {
    width: 3.66913rem;
    height: 1.7795rem;
  }
  .swb {
    width: 4.17231rem;
    height: 1.82156rem;
  }
  .akdesigno {
    width: 7.10963rem;
    height: 1.03475rem;
  }
  .alsorg {
    width: 2.82869rem;
    height: 1.08481rem;
  }
}
