.navDD {
    position: relative;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 1000000000;
    background: #FFF;
}

.logo {
    width: 13.96875rem;
    height: 2.25rem;
}

.mainContainer {
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 16px 0px rgba(154, 170, 207, 0.20);
}

.HeaderNav {
    gap: 3.2rem;
    display: flex;
}

.HeaderNavItems {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: #212121;
    font-family: Inter;
    font-size: 1.125rem;

    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;

}

.HeaderNavItems:hover {
    color: #0084EF;
}

.signIn {
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #FFF;
    cursor: pointer;
    margin-right: 1.25rem;
}

.signIn:hover {
    /* border-radius: 0.5rem;
    background: #F5F5F5;
    box-shadow: 0px 6px 12px 0px rgba(136, 136, 136, 0.10); */
    border-radius: 0.5rem;
background: #EBF6FF;
}

.signInText {
    color: #0084EF;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 127.523%;
}

.signUp {
    cursor: pointer;
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #0084EF;
    background: #FFF;
    margin-right: 1.25rem;
}

.signUp:hover{
background: #EBF6FF;
}

.signUpText {
    color: #0084EF;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 127.523%;
    /* 1.27525rem */
}

.scheduleDemoBtn {
    cursor: pointer;
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 1px solid #0084EF;
    background: #0084EF;
}

.scheduleDemoBtn:hover {
    /* box-shadow: 0px 6px 12px 0px rgba(108, 108, 108, 0.16); */

box-shadow: 0px 6px 16px 0px rgba(91, 91, 91, 0.18);
}

.scheduleDemoBtnText {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 127.523%;
    /* 1.27525rem */
}

.btnContainer {
    display: flex;
}

.dropdown {
    box-shadow: 0px 6px 12px 0px rgba(108, 108, 108, 0.16);
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    position: absolute;
    background-color: #FFF;
    z-index: 1000000000;
}

.ddmenus {
    padding: 2.25rem 4.12rem;
    /* padding: 0 0 0 8.12rem; */
    width: 75%;
    display: flex;
    width: 69.1875rem;
    /* padding: 2.25rem 2.75rem 3rem 5.5rem; */
    align-items: flex-start;
    align-content: flex-start;
    gap: 2rem 1rem;
    flex-shrink: 0;
    flex-wrap: wrap;
}
.firstListItem{
    margin-top: 0.25rem;
}
.ddmenu {
    width: 30%;
    border-radius: 0.5rem;
    background: #FFF;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    gap: 8px;
    padding: 8px;
}

.ddmenu:hover {
    cursor: pointer;
    background: #F0F8FF;
}
.ddmenuActive {
    cursor: pointer;
    background: #F0F8FF;
  }
.mhead {
    gap: 5px;
    color: #4D4D4D;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.012rem;
    margin-bottom: 5px;
}

.mdesc {
    color: #4D4D4D;
    font-family: Inter;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.00975rem;
}

.blog {
    width: 25%;
    display: flex;
    /* width: 20.8125rem; */
    /* padding: 2.75rem 5.75rem 2.25rem 2rem; */
    padding: 2.5rem 6.25rem 2.25rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    flex-shrink: 0;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    background: #F0F8FF;
}

.bni {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 15px;
    /* height: 8.6625rem;
    width: 100%;
    background: #FFF; */
}

.bni div {
    background: #FFF;
    height: 100%;
    color: #000;
    font-family: Inter;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;align-items: center;justify-content: center;
    letter-spacing: 0.0075rem;
}

.bhead {
    color: #3D3D3D;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0105rem;
}

.blog p:last-child {
    color: #4D4D4D;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.009rem;
}

.feautresMenuContainer{
    display: flex;
width: 90rem;
/* padding: 1.25rem 2.5rem; */
padding: 2.25rem 4.12rem;
align-items: flex-start;
gap: 1rem;
border-radius: 0rem 0rem 0.5rem 0.5rem;
background: #FFF;
}

.featuresMenuItems{
    display: flex;
align-items: flex-start;
/* gap: 1rem; */
width: 25%;
flex-direction:column;
}
.underFeatureHead{
    border-bottom: 1px solid #8D8D8D;
background: #FFF;
}

.featuresMenuItems li{
list-style-type: none;
}

.featuresHeading{
    color: #4D4D4D;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
border-bottom: 1px solid #EDEDED;
width: 100%;
/* padding-bottom: 0.75rem;
padding-top:0.75rem; */
padding:0.75rem;
}
.readMore{
    color: #0084EF;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.009rem;
cursor:pointer;
}
.readMore:hover{
    color:#0084EF;
    text-decoration: underline;
     text-underline-offset: 0.2rem;
 }
 .arrow{
    display:none;
    height:1rem;
    width:1rem;
}
.readMore:hover .arrow{
    display: block;
}
.featuresSubHeading{
    color: #4D4D4D;
font-family: Inter;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
padding:0.75rem 1rem;
width: 80%;
}

.featuresSubHeading:hover {
    cursor: pointer;
    color: #0084EF;
    background-color:  #F3F7FC;
    border-radius: 0.25rem;
}
.featuresSubHeadingActive{
    cursor: pointer;
    color: #0084EF;
    background-color:  #F3F7FC;
    border-radius: 0.25rem;
}
.iconImg{
    width: 1.375rem;
height: 1.375rem;
}
.featuresHeading:hover{
    background-color: #F7FBFF;
    border-bottom: 0.85px solid #0084EF;
}