.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
}

.cardTitle {
  letter-spacing: 0px;
  color: #000000;
  font-size: 22px;
  padding-bottom: 10px;
}

.edit {
  color: #30a1f7;
  cursor: pointer;
}

.fieldsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
}
