.accordion {
  margin-bottom:4rem;
  margin-top: 2.62rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.accordionItem {
  display: flex;
  width: 77.5rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1rem;
  background: #fff;
  margin-top: 1.5rem;
  border: 1px solid #EDEDED;
  /* box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02),
    0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03),
    0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04),
    0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04),
    0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05),
    0px 28px 80px 0px rgba(154, 170, 207, 0.07); */
}
.accordionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.accordionQuestion {
  cursor: pointer;
  color: #212529;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8125rem; /* 120.833% */
  display:flex;
  gap:10px;
  opacity: 0.6;
}

.accordionAnswer {
  color: #717171;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.8125rem;
  padding-left: 1.38rem;
  margin-top: 0.5rem;
  display: none;
}
.clickedAccItem{
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.12);
}
.clicked{
  color: #404040;
  opacity: 0.9;
}
.accordionItem:hover{
  border-radius: 1rem;
background: #FFF;
box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.12);
}
/* .plusMinusImage{
  opacity:0.6;
} */
.plusImgHover{
  opacity: 0.6;
}
.accordionItem:hover .plusImgHover {
  opacity: 0.9;
}
.accordionItem:hover .accordionQuestion {
  opacity: 0.9;
}

.transitioned {
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
  display: block;
}
.plusMinusToggle {
  font-size: 14px;
  cursor: pointer;
}
.accordionHeading {
  color: #1d3557;
  font-family: Montserrat;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 5rem; /* 166.667% */
  /* margin: 4.62rem 8.94rem 3.25rem 8.19rem; */
    margin-top: 4.62rem;
    margin-bottom: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.plusMinusImage{
  height:25px;
  width:25px;
}
.aboveFooter{
  margin-top:3.88rem;
  color: #000;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: 1.8125rem; /* 120.833% */
text-align: center;
}
.aboveFooterEmail{
  color: #000;
font-family: Inter;
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: 1.8125rem;
text-decoration:underline;
}
.thirdAnswer{
  color: #717171;
}
.ulList {
  padding-left: 1rem;
}

.ulListItem{
  margin-bottom: 1rem;
  color: #717171;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (max-width: 767px) {
  .aboveFooter{
    font-size:0.875rem;
    margin-top:2rem;
    text-align: center;
  }
  .aboveFooterEmail{
    font-size:0.875rem;
  }
  .thirdAnswer{
    margin-top:12px;
  }
  /* ul{
  padding-left:12px;
  list-style-type: none;
  margin:0px;
  }
ul li::before {
    content: "•"; 
    color: #000; 
    font-size: 0.8em; 
    margin-right: 0.5em; 
} */
.ulListItem{
  color: #666;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.5rem;
}
  .plusMinusImage{
    height:0.50831rem;
    width:0.50831rem;
  }
  .accordionHeading {
    font-size: 0.84781rem;
    line-height: 1.41306rem;
    margin: 2rem 0rem 0.75rem 0rem;
  }
  .accordionQuestion {
font-size: 0.59375rem;
line-height: 0.625rem; 
  }
  .accordionAnswer {
    font-size: 0.59375rem;
    line-height: 0.6255rem;
    margin-top: 0rem;
    padding-left:1rem;
  }
  .accordionItem {
    width: 19.69613rem;
    padding: 0.38rem;
    border-radius: 0.25413rem;
    margin-top: 0.38rem;
      /* box-shadow: 0px 0.197px 0.563px 0px rgba(154, 170, 207, 0.02), 0px 0.473px 1.352px 0px rgba(154, 170, 207, 0.03), 0px 0.891px 2.546px 0px rgba(154, 170, 207, 0.04), 0px 1.589px 4.541px 0px rgba(154, 170, 207, 0.04), 0px 2.973px 8.494px 0px rgba(154, 170, 207, 0.05), 0px 7.116px 20.332px 0px rgba(154, 170, 207, 0.07); */
  }
  .accordion{
    margin-top: 0rem;
    margin-bottom:4.5rem;
  }
}
@media only screen and (min-width:1023px) and (max-width: 1439px){
  .accordionItem {
    width: 60rem;
  }
}
@media only screen and (min-width:767px) and (max-width: 1023px){
  .accordionItem {
    width: 45rem;
  }
}