.heading{
    color: #2E548A;
font-family: Inter;
font-size: 2rem;
font-style: normal;
font-weight: 600;
line-height: 5rem; /* 250% */
display:flex;
align-items: center;
justify-content: center;
margin-top: 3.37rem;
}

.accordionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .accordionQuestion {
    cursor: pointer;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.8125rem; /* 120.833% */
    display:flex;
    gap:10px;
    color: #565656;
font-weight: 500;
line-height: 1.8125rem; /* 181.25% */
  }
  .accordionQuestion:hover{
    color: #404040;
  }

  .accordionItem {
     margin-left: auto;
  margin-right: auto;
    display: flex;
    width: 77.5rem;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1rem;
    background: #fff;
    margin-top: 1.5rem;
    border: 1px solid #EDEDED;
    /* box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02),
      0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03),
      0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04),
      0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04),
      0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05),
      0px 28px 80px 0px rgba(154, 170, 207, 0.07); */
  }
  .accordionItem:hover{
    border-radius: 1rem;
background: #FFF;
box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.12);
  }
  .clickedAccItem{
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.12);
  }
   .clicked{
    color: #404040;
  }
  .plusMinusImage{
    height:18px;
    width:18px;
  }
  .plusImgHover{
    opacity:0.6;
  }
  /* .plusMinusImage:hover{
    opacity: 1;
  } */
  .accordionItem:hover .plusImgHover {
    opacity: 1;
  }

.accordionAnsClass{
  padding-left: 1.5rem;
  margin-top: 0.75rem;
  color: #717171;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.8125rem; /* 181.25% */

}

.ulList {
  padding-left: 1rem;
}

.ulListItem{
  margin-bottom: 1rem;
  color: #717171;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.thirdAnswer{
  color: #717171;
}
  @media only screen and (max-width: 767px){
    .accordionAnsClass{
      padding: 0.62rem 1rem;
      margin-top: 0rem;
      color: #666;
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .ulListItem{
      color: #666;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
    .qAndACon{
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    .heading{
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal; 
  margin-top: 2rem;
  }
  .accordionItem{
    width:auto;
    padding: 0.75rem;
    /* box-shadow:0px 4px 24px 0px rgba(98,111,139,0.12) */
  }
  .plusMinusImage{
    margin-left: 2rem;
    height:0.75rem;
    width:0.75rem;
  }
  .accordionQuestion {
    cursor: pointer;
    color: #212529;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3rem; /* 120.833% */
    display:flex;
    gap:10px;
  }
  }