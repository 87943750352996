.container {
  /* border: 2px solid black; */
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
}

.threeBoxDiv {
  /* border: 2px solid red; */
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.totalTask {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #9aa4ac;
}
.leftText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #9aa4ac;
  width: 30%;
}
.leftTextt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #9aa4ac;
  /* width: 30%; */
}
.bar {
  background: #f5f5f5;
  border-radius: 50px;
  border: 2px solid black;
  width: 60%;
  height: 0.5rem;
  border: none;
}
.bar .progress-bar {
  background-color: #ff9d00 !important;
}
.firstData_box {
  background: #ffffff;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  border-radius: 12px;
  width: 32%;
  padding: 1rem 1rem 1rem 1rem;
}
.box_top {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.accountsData_box {
  background: #ffffff;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  border-radius: 12px;
  width: 32%;
  padding: 1rem;
}

.first_box {
  background: #ffffff;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  border-radius: 12px;
  width: 32%;
  padding: 1rem 0 1rem 1rem;
}
.task {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #7e8a95;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.noTask {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #bbbbbb;
  margin-top: 2rem;
}
.btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  border: 2px solid #003566;
  border-radius: 8px;
  color: #003566;
  background-color: #ffffff;
  padding: 5px 10px 5px 10px;
  margin-top:2rem;
}
.btnn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  border: 2px solid #003566;
  border-radius: 8px;
  color: #003566;
  background-color: #ffffff;
  padding: 5px 10px 5px 10px;
}

.addLeads {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #003566;
}
