.toggleactive {
    padding: 0.22rem 0.65rem;
    cursor: pointer;
    /* transition: all 300ms; */
    color: #454545;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 1.25rem;
  background: var(--blue, linear-gradient(180deg, #0084EF 0%, #0766AD 100%));
  height: 1.5rem;
  width: 1.5rem;
  }

  .toggleunactive {
    height: 1.5rem;
    background: transparent;
    width: 1.5rem;
    /* transition: all 300ms; */
    padding: 0.28rem 0.5rem;
    cursor: pointer;
    color: #FFF;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }

  .quarterlyYearlyText{
    color: #454545;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 500;
line-height: normal;
  }


  .selectItem{
    width: 92%;
   padding: 0.38788rem 0.96975rem;
    border-radius: 0.38788rem;
     cursor: pointer;
    }
    .selectItem option {
      /* Define default styles for each option in the dropdown */
      border-radius: 0; /* Resetting border-radius */
      background: #fff; /* Resetting background */
      padding: 0.5rem 1.25rem;
  }
    .selectItem option:hover {
      /* Define styles for each option when hovered */
      border-radius: 0.5rem; /* Applying border-radius on hover */
      background: red; /* Applying background color on hover */
  }