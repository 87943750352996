.mainContainer{
    display:flex;
    padding-top: 3rem;
    padding-bottom: 3rem;
    justify-content: space-between;
    padding-right: 5rem;
     padding-left: 5rem;
     background-color: #FFF;
}
.innerMainContainer{
    width:30%;
    margin-top: 3rem;
    flex-direction: column;
    display: flex;
}
.logoImg{
  width: 12rem;
   height: 2.2rem;

}
.socialIconContainer{
  display: flex;
   gap: 0.75rem;
    margin-top: 3.5rem;
}
.flexColFooter{
    margin-top: 3rem;
}
.flexColFooterHeading{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #17264C;
    margin-bottom: 1rem !important
}
.flexColFooterItems{
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 19px;
    /* color: #0a142f; */
    color:#212121;
    margin-bottom: 0.5rem 
}
.flexColFooterItems:hover{
    color:#0084EF;
}
.footerRight{
    display: flex;
    gap:5.45rem;
}
.firstContainer{
    display: flex;
    gap:4.56rem;
}
.footerRightFirst{
    display: flex;
    gap:5.45rem;
}
.footerPara{
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.625rem;
        color: #212121;
        opacity: 0.5;
        width:22.2125rem;
}
@media only screen and (max-width: 767px){
    .countryName{
        display: flex;
height: 0.9375rem;
justify-content: center;
align-items: center;
flex-shrink: 0;
color: var(--Dark, #0A142F);
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .flexColFooterItems{
        color: var(--Dark, #0A142F);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity:0.5;
    }
    .flexColFooterHeading{
        color: #17264C;
        font-family: Inter;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.75rem; /* 200% */
        margin-bottom: 0.75rem;
    }
    .flexColFooter{
        width:50%;
        margin-top: 0rem;
    }
    .footerRight{
        flex-direction: column;
        gap:1.94rem;
    }
    .footerRightFirst{
        gap:0rem;
        width:100%;
    }
    .footerPara{
        width:19.3rem;
        color: var(--Dark, #0A142F);
font-family: Inter;
font-size: 0.625rem;
font-style: normal;
font-weight: 500;
line-height: 0.9375rem; /* 150% */
    }
.logoImg{
    width: 9.125rem;
height: 1.46981rem;
}
.firstContainer{
    gap:0.84rem;
    flex-direction: column;
}
.mainContainer{
     padding-left: 1.38rem;
     padding-top: 0rem;
}
.socialIconContainer{
    display: flex;
     gap: 0.75rem;
      margin-top: 1.28rem;
      margin-bottom: 2rem;
  }
}