.congratulations{
    color: #212121;
font-family: Inter;
font-size: 1.66438rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.underCongratulations{
    color: #888;
text-align: center;
font-family: Inter;
font-size: 1.10956rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.CongratulationsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:0.83rem;
}
.congratulationsImg{
    width: 13.17613rem;
height: 13.17613rem;
}
.congratulationsImgContainer{
display:flex;
justify-content: center;
align-items: center;
margin-bottom:1.53rem;
margin-top: -100px;
}
.poppers{
    height:10rem;
    width:15rem;
}
.mirrorImagePoppers{
    height:10rem;
    width:15rem;
    transform: scaleX(-1);
  }
  .popperContainer{
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 767px){
    .poppers{
        height:6rem;
    }
    .mirrorImagePoppers{
        height:6rem;
    }
    .congratulationsImg{
        width: 9.1875rem;
height: 9.1875rem;
    }
    .congratulationsImgContainer{
        margin-top: -40px;
    }
  }