.mainContainer{
    /* width: 73.1875rem; */
    width:85%;
    margin-left: auto;
    margin-right: auto;
/* height: 21.125rem; */
flex-shrink: 0;
border-radius: 1rem;
border: 1px solid #F7F7F7;
background: #F5FAFF;
box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02), 0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03), 0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04), 0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04), 0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05), 0px 28px 80px 0px rgba(154, 170, 207, 0.07);
}

.quotesImg{
    width: 3.1875rem;
height: 3.1875rem;
}

.testimonialText{
    color: rgba(33, 33, 33, 0.75);
text-align: center;
font-family: Inter;
font-size: 1.125rem;
font-style: italic;
font-weight: 500;
line-height: 149.9%; /* 1.68638rem */
margin-top: 0.5rem;
/* width: 50.75rem; */
width:65%;
}

.star{
    margin-right: 0.54rem;
    height:1.53781rem;
    width:1.62831rem;
    margin-bottom: 1.6rem;
}

.testimonialRole{
    color: rgba(64, 64, 64, 0.75);
text-align: center;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 0.38rem;
margin-bottom: 1.25rem;
}

.testimonialName{
    color: #404040;
text-align: center;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 1.25rem;
}

.innerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 767px){
.mainContainer{
box-shadow: unset;
}
.quotesImg{
    width: 0.96906rem;
    height: 0.96906rem;
}
.testimonialText{
margin-top: 0.5rem;
width:86%;
color: rgba(33, 33, 33, 0.70);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: italic;
font-weight: 400;
line-height: 149.9%; /* 1.12425rem */
}
.testimonialName{
    color: #404040;
text-align: center;
font-family: Inter;
font-size: 0.75831rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.testimonialRole{
    color: rgba(64, 64, 64, 0.75);
text-align: center;
font-family: Inter;
font-size: 0.65rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top: 0rem;
margin-bottom: 0.5rem;
}
.star{
    margin-right: 0.27rem;
    width: 0.80919rem;
    height: 0.76425rem;
}
}