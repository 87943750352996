.oem_main {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: #EBF0F9
}

.oem_main2 {
    width: 100%;
    height: 100%;
}

.oem_center2 {
    height: 77%;
    display: flex;
    flex-direction: row;
    /* margin-top: 5vw; */
    /* margin-top: 6vw; */
    margin-top: 5.5vw;
    justify-content: center;

}

.oem_left {
    display: flex;
    background: #FFFFFF;
    width: 33%;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.oem_left1 {
    display: flex;
    flex-direction: column;
    /* margin-top: 4vw; */
    justify-content: center;
}

.oem_img {
    height: 26%;
    width: 50%;
    align-self: center;
}

.oem_img2 {
    height: 27%;
    width: 50%;
    align-self: center;
}

.oem_header {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    color: #121212;
    width: 91%;
    margin-top: 2vw;
    align-self: center;
    line-height: 43px;

}

.oem_bottom {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    margin-top: auto;
}

.oem_vector {
    width: 4%;
    height: auto;
}

.oem_byline {
    color: #888888;
    font-family: 'Public Sans', sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-top: 1vw;
    width: 57%;
    align-self: center;
    justify-content: center;
    width: 100%;
}

.oem_right {
    background: #F7F7F7;
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 33%;
    flex-direction: column;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.oem_form {
    display: flex;
    flex-direction: column;
    width: 98%;
}

.oem_amount, .oem_amount2 {
    border: none;
    outline: none;
    border-bottom: 1px solid #A7A7A7;
    background: #F7F7F7;
    font-size: 28px;
    border-radius: 3px;
    padding-bottom: 10px;
    margin-top: 2vw;
    width: 54%;
    align-self: center;
    text-align: center;
}

.oem_amount::placeholder {
    font-size: 15px !important;
}

.oem_amount2::placeholder {
    font-size: 15px !important;
}

.btn_err {
    position: absolute;
    left: 76%;
    top: 81%;
}

.oem_submit {
    width: 12vh;
    align-self: center;
    background: #3B5998;
    border-radius: 3px;
    border: none;
    height: 4.5vh;
    color: white;
    margin-left: auto;
    margin-top: auto;
    /* position: relative;
    top: 79% !important;
    left: -17px; */
    font-size: 14px;
}

@media screen and (max-width: 1281px) {
    .oem_left, .oem_right {
        width: 40%;
    }

    .oem_center2 {
        /* height: 60% !important; */
        margin-top: 7vw;

    }

    .oem_amount, .oem_amount2 {
        width: 51%;
    }


    /* .oem_submit {
        top: 88% !important;
    } */

    .oem_header {
        width: 96%;
    }
}

@media screen and (max-width: 1030px) {
    .oem_header {
        font-size: 30px;
        line-height: 40px;
    }

    .oem_img {
        height: 27%;
    }

    .oem_img2 {
        height: 29%;
    }

    .oem_byline {
        font-size: 10px;
    }

    .oem_vector {
        margin-top: -6px;
    }

    .oem_amount, .oem_amount2 {
        /* width: 66%; */
        width: 54%;
        font-size: 16px;
    }

    .oem_amount::placeholder {
        font-size: 12px !important;
    }

    .oem_amount2::placeholder {
        font-size: 12px !important;
    }

    .btn_err {
        left: 79%;
        top: 69%;

    }

    .oem_center2 {
        height: 67% !important;
    }
}

@media screen and (max-width: 915px) {

    .oem_left, .oem_right {
        width: 38%;
    }

    .oem_left1 {
        margin-top: 6vw;

    }

    .oem_center2 {
        margin-top: 8vw;

    }

    .oem_amount, .oem_amount2 {
        width: 68%;
    }

    /* .oem_submit {
        top: 91% !important;
    } */

    .oem_header {
        font-size: 25px;
        line-height: 38px;
    }
}

@media screen and (max-width: 830px) {
    .oem_img {
        height: 30%;
    }

    .oem_img2 {
        height: 32%;
    }

    .oem_header {
        font-size: 24px;
        line-height: 34px;

    }

    .oem_submit {
        width: 9vh;
        height: 3.5vh;
        /* top: 84% !important; */
    }

    .oem_amount, .oem_amount2 {
        width: 62%;
    }
}


@media screen and (min-height: 1180px) {
    .oem_submit {
        height: 3vh;
        width: 7vh;
        padding: 0;

    }
}

@media screen and (max-width: 770px) {
    .oem_center2 {
        margin-top: 18vw !important;
    }

    /* .oem_submit {
        top: 67% !important;
    } */

    .oem_amount, .oem_amount2 {
        width: 66%;
    }
}

@media screen and (max-width: 830px) {
    .oem_center2 {
        margin-top: 14vw;
    }

    .oem_left, .oem_right {
        width: 41%;
    }
}

@media screen and (max-height: 600px) {
    .oem_submit {
        height: 5.5vh;
    }
}

/* @media screen and (max-height: 700px) {
    .oem_center2 {
        height: 66% !important;
        margin-top: 9vw;
    }
} */

@media screen and (max-width: 721px) {
    .oem_center2 {
        height: 56% !important;
    }

    .oem_header {
        font-size: 19px;
        line-height: 31px;
    }

    .oem_submit {
        /* top: 56% !important; */
        font-size: 12px;
    }

    .oem_amount, .oem_amount2 {
        width: 71% !important;
    }
}

@media screen and (max-width: 550px) {
    .oem_header {
        font-size: 17px;
        line-height: 24px;
    }

    .oem_byline {
        font-size: 8px;
    }

    .oem_vector {
        margin-top: -11px;
    }

    .oem_amount, .oem_amount2 {
        font-size: 14px;
        width: 69%;
    }

    .oem_amount::placeholder {
        font-size: 9px !important;
    }

    .oem_amount2::placeholder {
        font-size: 9px !important;
    }

    .oem_submit {
        width: 8vh;
        padding: 0;
        font-size: 10px;
    }

    .oem_left, .oem_right {
        width: 43%;
    }

    .oem_center2 {
        margin-top: 33vw !important;
        height: 37% !important;
    }

    /* .oem_submit {
        top: 52% !important;
    } */
}

/* @media screen and (max-width: 1370px) {
    .oem_center2 {
        height: 50%;
    }
} */

@media screen and (max-width: 1024px) and (min-height: 1366px) {
    .oem_center2 {
        height: 36% !important;
    }
}

/* congratulations */

/* .congo_main {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background: #EBF0F9
}

.congo_main2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.congo_center2 {
    height: 75%;
    display: flex;
    justify-content: center;
    margin-top: 6vw;
    background: #FFFFFF;
    width: 66%;
}

.congo_start {
    display: flex;
    flex-direction: column;
    margin-top: 5.5vw;
    align-items: center;

}

.congo_img {
    width: 30%;
    height: 30%;
}

.congo_header {
    color: #3D3D3D;
    text-align: center;
    margin-top: 2.5vw;
    font-size: 31px;
    font-weight: 600;
}

.congo_line {
    color: #888888;
    font-size: 18px;
    margin-top: 1vw;
    width: 53%;
    align-self: center;
    text-align: center;
    font-weight: 400;
}

.congo_click {
    color: #3B5998;
    text-decoration: none;
    font-weight: 600;
}

.congo_submit {
    width: 25vh;
    align-self: center;
    background: #3B5998;
    border-radius: 3px;
    border: none;
    height: 40px;
    color: white;
    padding-right: 3vh;
    padding-left: 3vh;
    padding-top: 0.5vh;
    padding-bottom: 0.7vh;
    margin-top: 2vw;
} */

/* popup */

.modal-header {
    border: 0 !important;
}

.modal_main {
    /* display: flex; */
    margin: 0;
    padding: 0;
    background: #F1F6FF;
}

.topsec {
    background-image: url("./On_images2/popup.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.text-align-center {
    text-align: center;
    font-family: 'Manrope', sans-serif;
    font-size: 38px;
    font-weight: 500;
}

.top_con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.5vw;
}

.pop_by_line {
    text-align: center;
    color: #A0B7E9;
    font-size: 18px;
}

.modal-content {
    border: none !important;
}

.pop_bottom {
    display: flex;
    flex-direction: row;
    margin-bottom: 2vw;
    justify-content: center;
    margin-top: -2vw;
}

.bottom_left {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 12px;
    width: 35%;
    margin-top: -3vw;
    margin-left: 2vw;
}

.bottom_left2 {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 12px;
    width: 35%;
    margin-top: -3vw;
    margin-left: 2vw;
}

.bo_basic {
    color: black;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-top: 1vw;
}

.bo_free {
    color: #3B5998;
    font-family: 'Public Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    margin-top: 0.5vw
}

.po_list {
    text-align: left;
    font-family: 'Public Sans', sans-serif;
    font-weight: 300;
    color: #3B5998;
    font-size: 13px;
    width: 89%;
    align-self: center;
    margin-top: 3vw;
}

.po_list2 {
    text-align: left;
    font-family: 'Public Sans', sans-serif;
    font-weight: 300;
    color: #3B5998;
    font-size: 13px;
    width: 89%;
    align-self: center;
    margin-top: 1.5vw;
}

.po_btn {
    background: #FFFFFF;
    border: 1px solid #3B5998;
    color: #3B5998;
    border-radius: 5px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    width: 90%;
    align-self: center;
    margin-top: 3vw;
    margin-bottom: 1.3vw;
}

.po_btn2 {
    color: white;
    background: #3B5998;
    border: 1px solid #3B5998;
    border-radius: 5px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    width: 90%;
    align-self: center;
    margin-top: 1.5vw;
    margin-bottom: 1.3vw;
}

.pop_months {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.mon1 {
    background: #FFFFFF;
    border: 1px solid #3B5998;
    color: #3B5998;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 21px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    width: 28%;
    height: 22px;
}

.mon2 {
    /* background: #FFFFFF; */
    /* border: 1px solid #3B5998; */
    background: #F0F5FF;
    border: none;
    color: #3B5998;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 21px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    width: 28%;
    height: 22px;
}

.mon3 {
    /* background: #FFFFFF; */
    /* border: 1px solid #3B5998; */
    color: #3B5998;
    background: #F0F5FF;
    border: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 21px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    width: 28%;
    height: 22px;
}

@media screen and (max-width: 820px) {
    .pop_bottom {
        justify-content: space-evenly;
    }

    .bottom_left, .bottom_left2 {
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 12px;
        width: 43%;
        margin-top: -3vw;
    }

    .mon1, .mon2, .mon3 {
        font-weight: 900;
        font-size: 0.93vw;
    }

    .po_list, .po_list2 {
        font-size: 11.5px;
    }

    .bo_free {
        font-size: 17px;
    }

    .bo_basic {
        font-size: 18px;
    }

    .modal-dialog {
        max-width: 69%;
        margin-top: 4vw !important;
    }

    .po_btn {
        margin-top: 1.4vh;
    }

    .po_btn2 {
        margin-top: -0.9vh;
    }
}

@media screen and (max-width: 1024px) {
    .pop_bottom {
        justify-content: space-evenly;
        margin-top: -5vw;
    }

    .bottom_left, .bottom_left2 {
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 12px;
        width: 43%;
        margin-top: -3vw;
    }

    .mon1, .mon2, .mon3 {
        font-weight: 900;
        font-size: 0.93vw;
    }

    .po_list, .po_list2 {
        font-size: 11.5px;
    }

    .bo_free {
        font-size: 17px;
    }

    .bo_basic {
        font-size: 18px;
    }

    .modal-dialog {
        max-width: 56%;
        margin-top: 0.6vw !important;
    }
}

@media screen and (max-width: 540px) {
    .pop_bottom {
        justify-content: space-evenly;
    }

    .bottom_left, .bottom_left2 {
        background: #FFFFFF;
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 12px;
        width: 43%;
        margin-top: -3vw;
    }

    .mon1, .mon2, .mon3 {
        font-weight: 900;
        font-size: 1.4vw;
    }

    .po_list, .po_list2 {
        font-size: 11.5px;
    }

    .bo_free {
        font-size: 17px;
    }

    .bo_basic {
        font-size: 18px;
    }

    .modal-dialog {
        max-width: 96% !important;
    }
}

@media screen and (max-width: 1280px) {
    .modal-dialog {
        max-width: 56%;
        margin-top: 2.2vw;
    }
}

@media screen and (max-width: 912px) {
    .modal-dialog {
        max-width: 69%;
        margin-top: 3vw !important;
    }

    .pop_bottom {
        margin-top: -4vw;
    }
}