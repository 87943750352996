/* ************************************************ COMMON INTRO CSS ************************************************ */

.intro {
  position: relative;
  min-height: 42.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  font-family: Inter;
  font-style: normal;
  padding: 0 8.12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.intro h1 {
  width: 55%;
  font-size: 2.375rem;
  font-weight: 700;
  line-height: normal;
}

.intro p {
  width: 49%;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 150%;
  margin-top: 1.25rem;
  margin-bottom: 4rem;
}

.intro button {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  /* box-shadow: 0px 4px 8px 0px rgba(154, 170, 207, 0.12); */
  display: flex;
  padding: 0.75rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none;
  color: #fff;
  background: #0084ef;
  font-size: 1.125rem;
  font-weight: 600;
}
.intro button:hover{
  box-shadow: 0px 6px 16px 0px rgba(172, 173, 174, 0.20);
}
/* ************************************************ COMMON STEPS CSS ************************************************ */

.stepsContainer .icon {
  text-align: right;
  cursor: pointer;
}

.stepsContainer h3 {
  text-align: center;
  color: #2e548a;
  font-family: Inter;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5rem 0rem 0rem 0rem;
  margin-bottom: -2rem;
}

.steps {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .steps:nth-child(even) {
  padding: 5rem 8.12rem;
  border-radius: 0.75rem;
  background: white;
  flex-direction: row-reverse;
}

.steps:nth-child(odd) {
  padding: 1.9rem 8.12rem;
  border-radius: 0.75rem;
  background: #f5faff;
} */

.steps:nth-child(odd) {
  padding:7.75rem 8.12rem;
  background: white;
}
.steps:nth-child(even) {
  padding: 1.9rem 8.12rem;
  background: #f5faff;
  flex-direction: row-reverse;
}
.steps:nth-child(even):last-child {
  padding: 2.5rem 8.12rem 8.5rem 7.5rem;
  background: #f5faff;
  flex-direction: row-reverse;
}

.architect .steps:nth-child(odd) {
  padding:7.75rem 8.12rem;
  background: white;
}

.architect .steps:nth-child(even) {
  padding: 1.9rem 8.12rem;
  background: #f5faff;
}

.steps:nth-child(odd) .stepImage {
  background: #f5faff;
}

.steps:nth-child(even) .stepImage {
  background: white;
}

.stepDetails {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 0.9rem;
  width: 45%;
  padding-right: 2.142rem;
}

.stepImage {
  border-radius: 0.75rem;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 45%;
}

.stepDetails .scount,
.stepDetails .heading {
  color: #1d3557;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
}

.stepDetails .desc {
  margin-top: 1.75rem;
  width: 100%;
}

.stepImage img {
  width: 100%;
  height: 100%;
  scale: 1;
}

.stickyImage {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  z-index: 999; 
}

.icon {
  padding: 0 2.5rem 1.5rem 0;
  text-align: right;
  cursor: pointer;
  position: relative;
}
.stickyImage {
  position: fixed;
  bottom: 20px; 
  right: 0px; 
  z-index: 999; 
}

/* ************************************************ COMMON TOOLS CSS ************************************************ */

.tools {
  position: relative;
  min-height: 42.5rem;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.toolTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3rem;
}

.toolHeading,
.toolSubHead {
  color: #fff;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  width: 67.5%;
}

.toolHeading {
  line-height: normal;
  font-weight: 600;
  font-size: 2.125rem;
  font-size: 2.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.toolSubHead {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.toolsFlex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 2.5rem;
  padding: 2.5rem 0;
}

.toolCard {
  border-radius: 0.5rem;
  background: #fff;
  color: black;
  width: 20%;
  padding: 1.5rem;
  min-height: 20rem;
}

.toolCard div {
  color: #1d3557;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 127.523%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tools li {
  list-style-image: url("./Assets/ArrowRight.svg");
  color: #5d5d5d;
  margin-bottom: 0.55rem;
}

.tools li:hover {
  cursor: pointer;
  color: #0084ef;
}

.tbtns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}

.tbtns button {
  border-radius: 0.5rem;
  border: 1px solid #0084ef;
  /* box-shadow: 0px 4px 8px 0px rgba(154, 170, 207, 0.12); */
  display: flex;
  padding: 0.75rem 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  user-select: none;
  width: 19%;
  font-weight: 600;
  font-size: 1.25rem;
}

.tbtns button:nth-child(1) {
  color: #fff;
  background: #0084ef;
}
.tbtns button:nth-child(1):hover{
  box-shadow: 0px 6px 16px 0px rgba(172, 173, 174, 0.20);
}
.tbtns button:nth-child(2) {
  background: #fff;
  color: #0084ef;
  border: 1px solid white;
}
.tbtns button:nth-child(2):hover{
  border-radius: 0.5rem;
border: 1px solid #0082EB;
background: #F0F8FF;
box-shadow: 0px 6px 16px 0px rgba(172, 173, 174, 0.20);
}


/* ************************************************ COMMON TESTIMONIAL CSS ************************************************ */

.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 7.5rem 0 5rem 0;
}

.theading {
  color: #2e548a;
  text-align: center;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.75rem;
}

.tsubheading {
  color: #252525;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 127.523%;
  margin-bottom: 3.25rem;
}

.tFlex {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 2.5rem;
}

.tCard {
  width: 23.5rem;
  padding: 1.9rem;
  border-radius: 1rem;
  border: 1px solid #f7f7f7;
  background: #fff;
  box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02),
    0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03),
    0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04),
    0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04),
    0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05),
    0px 28px 80px 0px rgba(154, 170, 207, 0.07);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.75rem;
  min-height: 27rem;
}

.textContainer {
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tCard .tBottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.55rem;
}

.tCard p:nth-child(1) {
  color: rgba(33, 33, 33, 0.75);
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 149.9%;
}

.btn2 {
  text-align: center;
  color: #ffffff;
  padding: 0.75rem 1.25rem;
  width: 50%;
  border: none;
  border-radius: 0.5rem;
  background: #0084EF;
  color: #FFF;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: none;
}

.tCard p.TCName {
  color: #404040;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tCard p.TCDesig {
  color: rgba(64, 64, 64, 0.75);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.TCPeopleImg{
  border-radius: 50%;
}
/* ************************************************ COMMON FAQ CSS ************************************************ */

.newfaq {
  /* padding: 0.001rem 0 7.5rem 0; */
  /* background: rgba(250, 250, 250, 0.8); */
}

/* ************************************************ COMMON DEMO CSS ************************************************ */

.dHeading,
.ddesc {
  color: #fff;
  font-style: normal;
  line-height: normal;
  text-align: center;
  width: 44.5%;
}

.dHeading {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.75rem;
}

.ddesc {
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 1.125rem;
}

.demo {
  position: relative;
  height: 39.375rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* @media only screen and (max-width: 767px) {
    .intro{

    }
} */
.topIconImg{
  height:5rem;
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .topIconImg{
    height:4rem;
  }
  .intro {
    min-height: 20rem !important;
    background-size: 24rem 20rem;
    padding: 0px 0px;
    align-items: center !important;
    ;
  }

  .intro h1 {
    width: 85%;
    font-size: 20px;
    text-align: center;
  }

  .intro p {
    margin-top: 0.9rem;
    width: 94%;
    font-size: 12px;
    text-align: center;
    line-height: normal !important;
    ;
  }

  .tFlex {
    flex-direction: column;
  }

  .toolsFlex {
    flex-direction: column;
  }

  .toolCard {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    min-height: fit-content;
    /* Who We Serve */
    box-shadow: 0px 4px 12px 0px rgba(169, 169, 169, 0.25);
  }

  .tools {
    background-image: none !important;
    padding: 0 2rem;
  }

  .toolHeading,
  .toolSubHead {
    color: #2E548A;
  }

  .toolSubHead {
    display: none !important;
  }

  .btn2 {
    margin-left: 0rem;
    padding: 0.75rem 1.125rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
  }

  .dHeading,
  .ddesc {
    width: 100%;
  }

  .demo {
    height: fit-content;
    padding: 5rem 1.5rem;
    background-image: url('');
  }

  .scount {
    display: none;
  }

  .steps:nth-child(even) {
    flex-direction: column-reverse;
    padding: 0px;
    background:  #fff;
  }

  .steps:nth-child(odd) {
    flex-direction: column-reverse;
    /* padding: 0px; */
    padding:1rem 0rem 0rem 0rem;
    background:  #f5faff;
  }

  .architect .steps:nth-child(odd) {
    padding: 5px;
    flex-direction: column-reverse;
    background: #F5FAFF;
  }

  .architect .steps:nth-child(even) {
    padding: 5px;
    background: #ffffff;
  }

  .stepDetails {
    width: 95%;
    padding-right: 0%;
  }

  .stepDetails .scount,
  .stepDetails .heading {
    font-size: 18px;

  }

  .stepDetails .desc {
    /* margin: 0.5rem 1rem 1rem 1rem; */
    padding: 0rem 1rem 1rem 1rem;
    font-size: 13px;
    text-align: justify;
  }

  .stepDetails .heading {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  .stepImage {
    width: 100%;
  }

  .stepImage img {
    margin: 0 3%;
    width: 94%;
    height: 100%;
  }

  .tbtns {
    display: none;
  }

  .toolHeading,
  .toolSubHead {
    width: 100%;
  }

  .toolHeading {
    font-size: 20px;
  }

  .stepsContainer h3 {
    font-size: 20px;
  }

  .tCard {
    width: fit-content;
    padding: 1rem;
  }

  .icon {
    position: fixed !important;
    /* top: 72vh; */
    bottom:6rem;
    z-index: 1;
    right: 0%;
  }

  .testimonials {
    padding: 1rem;
  }

  .theading {
    font-size: 20px;
    color: #ffffff;
  }

  .tsubheading {
    font-size: 16px;
    color: #ffffff;
  }

  .newfaq {
    padding: 0% 0%;
  }

  .stepsContainer h3 {
    padding: 1.5rem 1rem;
    margin-bottom: 0rem;
  }

  .testimonials {
    background-image: url(https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095576.png);
  }

  .stepflex {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  .steps:nth-child(even):last-child {
    padding: 5px;
    background: #fff;
    flex-direction: column-reverse;
  }
}