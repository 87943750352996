.footerHeading{
    color: #FFF;
font-family: "Black Mango Variable";
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.footerText{
    color: #D0D0D0;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.footerHeader{color: #FFF;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.underFooterHeader{
    color: #D0D0D0;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 300;
line-height: normal;
margin-top: 0.62rem;
}
.submitBtn{
    color: #3B5998;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 0rem 0.1875rem 0.1875rem 0rem;
background: #FFF;
}
.emailField{
    border-radius: 0.25rem;
border: 1px solid #777;
color:#FFF;
background: #585858;
height: 2.875rem;
flex-shrink: 0;
width:16.25rem;
 padding-left:1.44rem;
}
.mainContainer{
    padding-right: 5rem;
     padding-left: 5rem;
}
.footerLower{
        color: #FFF;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 53.38rem;
        margin-right: 20.94rem;
      margin-top:auto;
      margin-bottom:auto;
}
@media only screen and (min-width:1023px) and (max-width: 1439px){
    .mainContainer{
        padding-right: 2rem;
         padding-left: 2rem;
    }
    .footerLower{
        margin-left: 40rem;
        margin-right: 5rem;
}
}
@media only screen and (min-width:767px) and (max-width: 1023px){
    .mainContainer{
        padding-right: 2rem;
         padding-left: 2rem;
    }
    .footerLower{
        margin-left: 25rem;
        margin-right: 4rem;
}
.emailField{
    width:12rem;
    height:2rem;
    padding:1rem;
}
.emailContainer{
    display:flex;
    flex-direction: column;
    gap:1rem;
}
}