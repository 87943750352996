.mainContainer{
    width: 100%;
/* height: 445px; */
background: #F0F8FE;
}

.flexContainer{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:1.4rem;
     margin-top:5.44rem;
     margin-bottom:5.44rem;
}

.heading{
    color: #1D3557;
font-family: Inter;
font-size: 2.125rem;
font-style: normal;
font-weight: 600;
line-height: normal;
max-width: 12.4375rem;
margin-top:9.37rem;
margin-bottom: 10.75rem;
margin-right: 1.5rem;
}

.flexItemBox{
    width: 20.25363rem;
border-radius: 0.93388rem;
background: #FFF;
box-shadow: 0px 3.736px 14.942px 0px rgba(154, 170, 207, 0.15);
padding:1.4rem;
position: relative;
}

.deccan{
    width: 8.50831rem;
    height: 1.82769rem;
}

.construction{
    width: 8.28088rem;
height: 1.98494rem;
flex-shrink: 0;
}

.zee{
    width: 5.4375rem;
    height: 2.11763rem;
    flex-shrink: 0;
}

.articleName{
    margin-top: 1.4rem;
    color: rgba(33, 33, 33, 0.60);
font-family: Inter;
font-size: 1.05063rem;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 15.99275rem;
/* margin-bottom: 0.75rem; */
}

.articleDate{
    color: rgba(33, 33, 33, 0.40);
font-family: Inter;
font-size: 0.8125rem;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 10px;
/* position: absolute;
top:10rem; */
}

.readFullArticle{
    color: #0084EF;
font-family: Inter;
font-size: 0.93388rem;
font-style: normal;
font-weight: 500;
line-height: normal;
position: absolute;
bottom:1.4rem;
}

.arrow{
    display:none;
}
.readFullArticle:hover .arrow{
    display: block;
}
.readFullArticle:hover{
    text-decoration: underline;
    color:#0084EF;
    text-underline-offset: 0.2em;
}

.deccanContainer{
    height: 16.92663rem;
}
.constructionContainer{
    height: 16.92663rem;
}
.zeeContainer{
    height: 16.92663rem;
}
.secondArticleName{
   width:18.1875rem;
    margin-right: 3rem;
}
.articleFlexContainer{
    margin-top: 1.25rem;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 767px) {
    .readFullArticle{
        font-size: 0.75rem;
    }
.heading{
    color: #2E548A;
text-align: center;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-top:3.38rem;
margin-bottom: 0rem;
margin-right: auto;
margin-left:auto;
max-width:none;
}
.flexContainer{
flex-direction: column;
margin-top:0rem;
margin-bottom:3rem;
width:19.5rem;
margin:0 auto;
}
.deccan{
    width: 8.19175rem;
    height: 1.75969rem; 
    margin-left: -8px;
}
.construction{
    width: 6.51344rem;
height: 1.56125rem;
margin-left: -4px;
}
.zee{
    width: 4.69819rem;
height: 1.82969rem;
}
.deccanContainer{
    height: 11.1875rem;
}
.constructionContainer{
    height: 12.125rem;
}
.zeeContainer{
    height: 12.4375rem;
}
.articleName{
    margin-top:0.65rem;
    width: 15.99rem;
    margin-right: 0rem;
    font-size: 0.75rem;
}
.articleDate{
position: unset;
margin-bottom: 2.25rem;
margin-top: 0.5rem;
font-size: 0.75rem;
}
/* .secondArticleName{
    max-width:17.625rem;
    margin-right: 0rem;
} */
.flexItemBox{
    width: 100%;
border-radius: 0.89913rem;
background: #FFF;
box-shadow: 0px 3.597px 14.386px 0px rgba(154, 170, 207, 0.15);
padding:1.4rem;
position: relative;
height:auto;
}
.flexItemBox:last-child{
    margin-bottom: 3rem;
}
}