.mobHeaderContainer {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #dfdfdf;
}

.headerLeft {
  display: flex;
  align-items: center;
}

.hamburgerIcon {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.openMenu {
  font-size: 14px;
}

.headerRight {
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 20px;
}

.notification {
  margin-right: 1rem;
}

.cartIcon {
  position: relative;
}

.cartLeadNum {
  position: absolute;
  background-color: #fb5622;
  border-radius: 50%;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  width: 1rem;
  color: #ffffff;
  bottom: 15px;
  left: 10px;
}

.menuAccordion button {
  padding: 0.6rem 0 !important;
  display: flex;
  align-items: center;
}

.menuLinks {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  position: relative;
}

.subLinks {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 14px;
  margin-left: 0.5rem;
  position: relative;
}

.comingSoonPill {
  border-radius: 20px;
  border: 1px solid #3b5998;
  color: #3b5998;
  font-size: 10px;
  background-color: #ffffff;
  padding: 0 0.3rem;
  margin-left: 0.5rem;
}

.smallComingSoon {
  font-size: 8px;
  border: 1px solid #3b5998;
  border-radius: 20px;
  background-color: #ffffff;
  color: #3b5998;
  margin-left: 0.3rem;
  padding: 0 0.15rem;
  line-height: 10px;
}

.initialsagain {
  background: #3B5998;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: white;
  margin-left: 1rem;
}

.menuBackDrop {
  opacity: 0 !important;
}

.commCount {
  height: 15px;
  width: 15px;
  font-size: 10px;
  background: #FB5622;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  position: absolute;
  left: 5.5rem;
  top: 0.5rem;
}