.container {
    /* border: 2px solid black; */
    padding-bottom: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #212121;
  }
  .para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0015em;
    color: #212121;
    margin-top: 0.5rem;
    width: 90%;
    /* border: 2px solid red; */
    text-align: center;
  }
  
  .Eachimg{
      width: 90%;
  }
  