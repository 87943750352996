.secureChat .mainDiv {
  min-height: 90vh;
  overflow: hidden;
}

.secureChat .currmsgs {
  /* height : 69vh;    */
}

.secureChat .allChatItems {
  /* transition: all 1s; */
}

.msg {
  min-width: 52%;
  max-width: 75%;
}
.msg img, #chatInp img {
  width: 100%;
  border-radius: 5px;
}
.msg.type-end {
  float: right;
  background: #49b7cf;
  color: white;
  border-bottom-right-radius: 0px !important;
}

.msg.type-start {
  float: left;
  background: #f5f6f7;
  color: black;
  border-bottom-right-radius: 0px !important;
}

.msg .time {
  font-size: 10px;
}
.msg.type-start .time {
  float: right;
  margin-top: 15px !important;
}

.secureChat .msgInput {
  position: fixed;
  bottom: 0;
  /* width: 52.4% !important; */
}

.box-fixlen {
  /* width: 52.4% !important; */
  width: 41% !important;
}

.allChatItemsInner {
  width: 22%;
}

@media (min-width: 998px) {
  .secureChat .mainDiv {
    /* margin-left: -170px;  */
    /* min-height: 88.5vh; */
  }
}

@media (max-width: 998px) {
  .secureChat .currmsgs {
    /* height : 58vh;    */
  }

  /* .secureChat .mainDiv{
        min-height: 88.5vh;
    } */
}
@media (min-width: 767px) and (max-width: 998px) {
  .secureChat .msgInput {
    bottom: 9vh;
  }
}

@media (max-width: 767px) {
  .secureChat .allChatItems {
    min-width: 100vw;
    /* min-width: 80vw; */
  }
  /* .secureChat .mainDiv{
        min-height: 88.5vh;
    } */
  .secureChat .msgInput {
    position: fixed;
    bottom: 8vh;
    /* width: 100% !important; */
    bottom: 0;
  }
  .box-fixlen {
    width: 100% !important;
  }

  .allChatItemsInner {
    width: 100vw;
  }
}

@media (max-width: 767px) {
  .mv-expander {
    /* width: 60px !important; */
    width: 0px !important;
    min-width: auto !important;
  }
}

.chatInp {
  transition: 0.5s;
}
.expandChatInp {
  max-height: 500px !important;
  height: 500px !important;
}

.pclip ~ div {
  top: 30px;
  z-index: -1;
  opacity: 0;
  transition: 0.5s;
}
.show-pclip {
  z-index: 9;

  opacity: 1 !important;
  top: -50px !important;
}
