.tabDisplay {
    display: flex;
    flex-direction: row;
    background: white;
    height: 100%;
}

.tab_main {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 25px;

}

.tab_main2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.tab_active {
    background: #176091;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    color: white;
    border: none;
    font-size: 14px;
    height: 40px;
    width: 27%;

}

.tab_active2 {
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    color: #888888;
    font-size: 14px;
    height: 40px;
    width: 27%;
    /* pointer-events: none; */
}

.file_upload {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 80%;
    margin-top: 80px;

}

.main_file {
    display: flex;
    background: #F0F0F0;
    border: 1px dashed #A7A7A7;
    border-radius: 8px;
    height: 40vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.upload_img {
    width: 20%;
    height: 20%;
}

.upload_line {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #888888;
    margin-top: 20px;
}

.upload_line2 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}

/* item.active{
    border: none !important;
} */
.carousel-indicators {
    margin-bottom: 0rem !important;
    display: none !important;
}

.tab_button {
    display: flex;
    flex-direction: row-reverse;
    height: 51%;
    align-items: end;
    margin-right: 60px;
}

.tab_next {
    background: #176091;
    border-radius: 4px;
    border: none;
    width: 13%;
    height: 32px;
    color: white;
    font-size: 14px;
    cursor: pointer;
}

.tab_next2 {
    background: #176091;
    border-radius: 4px;
    border: none;
    width: 13%;
    height: 32px;
    color: white;
    font-size: 14px;
    cursor: pointer;
}

.tab_back {
    background: none;
    border: none;
    height: 32px;
    color: #888888;
    font-size: 14px;
    margin-right: 25px;
    cursor: pointer;
}


/* uploadRef */
.uploadReference_main {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100%;
}

.upload_top {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 7rem;
    margin-top: 25px;
    overflow-x: scroll;
}

.uploadRef_active {
    background: rgba(23, 96, 145, 0.15);
    border: 1px solid #176091;
    border-radius: 4px;
    width: 120px;
    height: 36px;
    color: #176091;
    font-size: 14px;
    margin-left: 17px;
}

.uploadRef_unactive {
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 120px;
    height: 36px;
    color: black;
    font-size: 14px;
    margin-left: 17px;
}

.uploadRef_picMain {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 81.5%;
    margin-top: 30px;
}

.uploaRef_pic {
    display: flex;
    background: #F0F0F0;
    border-radius: 8px;
    height: 53vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.uploaRef_pic-err {
    display: flex;
    background: #F0F0F0;
    border-radius: 8px;
    height: 53vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-left: -35px; */
    border: 1px solid #A30D0D;
}

.uploadRef_svg {
    width: 20%;
    height: 20%;
}

.uploadRef_line {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #888888;
    margin-top: 20px;
}

.btn_main {
    display: flex;
    width: 97%;
    flex-direction: row-reverse;
    margin-top: 30px;
}

.btn_main-sticky {
    display: flex;
    width: fit-content;
    flex-direction: row-reverse;
    margin-top: 30px;
    margin-top: auto;
    position: absolute;
    right: 2.5rem;
    bottom: 1rem;
    background: white;
    z-index: 1;
    height: 35px;
    /* width: 74%; */
}

.btn_main2 {
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    height: 32%;
    align-items: end;
}

.uploadRef_submit {
    background: #176091;
    border-radius: 4px;
    border: none;
    width: 120px;
    height: 35px;
    color: white;
    font-size: 14px;
}

/* upload ref2 */
.uploadRef_card {
    display: flex;
    margin-top: 30px;
    margin-left: 36px;
    height: 58vh;
}

.uploadRef_addMore {
    display: flex;
    background: #D9D9D9;
    border-radius: 4px;
    /* height: 14.5vw; */
    /* height: 14vw; */
    /* height: 220px; */
    /* width: 23%; */
    /* width: 19vw; */
    height: 14rem;
    width: 16rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 18px;

}

/* @media screen and (max-width:1024px) {
    .uploadRef_addMore {
        height: 15.99vw;
    }
} */

.addMore_plus {
    width: 30%;
    height: 35%;
}

.addMore_line {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #888888;
    margin-top: 20px;
}

.card_start {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    width: 100%;
    margin-left: 4px;

}

.uploadRef_card2 {
    display: flex;
    margin-right: 20px;
    margin-bottom: 18px;
    /* width: 17vw; */
    /* margin-left: 25px; */
    /* height: 13vw;
    width: 26%;
    margin-bottom: 5vw; */
}

.card-main {
    display: flex;
    flex-direction: column;
    /* width: 17vw; */
    /* width: 71%; */
    /* width: 100%; */
    /* height: 100%;
    width: 80%; */
}

.coross_btn_main {
    display: flex;
    flex-direction: row-reverse;
}

.cross_btn {
    border: none;
    background: none;
    z-index: 1;
}

.uploadRef-card_img {
    margin-top: -26px;
    object-fit: cover;
    width: 16rem;
    height: 11rem;
}

.card_input {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 100%;
    height: 39px;
    margin-top: 10px;
    /* font-size: 1vw; */
    font-size: 14px;
    text-align: center;
    padding: 1vw;
}

.card_input::placeholder {
    font-size: 12px !important;
    text-align: center;
}

/* selectroom */

.selectRoom {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.selectRoom1 {
    display: flex;
    flex-direction: column;
    width: 31%;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    margin-top: 69px;
    align-items: center;
}

.selectRoom2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    border-radius: 4px;
}

.select_left, .select_right {
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
}

.select_left {
    width: 60%;
    padding-left: 40px;
}

.select_right {
    width: 40%;
    padding-right: 40px;
}

.select_name {
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #444444;
}

.select_text {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 92%;
    margin-top: 9px;
    height: 35px;
    padding-left: 20px;
}

.select_text-err {
    background: #FFFFFF;
    border: 1px solid #A30D0D;
    border-radius: 4px;
    width: 92%;
    margin-top: 9px;
    height: 35px;
    padding-left: 20px;
}

.select_text2 {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    width: 92%;
    margin-top: 1px;
    height: 35px;
    padding-left: 20px;
}

.select_text2 {
    background: #FFFFFF;
    border: 1px solid #A30D0D;
    border-radius: 4px;
    width: 92%;
    margin-top: 1px;
    height: 35px;
    padding-left: 20px;
}

.select_text::placeholder {
    font-size: 13px;
}

.inc_btns {
    display: flex;
    flex-direction: row;
    border: 1px solid #DFDFDF;
    height: 35px;
    margin-top: 9px;
}

.dec_1 {
    background: #FAFAFA;
    border: none;
    border-radius: 4px;
    height: 100%;
    width: 37%;
    color: #A7A7A7;
    font-size: 21px;
    font-weight: 600;
}

.inc_1 {
    background: #FAFAFA;
    border: none;
    border-radius: 4px;
    height: 100%;
    width: 37%;
    color: #3B5998;
    font-size: 21px;
    font-weight: 600;
}

.increment_0 {
    background: #FFFFFF;
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;
    width: 37%;
    text-align: center;
    height: 100%;
    padding-top: 3px;
    font-size: 19px;
    font-weight: 400;
}

.add_moreRoom {
    width: 81%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #3B5998;
    border-radius: 4px;
    color: #3B5998;
    font-weight: 500;
    font-size: 15px;
}

.addRoom_total {
    width: 81%;
    height: 47px;
    display: flex;
    background: #F0F0F0;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    margin-top: 15px;
    margin-bottom: 24px;
}

.addRoom_total2 {
    width: 100%;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid rgb(223, 223, 223);
    opacity: 0;
    animation: tot_anim2 0.3s forwards;
}

.add-view {
    font-weight: 400;
    font-size: 16px;
    color: #888888;
}

.add-view-rooms {
    font-weight: 400;
    font-size: 16px;
    color: #888888;

}

.add-view-rooms2 {
    font-weight: 600;
    font-size: 16px;
    color: black;

}

.addRoom_tot1, .addRoom_tot2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

/* @media screen and (max-width:1343px) {
    .selectRoom1 {
        width: 42%;
    }
}

@media screen and (max-width:1118px) {
    .selectRoom1 {
        width: 50%;
        margin-top: 50px;
    }
} */

@media screen and (max-width:940px) {
    .selectRoom1 {
        width: 60%;
    }
}

/* @media screen and (max-width:783px) {
    .selectRoom1 {
        width: 70%;
    }

    .tab_button {
        margin-top: 23px;
    }
} */

.mainRender {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
}

.mainRender_header {
    font-weight: 400;
    font-size: 32px;
    color: #000000;
    text-align: center;
}

.mainRender_byline {
    font-weight: 400;
    font-size: 18px;
    color: #888888;
    margin-top: -12px
}

.mainRender2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 60px;

}

.mainRen_comp1 {
    display: flex;
    flex-direction: row;
    border: 2px solid #3B5998;
    border-radius: 8px;
    width: 32%;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 13px;
    cursor: pointer;
    padding-bottom: 2px;
}

.mainRen_comp2 {
    display: flex;
    flex-direction: row;
    border: 2px solid #A7A7A7;
    border-radius: 8px;
    width: 32%;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 13px;
    cursor: pointer;
    padding-bottom: 2px;
}

.mainRen_comp3 {
    display: flex;
    flex-direction: row;
    background: #FAFAFA;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    width: 32%;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 13px;
    padding-bottom: 2px;
    margin-top: 80px;
    cursor: pointer;
}

.makeOne_lines {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.makOne_line1 {
    font-weight: 400;
    font-size: 24px;
    color: #3B5998;
    margin-bottom: 5px;
}

.makOne_line2 {
    font-weight: 400;
    font-size: 14px;
    color: #888888;
}

.makOne_line1_2 {
    font-weight: 400;
    font-size: 24px;
    color: #444444;
    margin-bottom: 5px;
}

.makOne_line2_2 {
    font-weight: 400;
    font-size: 14px;
    color: #888888;
}

.makOne_line1_3 {
    font-weight: 400;
    font-size: 24px;
    color: #666666;
    margin-bottom: 5px;
    text-align: left;
}

.makOne_line2_3 {
    font-weight: 400;
    font-size: 14px;
    color: #AAAAAA;
}

.makeOne_or {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #A7A7A7;
}

.makeOne_or2 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #A7A7A7;
}

.upload_icons {
    height: 61px;
    width: 65px;
}

.down_select-btn {
    position: relative;
    top: -38px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    transform: rotateZ(0deg);
    animation: anim1 0.3s forwards;
}

.down_select-btn_anim {
    position: relative;
    top: -38px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    animation: anim 0.3s forwards;
}

.dummy_main {
    height: 0px;
}

.dummy_tab {
    height: 0px !important;
    animation: dummy_anim_main 0.3s forwards;
}

.dummy_tab_anim {
    animation: dummy_anim 0.3s forwards;
    margin-bottom: 1rem;
}

.addRoom_total2_anim {
    animation: tot_anim 0.3s forwards;
    width: 100%;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid rgb(223, 223, 223);
}

@keyframes anim {
    0% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(180deg)
    }
}

@keyframes anim1 {
    0% {
        transform: rotateZ(180deg)
    }

    100% {
        transform: rotateZ(0deg)
    }
}

@keyframes dummy_anim {
    0% {
        height: 0%;
    }

    100% {
        height: 100%;
    }
}

@keyframes dummy_anim_main {
    0% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

@keyframes tot_anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes tot_anim1 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* landing page */

.landing_main {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 1.5rem;
}

.landing_main2 {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    width: 57%;
    padding-right: 30px;
    height: 90vh;
    overflow: scroll;
}

/* ::-webkit-scrollbar {
    display: none;
} */



.landing_top {
    display: flex;
    flex-direction: column;
    padding-top: 40px;

}

.landing_header {
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 0.0025em;
    color: #212121;
    line-height: 38px;
}

.landing_p {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 30px; */
    letter-spacing: 0.02em;
    color: #444444;
    padding-top: 12px;
    width: 62%;
}

.exploreMore {
    /* margin-left: -22vh; */
    /* margin-top: 6vh; */
    font-weight: 600;
    font-size: 1.4vw;
    color: #FFFFFF;
    position: relative;
    margin-left: -10.5vw;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing_started {
    background: #3B5998;
    border-radius: 4px;
    border: none;
    /* width: 21%; */
    width: 147px;
    height: 43px;
    color: white;
    font-size: 15px;
    margin-top: 15px;
}

.landing_started2 {
    background: #3B5998;
    border: none;
    /* width: 21%; */
    width: 100%;
    height: 43px;
    color: white;
    font-size: 15px;
    position: fixed;
    bottom: 0;
    margin-top: 30vh;
    margin-bottom: 0;
    margin-left: -18px;

}

.img_lan_header {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}



.sec3_img1 {
    width: 34%;
    height: 100%;
    align-self: center;

}

.sec3_img2 {
    width: 29%;
    height: 100%;
    align-self: center;

}

.sec3_head {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    padding-top: 15px;
    width: 12vw;
    align-self: center;
}

.sec3_head2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    padding-top: 15px;
    width: 12vw;
    align-self: center;
    position: relative;
    /* top: 16px; */

}

.sec3_byline {
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: #888888;
    width: 83%;
    align-self: center;
}

.sec3_byline2 {
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: #888888;
    position: relative;
    /* top: 16px; */
    width: 90%;
    align-self: center;
}

.arrow_connector1 {
    width: 17%;
    height: 54%;
    margin-left: -9vh;
    padding: 9px 9px;
}

.arrow_connector2 {
    width: 19%;
    height: 42%;
    margin-left: -9vh;
    padding: 9px 9px;
}

.landing_faqs {
    display: flex;
    flex-direction: column;
    margin-top: 65px;
}

.carousel-control-next {
    margin-right: -12px;
}

.carousel-control-prev {
    margin-left: -12px;
}

/* .modal::-webkit-scrollbar{
    display: none !important;
} */
.modal {
    overflow-y: hidden !important;
}

.faqs_header {
    font-weight: 600;
    font-size: 18px;
    color: #252A31;
}

.accordion-button:not(.collapsed) {
    color: #444444 !important;
    background-color: white !important;
    font-size: 15px !important;
}

.accordion-button {
    font-size: 15px !important;

}

.accordion-body {
    border-top: none !important;
    box-shadow: none !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    color: #888888 !important
}

.accordion-item {
    background-color: #fff !important;
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
}

.accordion-button::after {
    opacity: 0.5 !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    transform: rotate(-180deg) !important;
}

.side_landing {
    border-left: 1px solid gainsboro;
    padding-left: 30px;
    padding-top: 40px;
    padding-right: 20px;
    width: 35%;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    background: #FFFFFF !important;
    /* border: 1px solid #DFDFDF !important; */
    border-radius: 8px !important;
    padding: 17px 20px !important;
}

.caption_main {
    font-weight: 400;
    font-size: 16px;
    color: #444444;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 25px;
}

.cap_byline {
    font-weight: 500;
    font-size: 18px;
    color: #444444;
    margin-bottom: 0;
}

.cap_span {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
}

.starColor {
    width: 14%;
    height: auto;
}

.carousel-dark .carousel-indicators [data-bs-target] {

    width: 25px;
    height: 7px;
    padding: 0;
    /* background-color: #fff; */
    border: 0;
    border-top: 0;
    border-bottom: 0;
    /* background-color: #3B5998; */
    border-radius: 10px;
}

.carousel-indicators {
    margin-bottom: -1.5rem;
}

.bno1 {
    background: #3B5998;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    height: 19px;
    width: 18px;
    text-align: center;
    border-radius: 19px;
    align-self: center;
}

.bnoContent {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
    padding-left: 10px;
    width: 75%;

}

/* .modal-body{
    padding: 1rem !important
} */

.side_content {
    background-image: url("./3dImages/discount.svg");
    width: 20vw;
    height: 12vw;
    background-size: contain;
}

.dis_prem {
    background: #3B5998;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    height: 29px;
    /* margin-top: 41%; */
    margin-top: 9.3vw;
    margin-left: 25px;
    /* width: 42%; */
    width: 150px;
    font-size: small;
}


/* Final landing */

/* .s_m {
    scroll-snap-type: x;
}

.s_i {
    scroll-snap-align: start;
} */

.landing_img_main {
    scroll-behavior: smooth;
    /* scroll-snap-type: x; */
}

.landing_img_main1 {
    scroll-behavior: smooth;
    /* scroll-snap-type: x; */
}

/* .modal-content {
    background: none;
    box-shadow: none;
} */

.viewall-3d {
    font-weight: 400;
    font-size: 18px;
    color: #3B5998;
    text-decoration: none;
}

.dimag {
    height: 25px;
    width: 25px;
    align-self: center;
    box-shadow: 0px 4px 10px rgb(54 37 37 / 28%);
    border-radius: 100px;
}

.final_tab_active {
    font-weight: 500;
    font-size: 18px;
    color: #3B5998;
    border: none;
    background: none;
    border-bottom: 3.5px solid #3B5998;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 30px;
}

.final_tab_unactive {
    font-weight: 500;
    font-size: 18px;
    color: #888888;
    border: none;
    background: none;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 30px;
}

.final_tabs {
    border-bottom: 1px solid #D9D9D9;
}

.ord_span {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
}

.ord_span2 {
    font-weight: 400;
    font-size: 18px;
    color: #888888;
    margin-left: auto;
}

.ord_span3 {
    font-weight: 400;
    font-size: 16px;
    color: #888888;
}

.cart_file_sub2 {
    display: flex;
    width: 100%;
}

.file2 {
    font-weight: 400;
    font-size: 14px;
    color: #090909;
}

.file3 {
    font-weight: 400;
    font-size: 14px;
    color: #F6691A;
}

.cart_head2 {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    padding-top: 8px;
    padding-left: 10px;
}

.ord_view {
    font-weight: 400;
    font-size: 16px;
    color: #3B5998;
    padding-top: 8px;
    margin-left: auto;
    cursor: pointer;
}

.ord_circ {
    background: #58A1FF;
    height: 8px;
    width: 7px;
    border-radius: 10px;
    align-self: center;
    margin-top: -11px;

}

.ord_circ2 {
    background: #23DA82;
    height: 8px;
    width: 7px;
    border-radius: 10px;
    align-self: center;
    margin-top: -11px;

}

.ord_upload {
    background: #3B5998;
    border-radius: 32px;
    border: none;
    color: white;
    width: 11%;
    height: 26px;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
    margin-top: -2px;
}

.ord_upload2 {
    background: #3B5998;
    border-radius: 32px;
    border: none;
    color: white;
    width: 13%;
    height: 26px;
    font-weight: 400;
    font-size: 13px;
    margin-left: 8px;
    margin-top: 2px;
}

.ord_upload_comp {
    background: #3B5998;
    border-radius: 32px;
    border: none;
    color: white;
    width: 19%;
    height: 24px;
    font-weight: 400;
    font-size: 14px;
    margin-left: 25px;
    margin-top: 5px;
}

.modal-btn {
    width: 80%;
    background: #3B5998;
    border-radius: 4px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    align-self: center;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1250px) {
    .landing_header {
        font-size: 25px;
        line-height: 42px;
    }

    .landing_p {
        font-size: 15px;
    }
}

/* cart */

.cart_sub {
    display: flex;
    justify-content: space-between;
}

.cart_file_sub {
    display: flex;
    width: 42%;
    /* justify-content: space-around; */
}

.cart_main {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.cart_left {
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    /* height: 24vh; */
    /* height: 20vh; */
    padding: 18px 18px;
    width: 100%;
}

.cart_img_div {
    background: #F0F0F0;
    border-radius: 4px;
    height: 8vw;
    width: 20%;
    padding: 18px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
.cart_img {
    /* height: 100%; */
/* height: 90px; */
/* width: 100%; */
/* height: 87px;
    width: 118px; */
/* width: 7vw; */
/* } */

.cart_img {
    /* height: 100%; */
    height: 5vw;
    /* width: 100%; */
    width: 7vw;
}

.cart_left_start {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    width: 100%;
    /* justify-content: space-evenly; */
    /* height: 18vh; */
}

.cart_file {
    display: flex;
    /* margin-top: 15px; */
    justify-content: space-between;
    align-items: baseline;
}

.cart_head {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.file1 {
    font-weight: 400;
    font-size: 0.9vw;
    color: #090909;
}

.cart_below {
    display: flex;
    justify-content: space-between;
    width: 56%;
}

.cart_below2 {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;
    /* width: 80%; */
    margin-left: auto;
}

.mapped {
    background: #F0F0F0;
    border: none;
    border-radius: 4px;
    width: auto;
    height: 27px;
    color: #A7A7A7;
    font-size: 0.75vw;
    margin-right: 10px;
    /* margin-bottom: ; */
    /* margin-left: 10px; */
}

.cart_tot {
    font-weight: 500;
    font-size: 1.5vw;
    color: #000000;
    /* margin-left: 30px; */
    width: 16%;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    justify-content: flex-end;
}

.cart_payment {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
    padding-top: 15px;
    /* padding-left: 5px; */
}

.pay-btn {
    align-self: baseline;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #888888;
    border: none;
    background: none;
    margin-left: auto;
    padding-top: 15px;
}

@media screen and (max-width: 820px) {
    .cart_left {
        height: 15vh;
    }

    .cart_left_start {
        justify-content: space-evenly;
        height: 13vh;
    }

    .cart_below {
        margin-top: 0px;
    }
}

/* offcanvas */

.offcanvas-title {
    font-weight: 400;
    font-size: 22px;
    color: #000000;
    padding-top: 20px;
}

.offcanvas-end {
    width: 500px;
    border-left: 2px solid #DFDFDF;
}

.btn-off {
    background: #F0F0F0;
    border-radius: 4px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    height: 30px;
}

.p-off {
    font-weight: 600;
    font-size: 16px;
    color: #3B5998;
}

.ord-off {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 32px;
    height: 31px;
    color: #3B5998;
    font-size: 12px;
    margin-top: -5px;
}

.off-meet {
    font-weight: 400;
    font-size: 12px;
    color: black;
}

.off-meet-s {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
}

._line {
    position: absolute;
    width: 1.5px;
    /* height: 221px; */
    height: 311px;
    margin-top: 14px;
    margin-left: 3px;
    background: #A7A7A7;
    z-index: -1;
}

._line2 {
    position: absolute;
    width: 1.5px;
    height: 300px;
    margin-top: 14px;
    margin-left: 3px;
    background: #A7A7A7;
    z-index: -1;
}

.cross_img2 {
    width: 16px;
    height: 20px;
    margin-left: auto;
    position: absolute;
    margin-top: 10px;
    left: 90.5%;
}

/* .modal-content {
    background: none !important;
} */
/* .modal-body {
    padding: 0 !important;
} */

/* mobile view */

.err-line {
    color: rgb(163, 13, 13);
    font-size: 14px;
    font-weight: 400;
    width: 80%;
}

.err-line2 {
    color: rgb(163, 13, 13);
    font-size: 14px;
    font-weight: 400;
    width: 35%;
}

@media screen and (max-width: 770px) {
    .card-main {
        display: flex;
        flex-direction: column;
        /* width: 17vw; */
        /* width: 71%; */
        /* width: 100%; */
        /* height: 100%;
        width: 100%; */
    }

    .uploadRef_active {
        width: 96px;
        height: 36px;
        font-size: 12px;
        padding: 0.5rem;
    }

    .uploadRef_unactive {
        width: 96px;
        height: 36px;
        font-size: 12px;
        padding: 0.5rem;
    }

    .uploaRef_pic {
        display: flex;
        background: #F0F0F0;
        border-radius: 8px;
        height: 62vh;
        width: 99%;
        /* margin-top: 10px; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .uploaRef_pic-err {
        display: flex;
        background: #F0F0F0;
        border-radius: 8px;
        height: 62vh;
        width: 99%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        /* margin-left: -35px; */
        border: 1px solid #A30D0D;
    }

    .uploadRef_picMain {
        margin-top: 16px;
        width: 90.5%;
    }

    .err-line {
        width: 87%;
    }

    .err-line2 {
        width: 80%;
    }

    .sidBarHeight {
        display: none;
    }

    .file_upload {
        width: 100%;
        margin-top: 20px;
    }

    .cross_img2 {
        margin-top: 10px;
        left: 86.5%;
    }

    .main_file {
        height: 71vh;
        width: 87%;
        /* margin-bottom: 24px; */
    }

    .tab_next {
        width: 52%;
        height: 44px;
        color: white;
        font-size: 16px;
    }

    .tab_next2 {
        width: 80%;
        height: 44px;
        color: white;
        font-size: 16px;
        position: fixed;
        bottom: 18px;
    }

    .tab_back {
        font-size: 16px;
    }

    /* .mainRender {
        height: 20vh;
    } */

    .tab_button {
        justify-content: space-around;
        /* margin-right: 0; */
        /* margin-top: -29px !important; */
        align-items: flex-end;
        position: fixed;
        width: 100%;
        height: fit-content;
        bottom: 10px;
    }

    .tab_main {
        margin: 0;
        /* height: 100vh; */
        height: 100%;
    }

    .tab_mobile {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
        height: 7vh;
        cursor: pointer;
    }

    .tab_mobile_header {
        font-size: 18px;
        font-weight: 400;
        margin-top: 15px;
        margin-left: 20px;
    }

    .side_im_tab {
        width: 7%;
        margin-right: 10px;
    }

    .tabs_mob_main {
        display: flex;
        border-bottom: 1px solid #DFDFDF;
        height: 7vh;
        justify-content: space-around;
        /* padding-top: 7px; */

    }

    .tab_mob1-active {
        display: flex;
        flex-direction: row;
        padding-top: 15px;
        border-bottom: 2px solid #3B5998;
    }

    .tab_mob1-unactive {
        display: flex;
        flex-direction: row;
        padding-top: 15px;
        border-bottom: 0;
    }

    .no1 {
        background: #176091;
        border-radius: 25px;
        border: none;
        width: 19px;
        height: 19px;
        color: white;
        font-size: 12px;
    }

    .no2 {
        background: #EEEEEE;
        border-radius: 25px;
        border: none;
        width: 19px;
        height: 19px;
        color: #737373;
        font-size: 12px;
    }

    .no1_line {
        color: #3B5998;
        font-size: 13px;
        font-weight: 500;
        margin-left: 7px;
        margin-top: -1px;
    }

    /* select room */
    .selectRoom {
        height: 100%;
    }

    .selectRoom1 {
        width: 100%;
        border: none;
        margin-top: 0;
        height: 100%;
    }

    .select_left {
        padding-left: 15px;
    }

    .select_right {
        padding-right: 20px;
    }

    .mainRender_header {
        display: none;
    }

    .mainRender_byline {
        display: none;
    }

    .mainRender2 {
        flex-direction: column;
        width: auto;
        align-items: center;
        margin-top: 0;
    }

    .mainRen_comp1 {
        display: flex;
        flex-direction: row;
        border: 2px solid #3B5998;
        border-radius: 8px;
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 0px;
        padding-right: 15px;
        padding-left: 17px;
        padding-top: 13px;

    }

    .addRoom_tot1, .addRoom_tot2 {
        font-size: 16px;
    }

    .mainRen_comp2 {
        display: flex;
        flex-direction: row;
        border: 2px solid #A7A7A7;
        border-radius: 8px;
        width: 100%;
        padding-bottom: 0px;
        padding-right: 15px;
        padding-left: 17px;
        padding-top: 13px;

    }

    .mainRen_comp3 {
        width: 82%;
        margin-top: 0;
    }

    .makeOne_or2 {
        display: flex !important;
    }

    .makeOne_lines {
        padding-left: 10px;
    }

    .makOne_line1, .makOne_line1_2, .makOne_line1_3 {
        font-size: 19px;
    }

    .upload_icons {
        height: 47px;
        width: 56px;
    }

    .makOne_line2, .makOne_line2_2, .makOne_line2_3 {
        font-size: 12px;
    }

    .btn_main2 {
        height: fit-content;
        position: fixed;
        bottom: 10px;
    }

    /* .uploaRef_pic {
        margin-left: 0;
        height: 65vh;
        border: 1px dashed #A7A7A7;
    } */

    .upload_top {
        margin-left: 0;
        overflow-x: scroll;
    }

    .uploadReference_main {
        overflow-x: visible;
    }

    .uploadRef_submit {
        width: 96%;
        height: 40px;
        font-size: 15px;
    }

    .btn_main {
        height: fit-content;
        position: fixed;
        bottom: 10px;
        margin-top: 0;
    }

    .uploadRef_addMore {
        display: none;
    }

    /* landing page */
    .landing_main {
        display: flex;
        flex-direction: column;
        padding: 24px 18px;
    }

    .landing_main2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 0;
        height: auto;
        overflow: auto;
    }

    .landing_header {
        font-size: 24px;
        line-height: 36px;
    }

    .landing_p {
        padding-top: 9px;
        width: 100%;
        font-size: 13px;
    }

    /* .modal-backdrop {
        background-color: transparent;
    } */


    .disc-offer {
        background-image: url("./3dImages/discount.svg");
        background-size: cover;
        background-repeat: no-repeat;
        align-self: center;
        align-items: center;
        width: 100%;
        align-items: center;
        height: 28vh;
    }

    .dis_prem {
        width: 134px;
        font-size: x-small;
        height: 25px;
        margin-top: 21vh;
        margin-left: 17px;
    }

    .carousel-item {
        width: 100%;
    }

    .caption_main {
        font-size: 12px;
        padding-bottom: 18px;
    }

    .cap_byline {
        font-size: 14px;
    }

    .cap_span {
        font-size: 10px;
    }

    .bnoContent {
        font-size: 12px;
        width: 90%;
    }

    .bno1 {
        font-size: 12px;
        color: #FFFFFF;
        height: 17px;
        width: 18px;
    }

    .sec3_img1 {
        width: 25%;
        align-self: center;
        line-height: 0;
    }

    .sec3_head {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        width: auto;
    }

    .sec3_byline {
        font-weight: 400;
        font-size: 9.25424px;
        width: 94%;
    }

    .arrow_connector1 {
        width: 26%;
        height: 35%;
        margin-left: -7vh;
    }

    .arrow_connector2 {
        width: 26%;
        height: 19%;
        margin-left: auto;
        position: relative;
        left: -48px;
        top: -15px;
        padding: 0;
    }

    .img_lan_header {
        font-weight: 600;
        font-size: 18px;
        color: #252A31;
    }

    .exploreMore2 {
        margin-left: -4vh;
        margin-top: -15vh;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        z-index: 1;
        text-align: center;
    }

    .landingMobNav {
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    }

    .landing_started {
        width: 100%;
    }

    .landing_faqs {
        padding-right: 12px;
    }

    .accordion {
        width: 100% !important;
    }

    .accordion-button {
        font-size: 12px !important;
        line-height: 18px !important;
        padding: 8px 0px !important;

    }

    /* .cross_img2 {
        margin-left: auto;
        position: relative;
        top: -1.6rem;
        left: -1.4rem;
    } */

    .accordion-body {
        font-size: 12px !important;
        line-height: 18px !important;
        padding: 8px 0px !important;
    }

    .accordion-button:not(.collapsed) {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    .pricing_landing {
        display: flex;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 4px;
        margin-top: 50px;
        padding-left: 17px;
    }

    /* //card */

    .uploadRef-card_img {
        margin-top: -26px;
        width: 10rem;
        height: 7rem;
    }

    .uploadRef_card2 {
        display: flex;
        /* width: 17vw; */
        margin-left: 12px;
        height: fit-content;
        width: 40%;
    }

    .uploadRef_card {
        display: flex;
        margin-top: 30px;
        margin-left: 0px;

    }

    .card_input {
        padding: 3.5vw;
    }

    /* cart  */

    .box_start {
        width: 100%;
        /* height: 28vh; */
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 4px;
    }

    .box_head {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
    }

    .box-file1 {
        font-weight: 400;
        font-size: 12px;
        color: #888888;
        margin-bottom: 0;
    }

    .pay-btn {
        font-weight: 500;
        font-size: 12px;
        padding-top: 0;
        padding-right: 8px;
    }

    .mapped {
        font-weight: 500;
        font-size: 12px;
    }

    .cart_tot2 {
        font-weight: 600;
        font-size: 20px;
        color: black;
    }

    .cart_payment2 {
        font-weight: 400;
        font-size: 10px;
        color: #888888;
        padding-top: 13px;
        padding-left: 5px;
    }

    /* final 3d mob */
    .final_tab_active {
        font-size: 14px;
        border-bottom: 3px solid #3B5998;
        padding-left: revert;
        padding-right: revert;
    }

    .final_tab_unactive {
        font-size: 14px;
    }

    .cart_head {
        font-size: 16px;
    }

    .ord_span2 {
        font-size: 14px;
    }

    .ord_span {
        font-size: 10px;
    }

    .box_start2 {
        width: 100%;
        height: 28vh;
        background: #FFFFFF;
        border-bottom: 1px solid #DFDFDF;
        border-radius: 4px;
    }

    .ord_upload {
        width: 33%;
        font-size: 12px;
        margin-top: 0;
        height: 22px;
    }

    .ord_upload_comp {
        width: 39%;
        font-size: 12px;
        margin-top: 0;
        height: 20px;
    }

    .ord_upload2 {
        width: 25%;
        font-size: 12px;
        margin-top: 0;
        height: 20px;
    }

    .file2 {
        font-size: 12px;
    }

    .file3 {
        font-size: 12px;
        margin-left: 0;
    }

    .cart_head2, .ord_view {
        font-size: 12px;
        padding-top: 4px;
    }

    .ord_span3 {
        font-size: 12px;
    }

    .ord_circ, .ord_circ2 {
        height: 7px;
        width: 7px;
        margin-top: -14px;
    }

    .off-meet, .off-meet-s {
        font-size: 12px;
    }

    .ord-off {
        height: 25px;
        font-size: 11px;
    }

    ._line {
        /* height: 151px; */
        height: 288px;
        margin-top: 12px;
    }

    ._line2 {
        height: 280px;
        margin-top: 12px;
    }

    .btn-off {
        font-weight: 500;
        font-size: 12px;
    }

    .p-off {
        font-size: 14px;
    }
}

.cart-btns {
    display: flex;
    width: 100%;
    height: 7vh;
    position: fixed;
    bottom: 0;
    margin-top: 30vh;
    box-shadow: 0px -4px 10px rgb(0 0 0 / 19%);
    margin-bottom: 0;
}

.car_btns_det {
    display: flex;
    width: 50%;
    flex-direction: column;
    background: white;
    justify-content: center;
}

.p_details {
    color: #407BFF;
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 2vh;
}

.det_price {
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 2vh;
    color: black;
}

.car_btns_place {
    width: 50%;
    font-size: 12px;
    border: none;
    height: 7vh;
    background: #176091;
    color: white;
}

.explore_img_txt {
    width: 23.4%;
    height: 101%;
    display: flex;
}

@media screen and (max-width: 300px) {
    .select_left, .select_right {
        padding: 32px 7px;
    }

    .makeOne_lines {
        padding-left: 10px;
    }

    .makOne_line1, .makOne_line1_2, .makOne_line1_3 {
        font-size: 17px;
    }


    .makOne_line2, .makOne_line2_2, .makOne_line2_3 {
        font-size: 10px;
    }

    .upload_icons {
        height: 39px;
        width: 35px;
    }

    .mainRen_comp1, .mainRen_comp2, .mainRen_comp3 {
        padding-top: 10px;
        padding-bottom: 0px;

    }

    .mainRen_comp3 {
        width: 84%;
        padding-bottom: 0px;
        padding-top: 10px;
    }
}

.carousel-control-next-icon:after, .carousel-control-prev-icon:after {
    filter: brightness(0) !important;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    padding: 0 !important;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    opacity: 1 !important;
}