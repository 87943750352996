.toggleContainer {
  position: relative;

  .count {
    position: absolute;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: #3b5998;
    right: 5px;
    top: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}

.container {
  width: 400px;
  margin: 0 !important;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  margin-bottom: 10px;

  button {
    background: #3b5998;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
  }

  span {
    font-size: 14px;
    color: #35aeea;
    cursor: pointer;
  }
}

.scrollContainer {
  height: 250px;
  overflow: scroll;
}

.unread {
  background-color: #ebeef5 !important;
}

.item {
  height: 55px;
  display: flex !important;
  align-items: center;
  margin-bottom: 5px;

  &:active {
    color: black !important;
    background-color: #ebeef5 !important;
  }

  .icon {
    height: 45px;
    width: 45px;
  }

  .content {
    margin-left: 12px;
    width: 78%;

    span {
      font-size: 14px;
    }

    p {
      margin: 0;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .time {
    align-self: flex-start;
    font-size: 12px;
  }
}
