.fullContainer {
  height: 90vh;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tabsContainer {
  display: flex;
  background-color: #ffffff;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 1rem 0 0 1rem;
  margin-bottom: 0.5rem;
}
.eachTab {
  font-size: 12px;
  color: #888888;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
}
.eachTabActive {
  font-size: 12px;
  border-bottom: 2px solid #3b5998;
  color: #3b5998;
  padding-bottom: 0.2rem;
  margin-right: 1rem;
}

.cardsContainer {
  height: 100%;
  overflow-y: scroll;
}

.floatingPlus {
  background-color: #3b5998;
  color: #ffffff;
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  position: fixed;
  bottom: 16vh;
  left: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
}

.addNewClientHeading {
  border-bottom: 1px solid #dfdfdf;
}
.formBodyContainer {
  padding: 2rem 1rem !important;
}
.formInputContainer input {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #dfdfdf;
  padding: 0.5rem;
  border-radius: 4px;
}
.cancelButton {
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #888888;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 0.5rem;
}
.addClientButton {
  border-radius: 4px;
  background-color: #3b5998;
  color: #ffffff;
  width: 100%;
  border: 1px solid #3b5998;
  padding: 0.5rem;
}
.addClientInactiveButton {
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #888888;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 0.5rem;
}
.rightPanelNoLeads {
  padding: 1rem 1rem 0 1rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

.addClientInputSelect {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  color: #888888;
  padding: 0.5rem;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
