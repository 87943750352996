.container {
  /* border: 2px solid red;  */
  width: 100%;
  /* background: #FFFFFC; */
}

.content {
  /* height: 45vh; */
  margin-top: 5rem;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #1d3557;
  
}

.para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 127.52%;
  text-align: center;
  color: #212121;
  margin-top: 1rem;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.all_btn_div {
  /* border: 2px solid red; */
  margin-top: 1rem;
  width: 100%;
  padding-bottom: 2rem;
}

.file {
  background: #fef7e6;
  border-radius: 50px;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f2af06;
  margin: 0.5rem;
  width: 40%;
  text-align: center;
}



