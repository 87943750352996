.firstLine {
  color: #f1f1f1;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem; /* 166.667% */
  text-transform: uppercase;
}
.secondLine {
  margin-top: 1.5rem;
  color: #fff;
  font-family: Inter;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 649px;
  margin-bottom: 1.25rem;
}
.thirdLine {
  margin-bottom: 2.75rem;
  width: 34.1875rem;
  color: #fff;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.6875rem */
}
.scheduleDemoBtn {
  display: flex;
  padding: 0.75rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #0084EF;
  background: #0084EF;
  cursor: pointer;
}
.scheduleDemoBtn:hover{
  border-radius: 0.5rem;
  border: 1px solid #0082EB;
  background: #0082EB;
  box-shadow: 0px 6px 16px 0px rgba(172, 173, 174, 0.20);
}
.scheduleDemoBtnText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 127.523%;
}
.getStartedBtn {
  display: flex;
  padding: 0.75rem 1.25rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 6px 8px 0px rgba(154, 170, 207, 0.2);
  cursor: pointer;
}
.getStartedBtnText {
  color: #0084ef;
  text-align: center;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 127.523%; /* 1.43463rem */
}
.btnContainer {
  display: flex;
  gap: 1.25rem;
}
.firstLineContainer {
  display: inline-flex;
  padding: 0.25rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: #72BFFE80;


  
}

.heroImg {
  display: block;
  width: 100%;
  height: auto;
  z-index: 2;
  height: 42.5rem;
}
.heroContainerMain{
  /* background: #F5FAFF; */
}
.mainContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}
.gradientContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}

.innerContainer {
  position: absolute;
  top: 9.38rem;
  left: 6.12rem;
  color: white;
  z-index: 4;
}
.btnOuterContainer {
  display: none;
}


@media only screen and (max-width: 767px) {
  .scheduleDemoBtnText{
    font-size: 1rem;
  }
  .sticky {
    position: sticky;
    bottom: 0;
  }
  .scheduleDemoBtn {
    width: 100%;
  }
  .btnContainer {
    width: 95%;
  }
  .gradientContainer {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .flexInnerContainerOuter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heroImg {
    display: block;
    width: 100%;
    height: auto;
    z-index: 2;
    height: 368px;
  }
  .firstLine {
    font-size: 0.625rem;
    line-height: 0.875rem;
    text-align: center;
  }
  .secondLine {
    font-size: 22px;
    font-weight: 700;
    width: 90%;
    text-align: center;
  }
  .thirdLine {
    font-size: 0.8125rem;
    width: 19.25rem;
    text-align: center;
  }
  .innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    left: auto;
    flex-direction: column;
    top: 2.75rem;
  }
  .btnOuterContainer {
    display: inline-flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
  }
}
