.hamburgerPage{
    height: 100vh;
    position: relative;
}
.innerContainer{
    padding:1rem;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0rem;
    background-color: #FFF;
}
.logo{
    width: 8.79188rem;
height: 1.41613rem;
}
.ham{
    width: 1rem;
height: 1rem;
}
.menuItemsContainer{
    padding:1rem;
    overflow: scroll;
height: 65vh;
 
}
.menuItems{
    padding: 1.5rem 0rem;
    display: flex;
    justify-content: flex-start;
    border-bottom: 0.5px solid rgb(184, 184, 184);
}
.menuText{
    font-weight: 500;
    color: #212529;
}
.downArrowButton{
    margin-left: 1rem; 
}
.menuItemsParent{

}
.subMenuContainer{
    padding:1rem 0rem;
}
.subMenuTitle{
    display: flex;
    align-items: center;
    padding:0.5rem 0rem 0rem 0rem;
}
.subMenuItemData{
    padding: 0.3rem 0rem;
}
.subMenuTitleText{
    font-weight: 600;
    margin-left: 0.5rem;
}
.subMenuContainerInner{
    margin-bottom: 0.5rem;
}
.subMenuItemsContainer{
    margin-left: 1.5rem; 
}
.mega2Description{
    font-size: 12px;
}
.signIn {
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #FFF;
    cursor: pointer;
    width: 90%;
}

.signIn:hover {
    border-radius: 0.5rem;
    background: #F5F5F5;
    box-shadow: 0px 6px 12px 0px rgba(136, 136, 136, 0.10);
}

.signInText {
    color: #0084EF;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 127.523%;
}

.signUp {
    cursor: pointer;
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 1px solid #0084EF;
    background: #FFF;
    width: 90%;
}

.signUpText {
    color: #0084EF;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 127.523%;
    /* 1.27525rem */
}

.scheduleDemoBtn {
    cursor: pointer;
    display: flex;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    width: 90%;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    border: 1px solid #0084EF;
    background: #0084EF;
    color: #FFF;
}

.scheduleDemoBtn:hover {
    box-shadow: 0px 6px 12px 0px rgba(108, 108, 108, 0.16);
}
.btnContainer {
    position: fixed;
    bottom: 0rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
}