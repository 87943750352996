.container {
  height: 100vh;
  display: flex;
}

/* second div */
.second_contain {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.PersonalContent_div {
  /* border: 2px solid red; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.personal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  text-align: center;

  color: #212121;
}
.inputs {
  background: #f2f4fc;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 100%;
  margin-top: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  color: #2053c5;
}
.inputs1 {
  background: #eafaee;
  border-radius: 10px;
  border: none;
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 100%;
  margin-top: 2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #2dc653;
}
.inputs ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #4d4d4d;
}

.skip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #b7b8bb;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
  width: 80%;
  margin-top: 1.2rem;
  cursor: pointer;
}
.check {
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
}
