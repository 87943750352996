.heading{
    color: #2E548A;
font-family: Inter;
font-size: 2.125rem;
font-style: normal;
font-weight: 600;
line-height: normal;
display:flex;
justify-content: center;
align-items: center;
margin-bottom: 3.88rem;
padding-top: 20px;
}

.outerContainer{
    display: inline-flex;
padding: 1.5rem 1.5rem 2.56rem 1.5rem;
flex-direction: column;
align-items: flex-start;
gap: 0.625rem;
border-radius: 1rem;
background: #EFF7FB;
position:relative;
}
.projectImage{
    width: 41.625rem;
height: 26.875rem;
flex-shrink: 0;
border-radius: 0.5rem;
position:absolute;
top:1.5rem;
left:1.5rem;
right:1.5rem;
bottom:2.56rem;
}

.projectStats{
    display: inline-flex;
padding: 0.5rem;
flex-direction: column;
align-items: flex-start;
gap: 0.625rem;
border-radius: 0.5rem;
background: #FAFAFA;
position:absolute;
right:0rem;
bottom:0rem;
box-shadow: 0px 5.054px 10.107px 0px rgba(141, 141, 141, 0.25);
}

.projectStatsImg{
    position: absolute;
    right:0.5rem;
bottom:1rem;
width: 20.10825rem;
height: 11rem;
}

.flexContainer{
    display:flex;
    gap:7.37rem;
    justify-content: center;
    align-items: center;
}

.projectManagementBtn{
    /* width:52%; */
    /* display: flex;
justify-content: center;
align-items: center;
gap: 0.625rem; */
padding: 0.625rem 0.75rem;
display: inline-block;
border-radius: 0.5rem;
background: #EAF5FA;
}
.projectManagementBtnText{
    color: #247BA0;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.projectManagementHeading{
    color: #1D3557;
font-family: Inter;
font-size: 1.75rem;
font-style: normal;
font-weight: 600;
line-height: 2.25rem; /* 128.571% */
width: 28.625rem;
margin-top: 1rem;
margin-bottom: 1rem;
}
.projectManagementText{
    color: #666;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 150% */
width: 28.1875rem;
}
.learnMoreBtn{
    margin-top:2rem;
    display: flex;
gap: 2.5rem;
border-radius: 0.5rem;
border: 0.75px solid #D7D7D7;
/* width: 6.9375rem; */
width:30%;
height: 2.5rem;
padding: 0.88rem;
justify-content: center;
align-items: center;
gap: 0.25rem;
cursor:pointer;
}
.learnMoreBtn:hover{
    background: #FFF;
/* box-shadow: 0px 4px 10px 0px rgba(176, 176, 176, 0.20); */
border: 0.75px solid #0084EF;
width:35%;
/* width: 8.375rem; */
}
.learnMoreBtnText{
    color: #0084EF;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.learnMoreBtnText:hover{
    font-weight: 600;
}
.arrowRight{
    display:none;
    width: 1.125rem;
height: 1.125rem;
}
.learnMoreBtn:hover .arrowRight{
    display:block;
}
.mainContainer{
    display:flex;
    flex-direction:column;
    gap:10.69rem;
    margin-bottom: 10rem;
}
.homeSectionImgs{
    width:41.5rem;
    height:28.82rem;
}
.upperHeading{
    margin-top: 9.94rem;
    color: #2D2D2D;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .projectTop{
        position:absolute;
        top:1rem;
        left:1rem;
        width: 11.25rem;
        border-radius: 0.5rem;
        box-shadow: 0px 5.054px 10.107px 0px rgba(141, 141, 141, 0.25);
    }
    .costTop{
        border-top:3px solid #7148AF;
        border-right:3px solid #7148AF;
        position:absolute;
        top:1rem;
        right:1rem;
        width: 11.25rem;
        border-radius: 0.5rem;
        /* border-radius: 0.21444rem; */
        box-shadow: 0px 5.054px 10.107px 0px rgba(141, 141, 141, 0.25);
    }
    .costBtm{
        position: absolute;
        left:0.5rem;
    bottom:1rem;
    box-shadow: 0px 5.054px 10.107px 0px rgba(141, 141, 141, 0.25);
    width:25.5rem;
    }
    .clientTop{
        position:absolute;
        top:1rem;
        left:1rem;
        width: 15.375rem;
        border-radius: 0.5rem;
        box-shadow: 0px 5.054px 10.107px 0px rgba(141, 141, 141, 0.25);
    }
    .clientBtm{
        width:19rem;
        position:absolute;
        right:1rem;
        bottom:1rem;
        box-shadow: 0px 5.054px 10.107px 0px rgba(141, 141, 141, 0.25);
    }

    @media only screen and (max-width: 767px){
        .learnMoreBtn:hover .arrowRight{
            display:unset;
        }
        .learnMoreBtnText:hover{
            font-weight: 500;
        }
        .learnMoreBtn:hover{
        width:unset;
        }
        
        .homeSectionImgs{
            width:19.5rem;
            height:13.54rem;
        }
        .textMainContainer{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 1.5rem;
            margin-right: 1.5rem;        
        }
        .upperHeading{
            font-size: 0.75rem;
            margin-top: 2.25rem;
        }
        .heading{
            text-align: center;
        font-size: 1.25rem;
        font-weight: 500;
        letter-spacing: 0.025rem;
        /* margin-bottom: 1.5rem; */
        margin:0.75rem 1.5rem 1.5rem 1.5rem;
        }
        .flexContainer{
        flex-direction: column;
        gap:1.77rem;
        }
        .flexSecondContainer{
            flex-direction: column-reverse;
        }
        .project{
            width:100%;
            height:11.39681rem;
        }
        .projectTop{
            box-shadow: 0px 1.172px 3.515px 0px rgba(0, 0, 0, 0.12);
            width: 5.5125rem;
            top:0.5rem;
            left:0.5rem;
            border-radius: 0.245rem;
        }
        .projectStatsImg{
            width:9.53953rem;
            height:5.41891rem;
            right:0.25rem;
            bottom:0.5rem;
            box-shadow: 0px 1.172px 3.515px 0px rgba(0, 0, 0, 0.12);
        }
        .projectManagementBtn{
            padding: 0.44625rem 0.89244rem;
            gap: 0.44625rem;
            border-radius: 0.375rem;
        }
        .projectManagementHeading{
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.60644rem;
        width: auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:0.75rem 0.28rem ;
        }
        .projectManagementText{
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1.07094rem; /* 150% */
        width: auto;
        }
        .learnMoreBtn{
        border-radius: 0.357rem;
        border: 0.535px solid #D7D7D7;
        width: auto;
        height: 1.78488rem;
        padding: 1rem 0.79rem;
        justify-content: center;
        align-items: center;
        cursor:pointer;
        margin-top:1.5rem;
        gap:0.45rem;
        }
        .learnMoreBtnText{
            color: #0084EF;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 8px;
        }
        .costTop{
            border-top:1.698px solid #7148AF;
            border-right:1.698px solid #7148AF;
            top:0.5rem;
            right:0.5rem;
            width: 4.916rem;
            height:4.20094rem;
            border-radius: 0.21444rem;
            box-shadow: 0px 1.172px 3.515px 0px rgba(0, 0, 0, 0.12);
        }
        .costBtm{
            box-shadow: 0px 1.172px 3.515px 0px rgba(0, 0, 0, 0.12);
            left:0.25rem;
            bottom:0.5rem;
            width:11.20225rem;
        }
        .outerContainer{
            display: inline-flex;
        padding: 0.63613rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.26506rem;
        border-radius: 0.42406rem;
        background: #EFF7FB;
        position:relative;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        }
        .projectManagementBtnText{
            font-size: 0.75rem;
            font-weight: 600;
        }
        .mainContainer{
            gap:3.98rem;
            margin-bottom: 3.3rem;
        }
        .clientBtm{
            width:8.1rem;
            right:0.2rem;
            bottom:0.5rem;
            box-shadow: 0px 1.172px 3.515px 0px rgba(0, 0, 0, 0.12);
        }
        .clientTop{
            position:absolute;
            top:0.5rem;
            left:0.5rem;
            width: 6.52006rem;
            border-radius: 0.1325rem;
            box-shadow: 0px 1.172px 3.515px 0px rgba(0, 0, 0, 0.12);
        }
    }