@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import "styles.scss", "dashboard.scss", "common.scss";

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  height: 100vh;
  background-color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
