.testimonialsHeading{
    color: #1D3557;
    text-align: center;
    font-family: Montserrat;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top:4.69rem;
}

.testimonialsText{
    color: #252525;
text-align: center;
font-family: Montserrat;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 127.523%;
width: 43.5rem;
}
.testimonialsTextContainer{
    margin-top:1rem;
    display:flex;
    justify-content: center;
    align-items: center;
}
.testimonialPeopleContainer{
    display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 1.75rem;
margin-left:8.38rem;
}
.testimonialFlexContainer{
    display:flex;
    gap:6.5rem;
    margin-top:5.13rem;
    justify-content: space-between;
}
.testimonialPeople{
    height:7.75rem;
     width: 31.4375rem; 
    display: flex;
    gap:1.5rem;
}
.testimonialPeople:hover, .testimonialPeople.clicked {
    border-radius: 1rem;
background: #FFF;
height:7.75rem;
    width: 31.4375rem;
box-shadow: 0px 0.775px 2.214px 0px rgba(154, 170, 207, 0.02), 0px 1.862px 5.32px 0px rgba(154, 170, 207, 0.03), 0px 3.506px 10.017px 0px rgba(154, 170, 207, 0.04), 0px 6.254px 17.869px 0px rgba(154, 170, 207, 0.04), 0px 11.698px 33.422px 0px rgba(154, 170, 207, 0.05), 0px 28px 80px 0px rgba(154, 170, 207, 0.07);
}
.testimonialPeopleName{
    color: #212121;
font-family: Montserrat;
font-size: 1.0rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.testimonialPeoplesText{
    display: inline-flex;
height: 24.1875rem;
flex-direction: column;
align-items: flex-start;
gap: 1.3125rem;
flex-shrink: 0;
width: 39.125rem;
margin-right:4.56rem;
}
.testimonialPeopleRole{
    color: rgba(33, 33, 33, 0.75);
text-align: left;
font-family: Inter;
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.testimonialPeopleImage{
    align-self:center;
    width: 5.5625rem;
height: 5.5625rem;
flex-shrink: 0;
border-radius: 5.5625rem;
margin-left: 1.94rem;
}
.testimonialPeopleDetails{
    margin-top: 2.5rem;
    margin-left:2rem;
    display:flex;
    flex-direction: column;
}
.testimonialsRightSide{
    margin-top: 1.31rem;
    display: flex;
    flex-direction: column;
    gap:1.31rem;
}
.testimonialsRightSideHeading{
    color: #252525;
font-family: Montserrat;
font-size: 1.0rem;
font-style: normal;
font-weight: 700;
line-height: 1.8125rem; /* 120.833% */
}
.testimonialRightSideHead{
    display: flex;
    flex-direction: column;
    gap:0.94rem;
}
.star{
    margin-right: 0.54rem;
    height:1.53781rem;
    width:1.62831rem;
}

@media only screen and (max-width: 767px) {
.testimonialsHeading{
    font-size: 1.19363rem;
    margin-top:0.75rem;
}
.testimonialsText{
    font-size:0.52444rem;
}
.testimonialsRightSideHeading{
    font-size:0.5rem;
    line-height:0.625rem;
}
.testimonialPeoplesText{
    margin-right:1.38rem;
    font-size: 0.4375rem;
}
.testimonialPeopleName{
    font-size:0.5625rem;
}
.testimonialPeopleRole{
    font-size:0.375rem;
}
.testimonialPeopleImage{
    width: 1.64775rem;
height: 1.64775rem;
border-radius: 1.64775rem;
margin-left: 0.57rem;
}
.testimonialPeople{
    width:8.5rem;
    height:2.3125rem;
    gap:0.43rem;
}
.testimonialPeople:hover, .testimonialPeople.clicked {
    border-radius: 0.29625rem;
background: #FFF;
width:8.5rem;
height:2.3125rem;
box-shadow: 0px 0.23px 0.656px 0px rgba(154, 170, 207, 0.02), 0px 0.552px 1.576px 0px rgba(154, 170, 207, 0.03), 0px 1.039px 2.967px 0px rgba(154, 170, 207, 0.04), 0px 1.853px 5.293px 0px rgba(154, 170, 207, 0.04), 0px 3.465px 9.9px 0px rgba(154, 170, 207, 0.05), 0px 8.294px 23.698px 0px rgba(154, 170, 207, 0.07);
}
.testimonialFlexContainer{
gap:1rem;
margin-top:1.5rem;
}
.testimonialPeopleContainer{
gap: 0.52rem;
margin-left:1.38rem;
margin-bottom:0.81rem;
}
.star{
    height:0.44813rem;
    width:0.4745rem;
    margin-right: 0.16rem;
}
.testimonialPeoplesText{
height: auto;
gap: 1.3125rem;
flex-shrink: 0;
width: 10.25rem;
margin-right:1.38rem;
}
.testimonialPeopleDetails{
    margin-left: 0.42581rem;
    margin-top: 0.55rem;
}
.testimonialsRightSide{
    margin-top: 0rem;
    gap:0rem;
}
.testimonialRightSideHead{
    gap:0rem;
}
.testimonialsTextContainer{
    margin-top:0.38rem;
}
.starContainer{
    margin-top:-8px;
}
}
@media only screen and (min-width:320px) and (max-width: 374px){
    .testimonialPeoplesText{
    width:auto;
}
.testimonialRightSideHead{
    max-width:142px;
}
}
@media only screen and (min-width:1023px) and (max-width: 1439px){
    .testimonialPeople:hover, .testimonialPeople.clicked {
        width: 22rem;
    }
    .testimonialFlexContainer{
        gap:3rem;
    }
    .testimonialPeopleContainer{
    gap: 1rem;
    margin-left:6rem;
    }
    .testimonialPeoplesText{
        width:unset;
    }
}
@media only screen and (min-width:767px) and (max-width: 1023px){
    .testimonialPeople:hover, .testimonialPeople.clicked {
        width: 20rem;
    }
    .testimonialPeople{
        gap:0rem;
        width: 20rem;
    }
    .testimonialFlexContainer{
        gap:2rem;
        margin-top: 3.5rem;
    }
    .testimonialPeopleContainer{
        margin-left: 4rem;
        gap: 0.75rem;
    }
    .testimonialPeoplesText{
        width:unset;
        margin-right: unset;
        height: unset;
    }
    .testimonialsHeading{
        margin-top: 3.5rem;
    }
}