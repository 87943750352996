.container {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;
  }
  
  .heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #212121;
    text-align: center;
  }
  
  .para {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #212121;
    margin-top: 0.3rem;
    
  }
  .large_para{
      padding-right: 4rem;
      padding-top: 0.5rem;
  }
  .name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #000000;
  }

  .carso{
    border-bottom: none;
  }
  