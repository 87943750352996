.star{
    margin-right: 0.27rem;
    width: 0.80919rem;
height: 0.76425rem;
}

.mainContainer{
    /* background-color: pink; */
    /* background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/image%2025.png"); */
   background: url("https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/testBackMob.png");
    padding: 1.5rem;
   background-repeat: no-repeat;
background-size: cover;
background-position: center;
width: 100%;
}

.flexContainer{
    display: flex;
    flex-direction: column;
    gap:1.52rem;
}
.flexItem{
    background: white;
    border-radius:0.43331rem;
    padding:0.75rem 0.75rem 1rem 0.75rem;
    display: flex;
    gap:0.75rem;
}
.testimonialsImage{
    height:50px;
    width:50px;
    border-radius: 50%;
}
.testName{
    color: #404040;
font-family: Inter;
font-size: 0.75831rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.testRole{
    color: rgba(64,64,64,0.75);
        font-family: Inter;
    font-size: 0.65rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
}
.testDesc{
    color: rgba(33,33,33,0.70);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 149.9%; 
}
.testHead{
    color: #FFF;
    font-family: Inter;
    font-size: 1.25rem;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
}
.testSubHead{
    color: #FFFFFC;
    font-family: Inter;
    font-size: 0.75rem;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 127.523%; 
    margin-bottom: 1.88rem;
    margin-top: 0.25rem;
}
.flexItem:last-child {
    margin-bottom: 5rem;
  }