.container {
  width: 80vw;
}

.smallContainer {
  /* border: 2px solid red; */
  background-color: #ffffff;
  width: 80vw;
}

.dp {
  /* border: 2px solid red; */
  position: absolute;
  /* bottom: 1px; */
  top: -60px;
  left: 10%;
  height: 10rem;
  width: 10rem;
}
.dpActive {
  border: 3px solid #ffffff;
  position: absolute;
  /* bottom: 1px; */
  top: -60px;
  left: 10%;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
}

.camera {
  background-color: #00000067;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  top: -60px;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* border:2px solid red */
}
.changeCover {
  position: absolute;
  right: 27px;
  bottom: 18px;
  cursor: pointer;
}

.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #000000;
  padding-top: 2rem;
  padding-left: 13rem;
}

.btn1 {
  background: #f5f5f5;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 0.6rem 1rem 0.6rem 1rem;
  color: #4d4d4d;
  border: none;
  margin-right: 1rem;
}

.btn2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #1d3557;
  border-radius: 10px;
  border: none;
  padding: 0.6rem 1rem 0.6rem 1rem;
}

.editBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #1d3557;
  border-radius: 10px;
  border: none;
  padding: 0.4rem 1rem 0.4rem 1rem;
}

.tabs_div {
  background: #ffffff;
  box-shadow: 0px 100px 256px rgba(154, 170, 207, 0.07),
    0px 41.7776px 106.951px rgba(154, 170, 207, 0.0503198),
    0px 22.3363px 57.181px rgba(154, 170, 207, 0.0417275),
    0px 12.5216px 32.0552px rgba(154, 170, 207, 0.035),
    0px 6.6501px 17.0243px rgba(154, 170, 207, 0.0282725),
    0px 2.76726px 7.08418px rgba(154, 170, 207, 0.0196802);
  display: flex;
  margin-top: 3rem;
  padding-left: 3rem;
  display: flex;
  gap: 2rem;
}

.eachTab {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: all 300ms;
}

.eachTabActive {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #252525;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom: 3px solid #1d3557;
  cursor: pointer;
  transition: all 300ms;
}

.addMemBtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 12px;
  padding: 0.6rem 1rem 0.6rem 1rem;
  background-color: #ffffff;
}
