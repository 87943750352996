.mainContainer{
    display: flex;
    gap:0.75rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top:2rem;
    padding-bottom: 2rem;
}
.heading{
    /* padding-top:8.19rem;
    padding-bottom:9.5rem;
    padding-left:7.06rem; */
    color: #2E548A;
font-family: Inter;
font-size: 1.75rem;
font-style: normal;
font-weight: 600;
line-height: 3.25rem; 
}
.otherCompaniesContainer{
    width: 100%;
flex-shrink: 0;
background: #F0F8FE;
}
.imagesContainer{
    justify-content:center;
    display:flex;
    width:65%;
    gap:4.12rem;
    align-content:center;
    align-items: center;
}
.imagesContainerInner{
    display: flex;
}
.ambience{
    width: 7.1875rem;
    height: 2.83144rem;
/* margin-right:5.65rem; */
}
.Tejomaya{
    width: 7.1875rem;
    height: 2.83144rem;
}
.Interia{
    width: 6.3rem;
    height: 3rem;
}
.essentia{
    width: 7.5625rem;
height: 1.5125rem;
/* margin-right:7.02rem; */
}
.design{
    width: 5.02575rem;
    height: 2.4375rem;
}
.swb{
    width: 9.47681rem;
height: 4.13738rem;
    /* margin-right:4.29rem; */
}
.akdesigno{
    /* margin-right:3.31rem; */
    width: 12.43125rem;
    height: 1.658rem;
}
.alsorg{
    width: 5.5rem;
    height: 2.12544rem;
}

@media only screen and (max-width: 767px) {
    .mainContainer{
        display: flex;
        gap:2.25rem;
        flex-direction: column;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    .heading{
        padding-bottom: 0rem;
        padding-left:0rem;
        padding-top:2.25rem;
        color: #2E548A;
text-align: center;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
font-weight: 600;
line-height: 1.875rem; /* 150% */
text-transform: capitalize;
width:90%;
margin: 0 auto;
    }
    
.ambience{
    width: 5.15506rem;
    height: 2.03081rem;
    margin-right:1.66rem;
}
.essentia{
    width: 5.85413rem;
    height: 1.17081rem;
    margin-right:2.77rem;
}
.design{
    width: 4.05669rem;
    height: 1.9675rem;
}
.swb{
    width: 5.07088rem;
height: 2.21381rem;
margin-right:0.93rem;
}
.akdesigno{
    width: 8.64075rem;
    height: 1.15244rem;
    margin-right:0.92rem;
}
.alsorg{
    width: 3.77275rem;
    height: 1.45794rem;
}
.imagesContainer{
    margin-bottom: 2.54rem;
    gap:1.72rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    align-content:unset;
    align-items: unset;
    flex-direction: column;
    width:65%;
}
.imagesContainerInner{
    justify-content: center;
}
  }